import { Stack } from "@mui/material";
import { DeleteButton, StandardButton } from "../buttons/customButtons";
import PropTypes from 'prop-types'

export default function AddSiteButtonGroup (props) {
  return (
    <Stack direction='row' spacing={1}>
      <StandardButton fullWidth={true} label='View Site' onClick={(e) => props.onViewSiteClicked(e)}/>
      <DeleteButton onClick={(e) => props.onDeleteSiteClicked(e)}/>
    </Stack>
  )
}

AddSiteButtonGroup.propTypes = {
  onViewSiteClicked: PropTypes.func,
  onDeleteSiteClicked: PropTypes.func
}