
import { PK, PREFIX_U, ID, PK_PREFIX_FOR_APPLIANCE_HISTORY, PREFIX_APP, PREFIX_APP_HISTORY, SK, PREFIX_RG, PREFIX_SITE, PREFIX_COMPANY, APP_SITE_ID,RG_DATE_INDEX, SITE_INDEX, ALL_APPS_INDEX, PREFIX_CG, PREFIX_GROUPS, REGIONAL_GROUP, CONTRACT_GROUP, PAT_DATE, EMAIL, CG_DATE_INDEX, TABLE_NAME, PREFIX_CC, SITES_FOR_COMPANY_INDEX, COMPANY_ID, RG_INDEX, COVER, FIRST_NAME, NAME, COVER_TYPE, CG_INDEX, IS_STOCK_SITE, REGIONAL_MANAGER, ROLE } from '../DynamoDbConstants'
import * as AWS from 'aws-sdk'
import { dateNowAsUnix, dateNowMinusYearAsUnix } from './dateServices'
import { createAddApplianceParams, createAddNewApplianceParams, createCompanyAndSiteParams, createCompanyParams, createCoverContractGroupParams, createGroupItem, createNewRGContractParams, createRemoveCGCoverParams, createSiteItem, createUpdateAppAuditParams, createUpdateAppCGParams, createUpdateAppDamagedParams, createUpdateAppFoundParams, createUpdateAppMissingParams, createUpdateAppMoveParams, createUpdateAppPassParams, createUpdateAppScrappedParams, createUpdateGroupParams, createUpdateUserItem, createUserAndStockSiteParams, createUserItem } from './dynamoItemCreator'
import { Amplify, Auth, Hub } from 'aws-amplify';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import { DynamoDBClient, BatchExecuteStatementCommand } from "@aws-sdk/client-dynamodb";
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3'

const configuration = {
  region: 'eu-west-2',
  secretAccessKey: 'A59d5KqU3Gz4vUzJcfPauHgrOQoOsuaiM0RSNtqy',
  accessKeyId: 'AKIAZ7FYJQRGB7NT6FKX'
}

//AWS.config.update(configuration)

var docClient = new AWS.DynamoDB.DocumentClient(configuration)


  // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-integrating-user-pools-with-identity-pools.html
  // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-dynamodb/index.html
  // async function getDocClient () {
  //   const client =  await Auth.currentSession()
  //     .then(res => {
  //       //AWS.config.region = 'eu-west-2'
  //       // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //       //   IdentityPoolId: 'eu-west-2:9279622c-6381-48c1-900f-38e8ed2e2404',
  //       //   Logins: {
  //       //     'cognito-idp:eu-west-2.amazonaws.com/eu-west-2_xGH8EhAxS': res.getIdToken().getJwtToken()
  //       //   }
  //       // })

  //       return new AWS.DynamoDB.DocumentClient({ region: 'eu-west-2' })
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })


  //     return client
  // }

  const REGION = 'eu-west-2'
  const COGNITO = 'cognito-idp:eu-west-2.amazonaws.com/eu-west-2_xGH8EhAxS'
  const IDENTITY = 'eu-west-2:9279622c-6381-48c1-900f-38e8ed2e2404'

  export const testCred = (token) => {
    // const test2 = fromCognitoIdentityPool({
    //   identityPoolId: 'eu-west-2:d855102b-5f33-4f19-8c53-f26eb1e80b1c',
    //   logins: {COGNITO: token}
    // }) 

    // console.log(test2)



          const test =  new AWS.DynamoDB.DocumentClient({ 
            region: REGION,
            credentials: fromCognitoIdentityPool({
              region: 'eu-west-2',
              secretAccessKey: 'A59d5KqU3Gz4vUzJcfPauHgrOQoOsuaiM0RSNtqy',
              accessKeyId: 'AKIAZ7FYJQRGB7NT6FKX'
            }) 
          })

          // console.log(test)

          const params = {
            TableName: TABLE_NAME,
            KeyConditionExpression: `${PK} = :pk and begins_with(${SK}, :sk)`,
            ExpressionAttributeValues: {
              ':pk' : "",
              ':sk' : PREFIX_SITE
            }
          }

        
          return test.query(params).promise()

          
  }



//const dateIndex = RG_DATE_INDEX
//const siteIndex = SITE_INDEX
//const allAppsIndex = ALL_APPS_INDEX

//const docClient = new AWS.DynamoDB.DocumentClient()



export const fetchExpiredAppsRG = (id) => {
  const params = {
    TableName: TABLE_NAME,
    IndexName: RG_DATE_INDEX,
    KeyConditionExpression: `${REGIONAL_GROUP} = :gsipk and ${PAT_DATE} < :date`,
    ExpressionAttributeValues: {
        ':gsipk': id,
        ':date': dateNowMinusYearAsUnix()
    }
  }

  return docClient.query(params).promise()
}

export const fetchAppsRG = (id) => {
  const params = {
    TableName: TABLE_NAME,
    IndexName: RG_DATE_INDEX,
    KeyConditionExpression: `${REGIONAL_GROUP} = :gsipk and ${PAT_DATE} >= :date`,
    ExpressionAttributeValues: {
        ':gsipk': id,
        ':date': 0
    }
  }

  return docClient.query(params).promise()
}

export const fetchExpiredAppsCG = (id) => {
  const params = {
    TableName: TABLE_NAME,
    IndexName: CG_DATE_INDEX,
    KeyConditionExpression: `${CONTRACT_GROUP} = :gsipk and ${PAT_DATE} < :date`,
    ExpressionAttributeValues: {
        ':gsipk': id,
        ':date': dateNowMinusYearAsUnix()
    }
  }

  return docClient.query(params).promise()
}

export const fetchAppsCG = (id) => {
  const params = {
    TableName: TABLE_NAME,
    IndexName: CG_DATE_INDEX,
    KeyConditionExpression: `${CONTRACT_GROUP} = :gsipk and ${PAT_DATE} >= :date`,
    ExpressionAttributeValues: {
        ':gsipk': id,
        ':date': 0
    }
  }

  return docClient.query(params).promise()
}

export const fetchAllApps = (pk) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and begins_with(${SK}, :sk)`,
    ExpressionAttributeValues: {
      ':pk': pk,
      ':sk': PREFIX_APP
    }
  }

  return docClient.query(params).promise()
}

export const fetchAllAppsKeysOnly = (id) => {
  const params = {
    TableName: TABLE_NAME,
    IndexName: ALL_APPS_INDEX,
    KeyConditionExpression: `${PK} = :pk`,
    ExpressionAttributeValues: {
      ':pk': id
    }
  }

  return docClient.query(params).promise()
}

export const fetchAppsForSite = (id, sid) => {
  const params = {
    TableName: TABLE_NAME,
    IndexName: SITE_INDEX,
    KeyConditionExpression:  `${PK} = :pk and ${APP_SITE_ID} = :apsid`,
    ExpressionAttributeValues: {
      ':pk' : id,
      ':apsid' : sid
    }
  }

  return docClient.query(params).promise()
}

export const fetchApp = (id, appId) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression:  `${PK} = :pk and ${SK} = :apid`,
    ExpressionAttributeValues: {
      ':pk' : id,
      ':apid' : `AP#${appId}`
    }
  }
  return docClient.query(params).promise()
}

export const fetchAppHistory = (appId) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression:  `${PK} = :pk and begins_with (${SK}, :sk)`,
    ExpressionAttributeValues: {
      ':pk' : `ID#${appId}`,
      ':sk' : `AH#`
    }
  }
  return docClient.query(params).promise()
}

export const fetchAllSites = (id) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and begins_with(${SK}, :sk)`,
    ExpressionAttributeValues: {
      ':pk' : id,
      ':sk' : PREFIX_SITE
    }
  }

  return docClient.query(params).promise()
}

export const fetchSite = (id, sid) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and ${SK} = :sk`,
    ExpressionAttributeValues: {
      ':pk' : id,
      ':sk' : `${PREFIX_SITE}${sid}`
    }
  }

  return docClient.query(params).promise()
}

export const fetchAllCompanies = (id) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and begins_with(${SK}, :sk)`,
    ExpressionAttributeValues: {
      ':pk' : id,
      ':sk' : PREFIX_COMPANY
    }
  }

  return docClient.query(params).promise()
}

export const fetchCompanySites = (id, cuid) => {
  const params = {
    TableName: TABLE_NAME,
    IndexName: SITES_FOR_COMPANY_INDEX,
    KeyConditionExpression: `${PK} = :pk and ${COMPANY_ID} = :cuid`,
    ExpressionAttributeValues: {
      ':pk' : id,
      ':cuid' : cuid
    }
  }

  return docClient.query(params).promise()
}

export const fetchAllForRG = (pk, rg) => {
  const params = {
    TableName: TABLE_NAME,
    IndexName: RG_INDEX,
    KeyConditionExpression: `${PK} = :pk and ${REGIONAL_GROUP} = :rg`,
    ExpressionAttributeValues: {
      ':pk' : pk,
      ':rg' : rg
    }
  }

  return docClient.query(params).promise()
}

export const fetchAllForCG = (pk, cg) => {
  const params = {
    TableName: TABLE_NAME,
    IndexName: CG_INDEX,
    KeyConditionExpression: `${PK} = :pk and ${CONTRACT_GROUP} = :cg`,
    ExpressionAttributeValues: {
      ':pk' : pk,
      ':cg' : cg
    }
  }

  return docClient.query(params).promise()
}

export const fetchAllRegions = (id) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and begins_with(${SK}, :sk)`,
    ExpressionAttributeValues: {
      ':pk' : id,
      ':sk' : PREFIX_RG
    }
  }

  return docClient.query(params).promise()
}

export const fetchCG = (pk, cgId) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and ${SK} = :sk`,
    ExpressionAttributeValues: {
      ':pk' : pk,
      ':sk' : `${PREFIX_CG}${cgId}`
    }
  }

  return docClient.query(params).promise()
}

export const fetchAllContracts = (id) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and begins_with(${SK}, :sk)`,
    ExpressionAttributeValues: {
      ':pk' : id,
      ':sk' : PREFIX_CG
    }
  }

  return docClient.query(params).promise()
}

export const fetchAllGroups = (id) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and begins_with(${SK}, :sk)`,
    ExpressionAttributeValues: {
      ':pk' : id,
      ':sk' : PREFIX_GROUPS
    }
  }

  return docClient.query(params).promise()
}

export const addNewSite = (formData) => {
  const siteItem = createSiteItem(formData)
  console.log(siteItem)

  const params = {
    TableName: TABLE_NAME,
    Item: siteItem
  }

  return docClient.put(params).promise()
}

export const addNewSiteWithRGContract = (formData) => {
  const siteItem = createSiteItem(formData)
  const siteParams = {
    Put: {
      TableName: TABLE_NAME,
      Item: siteItem
    }
  }
  const params = createNewRGContractParams(formData)
  params.push(siteParams)
  console.log(params)
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const addNewCompany = (formData) => {
  const companyItem = createCompanyParams(formData)
  console.log(companyItem)

  const params = {
    TableName: TABLE_NAME,
    Item: companyItem
  }

  return docClient.put(params).promise()
}

export const addNewCompanyAndSite = (formData) => {
  const params = createCompanyAndSiteParams(formData)
  
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const addNewCompanyAndSiteWithRGContract = (formData) => {
  const params = createCompanyAndSiteParams(formData)
  const combined = params.concat(createNewRGContractParams(formData))
  console.log(params)
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const addCover = (formData) => {
  const params = createCoverContractGroupParams(formData)

  return docClient.transactWrite({TransactItems: params}).promise()
}

export const removeCover = (formData) => {
  const params = createRemoveCGCoverParams(formData)

  return docClient.transactWrite({TransactItems: params}).promise()
}

export const fetchUsers = (id) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and begins_with(${SK}, :sk)`,
    ExpressionAttributeValues: {
      ':pk' : id,
      ':sk' : PREFIX_U
    }
  }

  return docClient.query(params).promise()
}

export const fetchUser = async (id, ccId) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and ${SK} = :sk`,
    ExpressionAttributeValues: {
      ':pk' : `${ID}#${ccId}`,
      ':sk' : `${PREFIX_U}${id}`
    }
  }
  console.log(params)

  return docClient.query(params).promise()
}

export const fetchUserWithPK = async (pk, id) => {
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and ${SK} = :sk`,
    ExpressionAttributeValues: {
      ':pk' : pk,
      ':sk' : `${PREFIX_U}${id}`
    }
  }
  console.log(params)

  return docClient.query(params).promise()
}

export const fetchCleaningCompany = async (id) => {

  var pk = id

  if (pk.includes('#')) {
    const elements = pk.split('#')
    pk = elements[1]
  }

  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and begins_with (${SK}, :sk)`,
    ExpressionAttributeValues: {
      ':pk' : `${ID}#${pk}`,
      ':sk' : PREFIX_CC
    }
  }
  console.log(params)

  return docClient.query(params).promise()
}

// export const addDynamoUserAndStockSite = (formData) => {
//   const params = createUserAndStockSiteParams(formData)
//   console.log(params)
//   return docClient.transactWrite({TransactItems: params}).promise()
// }

export const addDynamoUser = (formData) => {
  const userItem = createUserItem(formData)
  console.log(userItem)
  const params = {
    TableName: TABLE_NAME,
    Item: userItem
  }
  //return Promise.resolve('test promise user item')
  return docClient.put(params).promise()
}

export const updateDynamoUser = (formData) => {
  const params = createUpdateUserItem(formData)
  console.log(params)

  return docClient.transactWrite({TransactItems: params}).promise()
}

// export const fetchGroupItem = (id, gid) => {
//   const params = {
//     TableName: TABLE_NAME,
//     KeyConditionExpression:  `${PK} = :pk and ${SK} = :apid`,
//     ExpressionAttributeValues: {
//       ':pk' : id,
//       ':apid' : `AP#${appId}`
//     }
//   }

//   return docClient.query(params).promise()
// }

export const addGroupItem = (formData) => {
  if (formData.user[ROLE] === REGIONAL_MANAGER) {
    const params = []
    params.push({
      Put: {
        TableName: TABLE_NAME,
        Item: createGroupItem(formData)
      }
    })
    params.push({
      Put: {
        TableName: TABLE_NAME,
        Item: createNewRGContractParams(formData)
      }
    })
    return docClient.transactWrite({TransactItems: params}).promise()
  } else {
    const groupItem = createGroupItem(formData)
    console.log(groupItem)
    const params = {
      TableName: TABLE_NAME,
      Item: groupItem
    }
    //return Promise.resolve('test promise add group item')
    return docClient.put(params).promise()
  }
}

export const updateGroupItem = (formData) => {
  const params = createUpdateGroupParams(formData)
  console.log(params)
  //return Promise.resolve('update group item')
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const addNewAppliance = (formData, site) => {
  const params = createAddNewApplianceParams(formData, site)
  console.log(params)
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const addApplianceToSite = (formData, site) => {
  const params = createAddApplianceParams(formData, site)
  console.log(params)
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const updateAppAddSSRemoveCG = (pk, appId) => {
  const params = {
    TableName: TABLE_NAME,
    Key: {
      [PK]: pk,
      [SK]: `${PREFIX_APP}${appId}`
    },
    UpdateExpression: `set ${IS_STOCK_SITE} = :ss remove ${CONTRACT_GROUP}`,
    ExpressionAttributeValues: {
      ':ss': true
    }
  }
  return docClient.update(params).promise()
}

export const updateAppCG = (app) => {
  const params = createUpdateAppCGParams(app)
  return docClient.update(params).promise()
}

export const updateAppAuditDate = (id, auditData) => {
  const params = createUpdateAppAuditParams(id, auditData)
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const updateAppPassDate = (id, auditData) => {
  const params = createUpdateAppPassParams(id, auditData)
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const updateAppAuditDateDamaged = (id, auditData) => {
  const params = createUpdateAppDamagedParams(id, auditData)
  console.log(params)
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const updateAppMoveSite = (id, auditData) => {
  const params = createUpdateAppMoveParams(id, auditData)
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const updateAppMissing = (id, auditData) => {
  const params = createUpdateAppMissingParams(id, auditData)
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const updateAppScrapped = (id, auditData) => {
  const params = createUpdateAppScrappedParams(id, auditData)
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const updateAppFound = (id, auditData) => {
  const params = createUpdateAppFoundParams(id, auditData)
  return docClient.transactWrite({TransactItems: params}).promise()
}

export const deleteUserDynamo = (pk, sk) => {
  const params = {
    TableName: TABLE_NAME,
    Key: {
      [PK]: pk,
      [SK]: sk
    }
  }
  return docClient.delete(params).promise()
}

export const deleteSite = (pk, sk) => {
  const params = {
    TableName: TABLE_NAME,
    Key: {
      [PK]: pk,
      [SK]: `S#${sk}`
    }
  }
  return docClient.delete(params).promise()
}

export const deleteCompany = (pk, sk) => {
  const params = {
    TableName: TABLE_NAME,
    Key: {
      [PK]: pk,
      [SK]: `CU#${sk}`
    }
  }
  return docClient.delete(params).promise()
}

export const deleteApp = (id, appId, history) => {
  const promises = []
  history.forEach(item => {
    promises.push(deleteHistory(appId, item[SK]))
  })
  promises.push(deleteJustApp(id, appId))
  return Promise.all(promises)
}

const deleteJustApp = (id, appId) => {
  const params = {
    TableName: TABLE_NAME,
    Key: {
      [PK]: `${id}`,
      [SK]: `${PREFIX_APP}${appId}`
    }
  }

  return docClient.delete(params).promise()
}

const deleteHistory = (appId, historyId) => {
  const params = {
    TableName: TABLE_NAME,
    Key: {
      [PK]: `${PK_PREFIX_FOR_APPLIANCE_HISTORY}${appId}`,
      [SK]: historyId
    }
  }




  return docClient.delete(params).promise()
}