import PropTypes from 'prop-types'
import { Box, Button, Card, CardContent, Divider, ThemeProvider, Typography } from "@mui/material";
import AppDataTable from '../tables/AppDataTable';
import AppDataTableSmall from '../tables/AppDataTableSmall';
import { THEME } from '../../themes/CustomThemes';
import { updateAppAddSSRemoveCG } from '../../services/dynamoDBservice';
import { APP_ID, PK } from '../../DynamoDbConstants';

export default function SiteViewContainer (props) {

  // used for setting all apps in site to stock site (FOR RYEFORDS)
  const setStockSite = () => {
    props.siteData.data.forEach(app => {
      updateAppAddSSRemoveCG(app[PK], app[APP_ID])
        .then(res => {

        })
        .catch(err => {
          console.log(err)
        })
    })
  }

  return (
    <Box>
      <ThemeProvider theme={THEME}>
        <Box sx={{ display: { tablet: 'none' } }}>
          <Card
            elevation={0}
          >
            <CardContent
              sx={{ padding: 0, '&:last-child': { pb: 0 } }}
            >    
              <Typography variant="subtitle2" >
                {props.siteData.key}
              </Typography>
              <Divider />
              <AppDataTableSmall
                apps={props.siteData.data}
                selectedRows={props.selectedDataIndexes}
                onAppSelected={props.onAppSelected}
                onAppUnSelected={props.onAppUnSelected}
                onAllAppsUnSelected={props.onAllAppsUnSelected}
              />
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ display: { mobile: 'none', tablet: 'block' } }}>
          <Card
            elevation={0}
          >
            <CardContent
            >
              
              <Typography variant="subtitle2" >
                {props.siteData.key}
              </Typography>
              <Divider/>
              <AppDataTable
                apps={props.siteData.data}
                selectedRows={props.selectedDataIndexes}
                onAppSelected={props.onAppSelected}
                onAppUnSelected={props.onAppUnSelected}
                onAllAppsUnSelected={props.onAllAppsUnSelected}
              />
            </CardContent>
          </Card>
        </Box>
      </ThemeProvider>
    </Box>
  )
}

SiteViewContainer.propTypes = {
  siteData: PropTypes.object,
  selectedDataIndexes: PropTypes.array,
  onAppSelected: PropTypes.func,
  onAppUnSelected: PropTypes.func,
  onAllAppsUnSelected: PropTypes.func
}