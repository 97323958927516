import { Box, Container } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { SmallContainedButton } from "../../components/buttons/customButtons";
import AdminCompanyList from "../../components/lists/AdminCompanyList";
import { EMPTY_COMPANY_ITEM, ID, PK } from "../../DynamoDbConstants";
import { FORM_ADD_COMPANY } from "../../FormConstants";

// admin companies page
export default function AdminCompanies () {
  const testUser = useOutletContext()
  const navigate = useNavigate()

  // sets the default form data for use in child components
  const formData = {
    // [PK] matches the testUser Partition Key, which is [ID]#cleaning company id
    [PK]: testUser[PK],
    // company defaults to empty company object (set in DynamoDbConstants)
    company: EMPTY_COMPANY_ITEM,
    type: FORM_ADD_COMPANY,
    // bool which determines what form to display (edit/add)
    new: false
  }

  // navigates to the add-company page, passing in formData as state
  const navigateToAddCompanyPage = () => {
    formData.new = true
    navigate("/appliance-home/admin/add-company", { state: formData })
  }

  return (
    <Container style={{ paddingTop: '20px' }} maxWidth="md">
      <SmallContainedButton 
        label='Add new company'
        onClick={navigateToAddCompanyPage}
      />
      <AdminCompanyList 
        formData={formData}
      />
    </Container>
  )
}