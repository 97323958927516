import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types'

export default function ContractMenu(props) {
  const [anchorElContract, setAnchorElContract] = useState(null);
  const menuOptions = ['Arrange cover', 'Edit User']

  const handleOpenContractMenu = (event) => {
    setAnchorElContract(event.currentTarget);
  }

  const handleCloseContractMenu = () => {
    setAnchorElContract(null)
  }

  const onMenuOptionClicked = (value, key) => {
    props.onMenuOptionClicked(value, key)
    setAnchorElContract(null)
  }

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="contract-menu"
        aria-controls="menu-contract"
        aria-haspopup="true"
        onClick={handleOpenContractMenu}
        color="inherit"
        sx={{ p: 0 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-contract"
        anchorEl={anchorElContract}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElContract)}
        onClose={handleCloseContractMenu}
        
      >
        {menuOptions.map((option) => (
          <MenuItem key={option} value={option} onClick={e => onMenuOptionClicked(option, props.data)}>
            <Typography textAlign="center">{option}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )

}

ContractMenu.propTypes = {
  data: PropTypes.object,
  onMenuOptionClicked: PropTypes.func
}