import { Alert, Box, Skeleton, Snackbar, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { APP_SITE_ID, NAME, PK, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE, SITE_ID, SK } from "../../DynamoDbConstants";
import { fetchAllApps, fetchAllAppsKeysOnly, fetchAllSites, fetchAppsForSite, fetchAppsRG } from "../../services/dynamoDBservice";
import { compareName } from "../../services/sortService";
import SiteButton from "../buttons/SiteButton";
import PropTypes from 'prop-types'
import { FORM_EDIT_SITE } from "../../FormConstants";

// list for displaying all sites to admin user
export default function AdminSiteList(props) {
  const navigate = useNavigate()
  const [sites, setSites] = useState([])
  const [showDeleteSuccess, setShowDeleteSucess] = useState(false)
  const [sitesLoading, setSitesLoading] = useState(false)

  const testUser = useOutletContext()

  // if sites haven't been fetched, fetches them from dynamo
  useEffect(() => {
   // if (sites.length === 0) {
      setSitesLoading(true)
      fetchAllSites(testUser[PK])
        .then(res => {
          var sorted = res.Items.sort(compareName)

          if (testUser[ROLE] === REGIONAL_MANAGER) {
            // currently not limited as RM see's all sites
            sorted = sorted.filter(s => s[REGIONAL_GROUP] === testUser[REGIONAL_GROUP])
            fetchAllApps(testUser[PK])
              .then(res => {
                sorted.forEach(site => {
                  site.apps = res.Items.filter(app => app[APP_SITE_ID] === site[SITE_ID])
                })
                setSitesLoading(false)
                setSites(sorted)
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            fetchAllApps(testUser[PK])
              .then(res => {
                sorted.forEach(site => {
                  site.apps = res.Items.filter(app => app[APP_SITE_ID] === site[SITE_ID])
                })
                setSitesLoading(false)
                setSites(sorted)
              })
              .catch(err => {
                console.log(err)
              })
          }

          // const promises = []
          // sorted.forEach(site => {
          //   promises.push(fetchAppsForSite(testUser[PK], site[SITE_ID]).then(res => {
          //     site.appCount = res.Items.length
          //   }))
          // })

          // Promise.allSettled(promises)
          //   .then(res => {
          //     //console.log(res)
          //     setSitesLoading(false)
          //     setSites(sorted)

          //   })
          //   .catch(err => {
          //     setSitesLoading(false)
          //     console.log(err)
          //   })
        })
        .catch(err => {
          setSitesLoading(false)
          console.log(err)
        })
   // }

  }, [testUser])

  // navigates to the edit site page
  const navigateToSitePage = (event, item) => {
    // sets the formData site item equal to the clicked on site
    props.formData.site = item
    // keeps track of old site name so can compare if it changes
    props.formData.oldSiteName = item[NAME]
    props.formData.type = FORM_EDIT_SITE
    const siteName = event.target.value.toLowerCase().replaceAll('/', ' ')

    console.log('navigating', siteName)
    const elements = siteName.split(' ')
    //console.log(formData)
    if (elements.length > 1) {
      navigate(`/appliance-home/admin/edit-site/${elements.join('-')}`, { state: props.formData })
      return
    }
    navigate(`/appliance-home/admin/edit-site/${siteName}`, { state: props.formData })
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowDeleteSucess(false);
  }

  return (
    <Box>
      <Stack spacing={1}>
        <Typography variant="h5" align="center">
          All Sites
        </Typography>
        {sitesLoading &&
          <Stack spacing={1}>
            <Skeleton variant='rounded' height={30} />
            <Skeleton variant='rounded' height={30} />
            <Skeleton variant='rounded' height={30} />
            <Skeleton variant='rounded' height={30} />
          </Stack>
        }
        {sites.map((item) =>
          <SiteButton
            key={item[SK]}
            site={item}
            onClick={navigateToSitePage}
          />
        )}
      </Stack>
      {/* This doesn't work as showDeleteSuccess is changed from child which navigates back to this view (re renders) */}
      <Snackbar open={showDeleteSuccess} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Site deleted successfully.
        </Alert>
      </Snackbar>
    </Box>
  )
}

AdminSiteList.propTypes = {
  formData: PropTypes.object
}