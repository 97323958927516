import {  Alert, Snackbar, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { COMPANY_ADDR, COMPANY_CONTACT, COMPANY_EMAIL, COMPANY_ID, COMPANY_POSTCODE, COMPANY_TEL, CUSTOMER_ID, NAME, PK, PREFIX_COMPANY, SK } from "../../DynamoDbConstants";
import PropTypes from 'prop-types'

// https://www.freecodecamp.org/news/beginner-react-project-build-basic-forms-using-react-hooks/

// add/edit company form
export default function AddEditCompanyForm(props) {
  // boolean which indicates whether to show the error snackBar or not
  const [showError, setShowError] = useState(false)
  // boolean which controls error state of company field
  const [companyError, setCompanyError] = useState(false)

  // function called from error snackBar on close events
  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  }

  // called if props.checkForm boolean changes (when parent component needs to validate form)
  useEffect(() => {
    // function 'checkForm' is in useEffect so as not to trigger re-renders when changing local state
    const checkForm = () => {
      var formError = false

      // shows the error on the company field
      if (!props.formData.company[NAME]) {
        setCompanyError(true)
        formError = true
      }

      // if any errors, shows the error snackBar and fails the parent form validation check
      if (formError) {
        setShowError(true)
        props.setCheckForm(false)
        return
      }

      // if no errors, calls parent submitForm method
      props.submitForm()
    }

    if (props.checkForm) {
      checkForm()
    }
  }, [props.checkForm, props.formData])

  // various state setting functions for company attributes
  // calls passed in props.setFormData function, using spread operator to update company
  const onCompanyChange = (event) => { setCompanyError(false); props.setFormData((formData) => ({ ...formData, company: { ...formData.company, [NAME]: event.target.value }}))}
  const onAddressChange = (event) => { props.setFormData((formData) => ({ ...formData, company: { ...formData.company, [COMPANY_ADDR]: event.target.value }})) }
  const onPostcodeChange = (event) => { props.setFormData((formData) => ({ ...formData, company: { ...formData.company, [COMPANY_POSTCODE]: event.target.value }})) }
  const onTelNoChange = (event) => { props.setFormData((formData) => ({ ...formData, company: { ...formData.company, [COMPANY_TEL]: event.target.value }})) }
  const onEmailChange = (event) => { props.setFormData((formData) => ({ ...formData, company: { ...formData.company, [COMPANY_EMAIL]: event.target.value }})) }
  const onContactChange = (event) => { props.setFormData((formData) => ({ ...formData, company: { ...formData.company, [COMPANY_CONTACT]: event.target.value }})) }

  return (
      <Stack spacing={1}>
        <TextField
        required
        error={companyError}
          id="outlined-basic"
          label="Company Name"
          variant="outlined"
          value={props.formData.company[NAME] ? props.formData.company[NAME] : ''}
          onChange={onCompanyChange}
          disabled={!props.formData.new}
          inputProps={{
            autoComplete: "none"
          }} />
        <TextField
          id="outlined-basic"
          label="Contact Name"
          variant="outlined"
          value={props.formData.company[COMPANY_CONTACT] ? props.formData.company[COMPANY_CONTACT] : ''}
          onChange={onContactChange}
          inputProps={{
            autoComplete: "none"
          }} />
        <TextField
          id="outlined-basic"
          label="Address"
          variant="outlined"
          value={props.formData.company[COMPANY_ADDR] ? props.formData.company[COMPANY_ADDR] : ''}
          onChange={onAddressChange}
          multiline
          inputProps={{
            autoComplete: "none"
          }} />
        <TextField
          id="outlined-basic"
          label="Postcode"
          variant="outlined"
          value={props.formData.company[COMPANY_POSTCODE] ? props.formData.company[COMPANY_POSTCODE] : ''}
          onChange={onPostcodeChange}
          inputProps={{
            autoComplete: "none"
          }}
        />
        <TextField
          id="outlined-basic"
          label="Tel No"
          variant="outlined"
          value={props.formData.company[COMPANY_TEL] ? props.formData.company[COMPANY_TEL] : ''}
          onChange={onTelNoChange}
          inputProps={{
            autoComplete: "none"
          }} />
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          value={props.formData.company[COMPANY_EMAIL] ? props.formData.company[COMPANY_EMAIL] : ''}
          onChange={onEmailChange}
          inputProps={{
            autoComplete: "none"
          }}
        />
        <Snackbar open={showError} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
          <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%'}}>
            Some required form fields not filled out.
          </Alert>
      </Snackbar>
      </Stack>
  )
}


AddEditCompanyForm.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  checkForm: PropTypes.bool,
  setCheckForm: PropTypes.func,
  submitForm: PropTypes.func
}