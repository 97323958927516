import * as AWS from 'aws-sdk'
import { HAS_COMMENTS } from '../../DynamoDbConstants'
import { createCommentItem, createDeleteCommentParams, createDeleteMessageParams, createFetchCommentsParams, createFetchMessagesParams, createMessageItem, createUpdateCommentLikedParams, createUpdateCommentUnlikedParams, createUpdateMessageItem, createUpdateMesssageLikedParams, createUpdateMesssageUnlikedParams } from './streamDynamoItemCreator'

const configuration = {
  region: 'eu-west-2',
  secretAccessKey: 'A59d5KqU3Gz4vUzJcfPauHgrOQoOsuaiM0RSNtqy',
  accessKeyId: 'AKIAZ7FYJQRGB7NT6FKX'
}

//AWS.config.update(configuration)

var docClient = new AWS.DynamoDB.DocumentClient(configuration)

export const uploadMessage = (user, message, selectedImage, linkPreviews) => {
  if (message.id === undefined) {
    return Promise.reject(new Error('message id cannot be null'))
  }
  const params = createMessageItem(user, message, selectedImage, linkPreviews)
  return docClient.put(params)
    .promise()
    .then(res => {
      return {
        response: res,
        params: params
      }
    })
}

export const uploadComment = (user, message, comment) => {
  if (comment.length < 1) {
    return Promise.reject(new Error("can't upload empty comment"))
  }

  if (!message[HAS_COMMENTS]) {
    const putItem = createCommentItem(user, message, comment)
    const params = [{
      Update: createUpdateMessageItem(message)},
      {Put: putItem
    }]

    return docClient.transactWrite({TransactItems: params})
      .promise()
      .then(res => {
        return {
          response: res,
          params: putItem
        }
      })
  } else {
    const params = createCommentItem(user, message, comment)
    return docClient.put(params)
      .promise()
      .then(res => {
        return {
          response: res,
          params: params
        }
      })
  }


}

export const fetchMessages = (pk) => {
  const params = createFetchMessagesParams(pk)
  return docClient.query(params).promise()
}

export const fetchComments = (messageSk) => {
  const params = createFetchCommentsParams(messageSk)
  return docClient.query(params).promise()
}

export const updateMessageLiked = (user, message) => {
  const params = createUpdateMesssageLikedParams(user, message)
  return docClient.update(params).promise()
}

export const updateMessageUnliked = (user, message) => {
  const params = createUpdateMesssageUnlikedParams(user, message)
  return docClient.update(params).promise()
}

export const updateCommentLiked = (user, comment) => {
  const params = createUpdateCommentLikedParams(user, comment)
  return docClient.update(params).promise()
}

export const updateCommentUnliked = (user, comment) => {
  const params = createUpdateCommentUnlikedParams(user, comment)
  return docClient.update(params).promise()
}

export const deleteMessage = (message) => {
  const params = createDeleteMessageParams(message)
  return docClient.delete(params).promise()
}

export const deleteComment = (comment) => {
  const params = createDeleteCommentParams(comment)
  return docClient.delete(params).promise()
}