
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import PropTypes from 'prop-types'
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { APP_ID, NAME, PK } from "../../DynamoDbConstants";
import { fetchAllAppsKeysOnly, fetchAllSites } from "../../services/dynamoDBservice";
import ApplianceAutoComplete from "../autoCompletes/ApplianceAutoComplete";
import AddApplianceForm from "../forms/AddApplianceForm";

// dialog that's shown when 'add appliance' is clicked in the site view
export default function AddApplianceDialog (props) {
  const testUser = useOutletContext()
  const [addNewAppliance, setAddNewAppliance] = useState(false)
  const [apps, setApps] = useState([])
  const [checkForm, setCheckForm] = useState(false)

  // blank form data for new appliances
  const [formData, setFormData] = useState({
    appId: "",
    serialNo: "",
    make: "",
    description: "",
    testDate: (new Date().getTime()),
    noTestDate: false,
    newApp: false
  })

  // function for re-setting the form data
  const clearFormData = () => {
    setFormData({
      appId: "",
      serialNo: "",
      make: "",
      description: "",
      testDate: (new Date().getTime()),
      noTestDate: false,
      newApp: false
    })
  }

  // function to fetch all the appliances for a cleaning company
  const fetchApps = (setId) => {
    fetchAllAppsKeysOnly(testUser[PK])
    .then(res => {
      // TODO - currently there's no need to set apps as they aren't used in this component - Oscar 26/8/22
      // setApps((apps) => [...apps, ...res.Items])
      // checks to see if setId is true
      if (setId) {
        // maps all returned appliances into a sorted site of APP_ID
        const sorted = res.Items.map(item => item[APP_ID]).sort()
        // sets the form data apps so check for existing ID can happen in form
        setFormData((formData) => ({...formData, apps: sorted}))
        // loops from 1 - 2000, increasing the index each loop
        for (let index = 1; index < 2000; index++) {
          // checks if the list of appliance ID's contains the index
          if (sorted.includes(index.toString())) {
            //console.log(`ID of ${index} already exists`)
          } else {
            // if the list does not contain the current index, then that ID number is available
            console.log(`ID ${index} available`)
            // sets the new appliance ID before breaking out of the loop
            setFormData((formData) => ({...formData, appId: index.toString()}))
            break
          }
        }
      }
      
    })
    .catch(err => {
      console.log(err)
    })
  }

  // called when submit button is clicked
  const onSubmitClicked = () => {
    // sets the checkForm boolean to true, which tells the child form component to validate the required form fields
    setCheckForm(true)
  }

  // called when continue button is clicked (if the user is adding a new appliance)
  const onContinueClicked = () => {
    // sets the formData.newApp attribute to true to indicate adding a new appliance
    setFormData((formData) => ({...formData, newApp: true}))
    // calls fetchApps to set a new available appliance ID
    fetchApps(true)
    // indicates that the dialog should change to accomodate adding a new appliance
    setAddNewAppliance(true)
  }

  // called when the back button is clicked (only visible if adding a new appliance)
  const onBackClicked = () => {
    // clears any filled in form data
    clearFormData()
    // indicates that the dialog should change back to showing applianceAutoComplete
    setAddNewAppliance(false)
  }

  return (
    <div>
      <DialogTitle>Add Appliance - {props.site[NAME]}</DialogTitle>
      <DialogContent>
        <AddApplianceForm 
          user={testUser}
          formData={formData}
          setFormData={setFormData}
          checkForm={checkForm}
          setCheckForm={setCheckForm}
          submitForm={() => props.onSubmit(formData)}
          addNewAppliance={addNewAppliance}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancelled}>Cancel</Button>
        {!addNewAppliance && !formData.appId &&
          <Button onClick={onContinueClicked}>Continue</Button>
        }
        {!addNewAppliance && formData.appId &&
          <Button onClick={(e) => props.onSubmit(formData)}>Submit</Button>
        }
        {addNewAppliance &&
        <div>
          <Button onClick={onBackClicked}>Back</Button>
          <Button onClick={onSubmitClicked}>Submit</Button>
        </div>
        }
      </DialogActions>
    </div>
  )
}

AddApplianceDialog.propTypes = {
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func
}