import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from "@mui/material";
import PropTypes from 'prop-types'
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { CONTRACT_GROUP, COVER, COVER_TYPE, FIRST_NAME, NAME, PK, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE } from "../../DynamoDbConstants";
import ContractsAutoComplete from "../autoCompletes/ContractsAutoComplete";

export default function ArrangeCoverDialog(props) {
  const testUser = useOutletContext()
  //const [selected, setSelected] = useState({ [COMPANY_NAME]: '', [SITE_ID]: '-111' })
  const [note, setNote] = useState('')
  const [formData, setFormData] = useState({
    contractGroupHolder: { [NAME]: '', [CONTRACT_GROUP]: '-111' }
  })

  useEffect(() => {
    formData.coverContractGroupHolder = props.contractGroup
    console.log(formData)
  }, [props.contractGroup])

  const shouldDisable = () => {
    // if (props.contractGroup[COVER] && props.contractGroup[COVER].find(cov => cov[COVER_TYPE] === false)) {
    //   return true
    // }
    return true
  }

  const WarningText = () => {
    if ((props.contractGroup && props.contractGroup[COVER]) || (formData.contractGroupHolder && formData.contractGroupHolder[COVER])) {
      return (
        <DialogContentText>
          Changing this will overwrite previous cover.
        </DialogContentText>
      )
    }
    return <></>
  }

  return (
    <div>
      <DialogTitle>Arrange Cover for {`${props.contractGroup[FIRST_NAME]} ${props.contractGroup[NAME]}`}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <DialogContentText>
            Please select the person covering
          </DialogContentText>
          <ContractsAutoComplete
            user={testUser}
            formData={formData}
            setFormData={setFormData}
            dontInclude={props.contractGroup}
            limitRegion={testUser[ROLE] === REGIONAL_MANAGER ? testUser[REGIONAL_GROUP] : undefined}
            label='Contract Manager'
            forUsers={true}
          />
          {(props.contractGroup && props.contractGroup[COVER]) &&
            <DialogContentText>
              {props.contractGroup[COVER][COVER_TYPE] === false
                ? `${props.contractGroup[FIRST_NAME]} ${props.contractGroup[NAME]} is already being covered by ${props.contractGroup[COVER][NAME]}.`
                : `${props.contractGroup[FIRST_NAME]} ${props.contractGroup[NAME]} is already covering ${props.contractGroup[COVER][NAME]}.`
              }
            </DialogContentText>
          }
          {(formData.contractGroupHolder && formData.contractGroupHolder[COVER]) &&
            <DialogContentText>
              {formData.contractGroupHolder[COVER][COVER_TYPE] === true
                ? `${formData.contractGroupHolder[FIRST_NAME]} ${formData.contractGroupHolder[NAME]} is already covering ${formData.contractGroupHolder[COVER][NAME]}.`
                : `${formData.contractGroupHolder[FIRST_NAME]} ${formData.contractGroupHolder[NAME]} is being covered by ${formData.contractGroupHolder[COVER][NAME]}.`
              }
            </DialogContentText>
          }
          <WarningText />
          {/* { formData.contractGroupHolder

          } */}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancelled}>Cancel</Button>
        <Button onClick={(e) => props.onSubmit(formData)} disabled={!formData.contractGroupHolder || !formData.contractGroupHolder[PK]}>Submit</Button>
      </DialogActions>
    </div>
  )
}

ArrangeCoverDialog.propTypes = {
  contractGroup: PropTypes.object,
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func
}