import { Box, Button, Card, CardContent, Chip, Container, Dialog, Grid, ThemeProvider, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import ActionButtonGroup from "../../components/buttonGroups/ActionButtonGroup";
import AddApplianceDialog from "../../components/dialogs/AddApplianceDialog";
import SiteViewContainer from "../../components/containers/SiteViewContainer";
import { APP_ID, COMPANY_NAME, CONTRACT_MANAGER, FIRST_NAME, IS_STOCK_SITE, MISSING_DATE, NAME, PAT_TESTER, PK, ROLE, SCRAP_DATE, SITE_ID } from "../../DynamoDbConstants";
import { filterOutScrappedApps, groupCompanies } from "../../services/dataSortingService";
import { addApplianceToSite, addNewAppliance, fetchAppsForSite, fetchSite } from "../../services/dynamoDBservice";
import { THEME } from "../../themes/CustomThemes";

// page for displaying specific site
export default function Site () {
  const testUser = useOutletContext()
  const location = useLocation()

  const [site, setSite] = useState(location.state) 
  const [tableData, setTableData] = useState([])
  const [showAddAppDialog, setShowAddAppDialog] = useState(false)

  const [selectedApps, setSelectedApps] = useState([])
  const [selectedDataIndexes, setSelectedDataIndexes] = useState([])

  useEffect(() => {
    // checks to see if site item exists, fetching from dynamo if not
    // (if site doesn't have a partition key then page has been loading from home page/app)
    //if (!location.state[PK]) {
      fetchSite(testUser[PK], location.state[SITE_ID])
        .then(res => {
         // console.log(res)
          setSite(res.Items[0])
        })
        .catch(err => {
          console.log(err)
        })
    //}
  }, [location.state, testUser])

  // function for fetching appliances for site
  // wrapping in useCallback means it won't trigger a re-render of useEffects which care about it's state
  const fetchApps = useCallback(() => {
    fetchAppsForSite(testUser[PK], location.state[SITE_ID])
      .then(res => {
       // console.log(res)
        // sets array for holding filtered appliances
        var filteredApps = []
        // if use role does not allows them to see scrapped appliances, filters them out
        if (testUser[ROLE] === CONTRACT_MANAGER || testUser[ROLE] === PAT_TESTER) {
          filteredApps = filterOutScrappedApps(res.Items)
        } else {
          filteredApps = res.Items
        }

        // checks if there are any appliances to show
        if (filteredApps.length === 0) {
          // if not, sets the siteApps state to value which will show 'no matching records' in child tables
          setTableData([{ 
            key: site[COMPANY_NAME], 
            data: [{ 
              key: site[NAME], data: []
            }]
          }])
        } else {
          // groups apps by company and site if filtered apps was not empty
          setTableData(groupCompanies(filteredApps))
        }
      })
  }, [site, testUser])

  useEffect(() => {
    // if site set (partition key will only be set if site fetched from dynamo/set from view site in editSites), fetches appliances for site
    if (site[PK]) {
      fetchApps()
    }
  }, [fetchApps, site, testUser])

  const onAddAppClicked = () => {
    setShowAddAppDialog(true)
  }

  const onDialogCancel = () => {
    setShowAddAppDialog(false)
  }

  const onDialogSubmit = (formData) => {
    formData[PK] = testUser[PK]
    formData.user = `${testUser[FIRST_NAME]} ${testUser[NAME]}`
    // checks to see if adding a new appliance
    if (formData.newApp) {
      setShowAddAppDialog(false)
      addNewAppliance(formData, site)
        .then(res => {
          console.log(res)
          // refreshes the list of apps for the site to reflect changes
          fetchApps()
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      setShowAddAppDialog(false)
      addApplianceToSite(formData, site)
        .then(res => {
          console.log(res)
          // refreshes the list of apps for the site to reflect changes
          fetchApps()
        })
        .catch(err => {
          console.log(err)
        })
    }
    
  }

  const onAppSelected = (app, index) => {
    setSelectedDataIndexes((selectedDataIndexes) => [...selectedDataIndexes, index])
    setSelectedApps((selectedApps) => [...selectedApps, app])
    // setSelectedApps((selectedApps) => selectedApps.filter((a) => a.SID === app.SID))
  }

  const onAppUnSelected = (app, index) => {
    setSelectedDataIndexes((selectedDataIndexes) => selectedDataIndexes.filter((i) => i !== index))
    setSelectedApps((selectedApps) => selectedApps.filter((a) => a[APP_ID] !== app[APP_ID]))
  }

  const onAllAppsUnSelected = (siteId) => {
    setSelectedDataIndexes([])
    setSelectedApps([])
  }

  const refreshApps = () => {
    onAllAppsUnSelected("")
    fetchApps()
  }

  return (
    <Container style={{ paddingTop: '20px' }} maxWidth="md">
      <ActionButtonGroup 
        selectedApps={selectedApps}
        refresh={refreshApps}
        partialRefresh={(e) => onAllAppsUnSelected("")}
        isSiteView={true}
        isStockSite={site[IS_STOCK_SITE]}
      />
      {selectedApps.map(i =>
        <Chip key={i[APP_ID]} sx={{ m: 1 }} label={`${i[APP_ID]} ${i[MISSING_DATE] ? '(missing)' : ''}${i[SCRAP_DATE] ? '(scrapped)' : ''}`} ></Chip>
      )}
      <ThemeProvider theme={THEME}>   
        {tableData.map((companyData => 
          // data.key is equal to company name on first level of tableData
          <Card key={companyData.key} variant="outlined">
            <CardContent>
              {/* this box contains the company name and add appliance button */}
              <Box>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography fontSize={18}>{companyData.key}</Typography>
                  </Grid>
                  <Grid item>
                    <Button onClick={onAddAppClicked}>Add Appliance</Button>
                  </Grid>
                </Grid>
              </Box>
              {companyData.data.map(siteData =>
                <SiteViewContainer
                  key={siteData.key} 
                  siteData={siteData}
                  selectedDataIndexes={selectedDataIndexes}
                  onAppSelected={onAppSelected}
                  onAppUnSelected={onAppUnSelected}
                  onAllAppsUnSelected={onAllAppsUnSelected}
                />
              )}
              
            </CardContent>
          </Card>
        ))}
        {/* placing Dialog parent outside of custom component means the dialog content will reset */}
        <Dialog open={showAddAppDialog} scroll="paper">
          <AddApplianceDialog 
            site={site}
            onSubmit={onDialogSubmit}
            onCancelled={onDialogCancel}
          />
        </Dialog>
      </ThemeProvider>
    </Container>
    
  )
}