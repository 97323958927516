import { Avatar, Badge, Box, Button, Card, Tooltip, CardActionArea, CardContent, Chip, CircularProgress, Divider, Grid, IconButton, LinearProgress, Popover, Skeleton, Stack, Typography } from "@mui/material";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Chat, ChatBubbleOutline, ChatBubbleRounded, Comment, ThumbUpAlt, ThumbUpOffAlt } from "@mui/icons-material";
import PropTypes from 'prop-types'
import { FIRST_NAME, HAS_COMMENTS, IMAGE_ID, LIKES, LINKS, MESSAGE, NAME, SK, UPLOAD_DATE, USER_ID } from "../../DynamoDbConstants";
import { unixToMessageDate } from "../../services/dateServices";
import { useOutlet, useOutletContext } from "react-router-dom";
import { deleteComment, deleteMessage, updateCommentLiked, updateCommentUnliked, updateMessageLiked, updateMessageUnliked, uploadComment } from "../../services/dynamo/streamDynamoDBService";
import { useEffect, useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { stringToColor } from "../../services/colourService";
import { Auth } from "aws-amplify";
import { deleteImage, getImage } from "../../services/s3/streamS3Service";
import { processStream } from "../../services/s3/responseService";
import StreamCommentTextField from "../textFields/StreamCommentTextField";
import { getInitials } from "../../services/data/messageDataSortingService";
import Linkify from "linkify-react";
import LinkPreviewContainer from "../containers/LinkPreviewContainer";
import MessageOptionsMenu from "../menu/MessageOptionsMenu";

export default function MessageCard(props) {
  const testUser = useOutletContext()

  const [liked, setLiked] = useState()
  const [initials, setInitials] = useState()

  const [image, setImage] = useState(null)
  const [loadingImage, setLoadingImage] = useState(false)

  const linkifyOptions = {
    target: '_blank'
  }

  const [openTooltip, setOpenTooltip] = useState(false)
  const [openCommentTooltip, setOpenCommentTooltip] = useState(false)

  useEffect(() => {
    console.log("checking image")
    if (props.message[IMAGE_ID] && props.message.selectedImage) {
      setImage(URL.createObjectURL(props.message.selectedImage))
    }
    if (props.message[IMAGE_ID] && !props.message.uploading) {
      setLoadingImage(true)
      Auth.currentSession()
        .then(res => {
          getImage(res.getIdToken().getJwtToken(), props.message)
            .then(res => {
              //  console.log(res)
              res.Body.transformToByteArray()
                .then(res => {
                  //  console.log(res)
                  //console.log(encode(res))
                  const blob = new Blob([res], { type: 'image/jpeg' })
                  //  console.log(blob)
                  setLoadingImage(false)
                  setImage(URL.createObjectURL(blob))
                })
                .catch(err => {
                  setLoadingImage(false)
                  console.error(err)
                })
            })
            .catch(err => {
              setLoadingImage(false)
              console.error(err)
            })
        })
    }
  }, [props.message])

  useEffect(() => {
    const elements = props.message[NAME].split(' ')
    setInitials(elements.map(e => {
      return e[0]
    }))
  }, [props.message])

  useEffect(() => {
    if (props.message[LIKES] && props.message[LIKES].find(e => e[USER_ID] === testUser[USER_ID])) {
      setLiked(true)
    } else {
      setLiked(false)
    }
  }, [props.message, testUser])

  const checkCommentLiked = (comment) => {
    return comment[LIKES] && comment[LIKES].find(e => e[USER_ID] === testUser[USER_ID])
  }

  const onLikeClicked = () => {
    if (props.message[LIKES] && props.message[LIKES].find(e => e[USER_ID] === testUser[USER_ID])) {
      // unlike post
      updateMessageUnliked(testUser, props.message)
        .then(res => {
          console.log("message unliked on database")
          props.setLikes(props.message)
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      // like post
      updateMessageLiked(testUser, props.message)
        .then(res => {
          console.log("message liked on database")
          props.setLikes(props.message)
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  const onDeleteClicked = () => {
    if (props.message[IMAGE_ID]) {
      Auth.currentSession()
        .then(res => {
          deleteImage(res.getIdToken().getJwtToken(), props.message)
            .then(res => {
              console.log("image deleted")
              deleteMessageDyanmo()
            })
            .catch(err => {
              console.error(err)
            })
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      deleteMessageDyanmo()
    }
  }

  const deleteMessageDyanmo = () => {
    if (props.message[HAS_COMMENTS]) {
      const promises = []
      props.message.comments.forEach(comment => {
        promises.push(deleteComment(comment))
      })
      promises.push(deleteMessage(props.message))

      Promise.allSettled(promises)
        .then(res => {
          console.log('comments and message deleted')
          props.removeMessage(props.message)
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      deleteMessage(props.message)
        .then(res => {
          console.log("message deleted")
          props.removeMessage(props.message)
        })
        .catch(err => {
          console.error(err)

        })
    }

  }

  const onCommentPostClicked = (comment) => {
    uploadComment(testUser, props.message, comment)
      .then(res => {
        // console.log("message uploaded successfully", res)
        // props.addMessage(res.params.Item, selectedImage)
        // setMessage({...message, text: ""})
        // setImageUrl(null)
        props.setComments(props.message, res.params.Item)
        console.log("comment uploaded")

      })
      .catch(err => {
        console.error(err)
      })
  }

  const onCommentLikeClicked = (comment) => {
    if (comment[LIKES] && comment[LIKES].find(e => e[USER_ID] === testUser[USER_ID])) {
      // unlike comment
      updateCommentUnliked(testUser, comment)
        .then(res => {
          console.log("comment unliked on database")
          props.setCommentLikes(comment)
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      // like comment
      updateCommentLiked(testUser, comment)
        .then(res => {
          console.log("comment liked on database")
          props.setCommentLikes(comment)
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
  // if (props.message[HAS_COMMENTS]) {
  //   const promises = []
  //   props.message.comments.forEach(comment => {
  //     promises.push(deleteComment(comment))
  //   })
  //   promises.push(deleteMessage(props.message))

  //   Promise.allSettled(promises)
  //     .then(res => {
  //       console.log('comments and message deleted')
  //       props.removeMessage(props.message)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //     })
  // } else {
  //   deleteMessage(props.message)
  //     .then(res => {
  //       console.log("message deleted")
  //       props.removeMessage(props.message)
  //     })
  //     .catch(err => {
  //       console.error(err)

  //     })
  // }
  const onCommentDeleteClicked = (comment) => {
    deleteComment(comment)
      .then(res => {
        console.log("comment deleted")
        props.removeComment(props.message, comment)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const onCommentReplyClicked = () => {

  }

  return (
    <Card sx={{ m: 0.5, position: 'relative' }}>

      <CardContent>
        <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
          <Stack direction='row' spacing={1}>
            <Avatar sx={{ bgcolor: stringToColor(props.message[NAME]), alignSelf: 'center' }}>{initials}</Avatar>
            <Stack>
              <Typography variant="h6">
                {props.message[NAME]}
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                {unixToMessageDate(props.message[UPLOAD_DATE])}
              </Typography>
            </Stack>

          </Stack>

          <MessageOptionsMenu onDeleteClicked={onDeleteClicked} color='inherit'/>
        </Box>

        <Linkify options={linkifyOptions}>
          <Typography variant="h5" sx={{ mt: 2 }}>
            {props.message[MESSAGE]}
          </Typography>
        </Linkify>
        {(props.message[LINKS] && props.message[LINKS].length > 0) &&
          <LinkPreviewContainer previews={props.message[LINKS]} type='normal' />
        }
        {(image && props.message.uploading) &&
          <Box>
            <Box
              component="img"
              sx={{
                height: 'auto',
                width: '100%',
                opacity: 0.5
              }}
              alt="loading message image"
              src={image}
            >

            </Box>
            <Box width='100%'>
              <LinearProgress />

            </Box>
          </Box>

        }
        {(!image && loadingImage) &&
          <Box sx={{ position: 'relative' }}>
            <Skeleton variant='rectangular'
              sx={{ maxHeight: { xs: 233, md: 167 }, maxWidth: { xs: 350, md: 250 }, }} />
          </Box>
        }
        {(image && !props.message.uploading) &&
          <Box sx={{ position: 'relative' }}>
            <Box
              component="img"
              sx={{
                height: 'auto',
                width: '100%',

              }}
              alt="message image"
              src={image}
            >
            </Box>
          </Box>
        }
        <Stack>
          <Box display="flex" justifyContent="space-between" sx={{ mt: 2, mb: 2 }}>
            <Stack direction='row'>
              <Button sx={{ textTransform: 'none' }} onClick={onLikeClicked}>
                {liked &&
                  <Stack direction='row'>
                    <ThumbUpAlt sx={{ mr: 1.5 }} />
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Like
                    </Typography>
                  </Stack>

                }
                {!liked &&
                  <Stack direction='row'>
                    <ThumbUpOffAlt sx={{ mr: 1.5 }} />
                    <Typography>
                      Like
                    </Typography>
                  </Stack>

                }
              </Button>
            </Stack>
            {(props.message[LIKES] && props.message[LIKES].length > 0) &&
            <Box onMouseOver={() => setOpenTooltip(true)}>
              <Tooltip open={openTooltip} onClick={() => setOpenTooltip(true)} onClose={() => setOpenTooltip(false)} title={
                <Stack 
                  
                >
                  {props.message[LIKES].map((like) =>
                    <Typography key={like[USER_ID]} variant='caption'>
                      {like[NAME]}
                    </Typography>
                  )}
                </Stack>

              }>
                <Typography color='primary' variant="h5">

                  <ThumbUpAlt sx={{ mr: 1.5 }} />

                  {(props.message[LIKES] && props.message[LIKES].length > 0) ? props.message[LIKES].length : ''}
                </Typography>
              </Tooltip>
              </Box>
            }
          </Box>
          <Divider sx={{ mb: 4 }} />

        </Stack>

        {props.message.comments &&
          <Grid container spacing={3} direction='column'>

            {props.message.comments.map((comment) =>
              <Grid key={comment[SK]} item >
                <Box mr={3}>

                  <Badge badgeContent={
                    (comment[LIKES] && comment[LIKES].length > 0) &&
                    <Box onMouseOver={() => setOpenCommentTooltip(comment[SK])} >

                    
                    <Tooltip open={openCommentTooltip === comment[SK]} onClick={() => setOpenCommentTooltip(comment[SK])} onClose={() => setOpenCommentTooltip(false)} title={
                      <Stack>
                        {comment[LIKES].map((like) =>
                          <Typography key={like[USER_ID]} variant='caption'>
                            {like[NAME]}
                          </Typography>
                        )}
                      </Stack>

                    }>


                      <Card>
                        <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 }, pl: 0.5, pr: 0.5 }}>
                          <Stack direction='row' spacing={1}>
                            <ThumbUpAlt sx={{ maxHeight: 20 }} color='primary' />
                            <Typography color='primary'>
                              {comment[LIKES].length}
                            </Typography>
                          </Stack>

                        </CardContent>
                      </Card>
                    </Tooltip>
                    </Box>
                  }  >

                    <Stack direction='row' spacing={1}>
                      <Avatar sx={{ bgcolor: stringToColor(comment[NAME]), width: 30, height: 30, fontSize: 14 }}>{getInitials(comment[NAME])}</Avatar>
                      <Stack>


                        <Card sx={{ display: 'inline-flex', backgroundColor: 'whitesmoke' }}>
                          <CardContent>

                            <Stack>
                              <Stack direction='row' spacing={1}>
                                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                  {comment[NAME]}
                                </Typography>
                                <Typography color='text-secondary' sx={{ fontSize: 12 }}>
                                  {unixToMessageDate(comment[UPLOAD_DATE])}
                                </Typography>
                              </Stack>

                              <Typography  >
                                {comment[MESSAGE]}
                              </Typography>
                            </Stack>

                          </CardContent>

                        </Card>
                        <Box display="flex" justifyContent="space-between">
                          <Stack direction='row'>
                            <IconButton color="primary" onClick={() => onCommentLikeClicked(comment)}>
                              {checkCommentLiked(comment) &&
                                <ThumbUpAlt sx={{ p: 0 }} />
                              }
                              {!checkCommentLiked(comment) &&
                                <ThumbUpOffAlt sx={{ p: 0 }} />
                              }
                            </IconButton>
                            <IconButton color="primary">
                              <Comment />
                            </IconButton>
                            <MessageOptionsMenu onDeleteClicked={() => onCommentDeleteClicked(comment)} color='primary'/>
                          </Stack>
                        </Box>
                      </Stack>
                    </Stack>
                  </Badge>
                </Box>

              </Grid>
            )}
          </Grid>
        }
        <Box mt={3}>
          <StreamCommentTextField message={props.message} onPostClicked={onCommentPostClicked} />
        </Box>
      </CardContent>
    </Card>
  )
}

MessageCard.propTypes = {
  message: PropTypes.object,
  setLikes: PropTypes.func,
  setCommentLikes: PropTypes.func,
  removeMessage: PropTypes.func,
  removeComment: PropTypes.func,
  setComments: PropTypes.func
}