import { Button } from "@mui/material";
import PropTypes from 'prop-types'
import { NAME } from "../../DynamoDbConstants";

export default function CompanyButton (props) {
  return (
    <Button
        variant="outlined"
        value={props.company[NAME]}
        onClick={(e) => props.onClick(e, props.company)}
      >
        {props.company[NAME]}
    </Button>
  )
}

CompanyButton.propTypes = {
  onClick: PropTypes.func,
  company: PropTypes.object
}