import { Button } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types'

export const StandardButton = (props) => {
  return (
    <Button variant="outlined" fullWidth={props.fullWidth} onClick={e => props.onClick(e)}>
      {props.label}
    </Button>
  )
}

StandardButton.propTypes = {
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  label: PropTypes.string
}

export const SmallContainedButton = (props) => {
  return (
    <Button 
        sx={{ maxwidth:'sm' }}
        variant="contained"
        onClick={(e) => props.onClick(e)}
      >
        {props.label}
      </Button>
  )
}

SmallContainedButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string
}

export const DeleteButton = (props) => {
  return (
    <Button variant="outlined" color='error' onClick={(e) => props.onClick()}>
      <DeleteIcon />
    </Button>
  )
}

DeleteButton.propTypes = {
  onClick: PropTypes.func
}
