import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useRoutes } from "react-router-dom";
import { ADMINSTRATOR, CONTRACT_MANAGER, DEVELOPER, IS_ADMIN, IS_DEVELOPER, PAT_TESTER, PK, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE } from "../../DynamoDbConstants";
import { fetchUsers } from "../../services/dynamoDBservice";
import { compareName } from "../../services/sortService";
import PropTypes from 'prop-types'
import { getRoleText } from "../../services/dataSortingService";
import { FORM_EDIT_USER } from "../../FormConstants";

// list for displaying all users to admin
export default function AdminUserList(props) {
  const navigate = useNavigate()
  const [users, setUsers] = useState([])

  const testUser = useOutletContext()

  // if users haven't been fetched, fetches them from dynamo
  useEffect(() => {
    if (users.length === 0 || (testUser[IS_DEVELOPER] || testUser[IS_ADMIN])) {
      fetchUsers(testUser[PK])
        .then(res => {
          if (testUser[ROLE] === REGIONAL_MANAGER) {
            setUsers(res.Items.filter(i => i[REGIONAL_GROUP] === testUser[REGIONAL_GROUP]).sort(compareName))
          } else {
            const sorted = res.Items.sort(compareName)
            setUsers(sorted)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [testUser])

  // navigates to the edit user page
  const navigateToUserPage = (event, item) => {
    console.log(item)
    props.formData.user = item
    props.formData.type = FORM_EDIT_USER
    console.log('navigating', event.target.value.toLowerCase())
    const elements = event.target.value.toLowerCase().split(' ')
    if (elements.length > 1) {
      navigate(`/appliance-home/admin/edit-user/${elements.join('-')}`, { state: props.formData })
      return
    }
    navigate(`/appliance-home/admin/edit-user/${event.target.value.toLowerCase()}`, { state: props.formData })
  }

  return (
    <Box>
      <Stack spacing={1}>
        <Typography variant="h5" align="center">
          All Users
        </Typography>
        {users.map((item) =>
          // hides the user if they're a developer
          item[ROLE] !== DEVELOPER &&
          <Button
            key={item.SK}
            variant="outlined"
            value={item.UID}
            onClick={(e) => navigateToUserPage(e, item)}
          >
            {item.FN} {item.N} {getRoleText(item[ROLE])}
          </Button>

        )}
      </Stack>
    </Box>
  )
}

AdminUserList.propTypes = {
  formData: PropTypes.object
}