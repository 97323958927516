import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { SmallContainedButton } from "../../components/buttons/customButtons";
import AdminSiteList from "../../components/lists/AdminSiteList";
import { COMPANY_ID, CONTRACT_GROUP, EMPTY_SITE_ITEM, ID, IS_STOCK_SITE, NAME, PK, REGIONAL_GROUP } from "../../DynamoDbConstants";
import { FORM_ADD_SITE } from "../../FormConstants";
import { createUser } from "../../services/cognitoService";

// admin sites page
export default function AdminSites () {
  const navigate = useNavigate()
  const testUser = useOutletContext()

  // sets default form data for use in child components
  const formData = { 
    // site defaults to empty site object (set in DynamoDbConstants)
    site: EMPTY_SITE_ITEM,
    type: FORM_ADD_SITE,
    // [PK] matches the testUser Patition Key, which is [ID]#cleaning company id
    [PK]: testUser[PK],
    // bool which determines what form to display (edit/add)
    new: false,
    // holders for each type a site item is interested in (will be equal to equivalent dynamo item if edit form)
    // ID is set to -111 in order for autocomplete to have something to compare against (protects against same name issues)
    companyHolder: { [NAME]: '', [COMPANY_ID]: '-111'},
    regionalGroupHolder: { [NAME]: '', [REGIONAL_GROUP]: '-111' },
    contractGroupHolder: { [NAME]: '', [CONTRACT_GROUP]: '-111' }
  }

  // navigates to the add-site page, passing in formData as state
  const navigateToAddSitePage = () => {
    formData.new = true
    formData.site[IS_STOCK_SITE] = false
    navigate(`/appliance-home/admin/add-site`, {
      state: formData})
  }

  const navigateToAddStockSitePage = () => {
    formData.new = true
    formData.site[IS_STOCK_SITE] = true
    navigate(`/appliance-home/admin/add-stock-site`, {
      state: formData})
  }

  return (
    <Container style={{ paddingTop: '20px' }} maxWidth="md">
      <Box>
        <Stack direction='row' spacing={1}>
          <SmallContainedButton 
            label='Add new site'
            onClick={navigateToAddSitePage}
          />
          <SmallContainedButton 
            label='Add stock site'
            onClick={navigateToAddStockSitePage}
          />
        </Stack>
        
        <AdminSiteList 
          formData={formData}
        />
      </Box>
    </Container>
  )
}