import { Avatar, Button, Stack, TextField } from "@mui/material";
import { stringToColor } from "../../services/colourService";
import PropTypes from 'prop-types'
import { FIRST_NAME, NAME } from "../../DynamoDbConstants";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";

export default function StreamCommentTextField(props) {
  const testUser = useOutletContext()
  const [initials, setInitials] = useState()
  const [focused, setFocused] = useState(false)
  const [comment, setComment] = useState("")

  useEffect(() => {
    const elements = `${testUser[FIRST_NAME]} ${testUser[NAME]}`.split(' ')
    setInitials(elements.map(e => {
      return e[0]
    }))
  }, [testUser])

  const onFocus = () => {
    console.log('focused')
    setFocused(true)
  }

  const onLostFocus = () => {
    console.log('lost focus')
    setFocused(false)
  }

  const handleChange = (e) => {
    setComment(e.target.value)
  }

  const onPostClicked = () => {
    props.onPostClicked(comment)
    setComment("")
  }

  return (
    <Box>
      <Stack direction='row' spacing={2}>
        <Avatar sx={{ bgcolor: stringToColor(`${testUser[FIRST_NAME]} ${testUser[NAME]}`), alignSelf: 'center' }}>{initials}</Avatar>
        <TextField
          fullWidth
          placeholder="Write a comment"
          multiline
          maxRows={6}
          onFocus={onFocus}
          onBlur={onLostFocus}
          value={comment}
          onChange={handleChange}
        />
      </Stack>
      {(focused || comment.length > 0) &&
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          
        >
          <Button onClick={onPostClicked}>
            Post
          </Button>
        </Box>
      }


    </Box>

  )
}

StreamCommentTextField.propTypes = {
  message: PropTypes.object,
  onPostClicked: PropTypes.func
}