import { Alert, Checkbox, DialogContentText, FormControlLabel, FormGroup, Snackbar, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { APP_ID } from '../../DynamoDbConstants'
import ApplianceAutoComplete from '../autoCompletes/ApplianceAutoComplete'

// form for adding an appliance to a site
export default function AddApplianceForm (props) {

  const [showError, setShowError] = useState(false)
  const [makeError, setMakeError] = useState(false)
  const [idError, setIdError] = useState(false)
  const [idMessage, setIdMessage] = useState()

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  }

  // called if props.checkForm boolean changes (when parent component needs to validate form)
  useEffect(() => {
    // function 'checkForm' is in useEffect so as not to trigger re-renders when changing local state
    const checkForm = () => {
      var formError = false

      // shows the error on the app id field
      if (!props.formData.appId) {
        setIdError(true)
        formError = true
      }
      // shows the error on the make field
      if (!props.formData.make) {
        setMakeError(true)
        formError = true
      }

      console.log(props.formData.apps)

      if (props.formData.apps && props.formData.apps.includes(props.formData.appId)) {
        setIdError(true)
        formError = true
        setIdMessage('Appliance with ID already exists')
      }

      // if any errors, shows the error snackBar and fails the parent form validation check
      if (formError) {
        setShowError(true)
        props.setCheckForm(false)
        return
      }

      // if no errors, calls parent submitForm method
      props.submitForm()
    }

    if (props.checkForm) {
      checkForm()
    }
  }, [props.checkForm, props.formData])

  // various setter functions for form fields
  const onAppIdChange = (v) => { props.setFormData((formData) => ({...formData, appId: v[APP_ID]})) }
  const onAppIdChangeTextFeild = (e) => { setIdError(false); setIdMessage(null); props.setFormData((formData) => ({...formData, appId: e.target.value})) }
  const onSerialChange = (e) => { props.setFormData((formData) => ({...formData, serialNo: e.target.value})) }
  const onMakeChange = (e) => { setMakeError(false); props.setFormData((formData) => ({...formData, make: e.target.value})) }
  const onDescriptionChange = (e) => { props.setFormData((formData) => ({...formData, description: e.target.value})) }
  const onTestDateChange = (v) => { props.setFormData((formData) => ({...formData, testDate: v._d.getTime()})) }
  const onNoTestDateChange = (e) => { props.setFormData((formData) => ({...formData, noTestDate: e.target.checked}))}

  return (
    <div>
      {/* displays a applianceAutoComplete so user can select an existing appliance to add to the site */}
      {!props.addNewAppliance &&
        <ApplianceAutoComplete 
          user={props.user}
          label='Appliance ID'
          overrideOnChange={onAppIdChange}
          overrideVariant='standard'
          helperText='leave blank if adding new'
        />
      }
      {/* if addNewAppliance is true, then displays the needed form fields for new appliances */}
      {props.addNewAppliance &&
        <div>
          <DialogContentText>
            Please make sure you mark this appliance with a permanent pen with the ID number:
          </DialogContentText>
          <DialogContentText sx={{ fontSize: "26px", color: 'green', fontWeight: 'bold'}}>
            {props.formData.appId}
          </DialogContentText>
          <TextField
          margin="dense"
          id="app-ID"
          label="Appliance ID"
          fullWidth
          variant="standard"
          value={props.formData.appId}
          required
          error={idError}
          onChange={onAppIdChangeTextFeild}
          helperText={idMessage}
          // InputProps={{
          //   readOnly: true
          // }}
        />
        <TextField
          margin="dense"
          id="add-app-serial-no"
          label="Serial No (optional)"
          fullWidth
          variant="standard"
          value={props.formData.serialNo}
          onChange={onSerialChange}
        />
        <TextField
          margin="dense"
          id="add-app-make"
          label="Make"
          fullWidth
          variant="standard"
          required
          value={props.formData.make}
          onChange={onMakeChange}
          error={makeError}
          helperText={makeError ? 'You must enter make for a new appliance' : ''}
        />
        <TextField
          margin="dense"
          id="add-app-description"
          label="Description (optional)"
          fullWidth
          variant="standard"
          value={props.formData.description}
          onChange={onDescriptionChange}
          helperText="Vacuum cleaner, extension lead etc."
        />
        <DatePicker 
          label="PAT test date"
          inputFormat="DD/MM/yyyy"
          value={props.formData.testDate}
          onChange={onTestDateChange}
          renderInput={(params) => <TextField fullWidth variant="standard" {...params} />}
          disabled={props.formData.noTestDate}
          disableFuture
        />
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={props.formData.noTestDate} onChange={onNoTestDateChange} />} label="No PAT test date" />
        </FormGroup>
        </div>
      }
      <Snackbar open={showError} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
          <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%'}}>
            Some required form fields not filled out.
          </Alert>
      </Snackbar>
    </div>
  )
}

AddApplianceForm.propTypes = {
  user: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  checkForm: PropTypes.bool,
  setCheckForm: PropTypes.func,
  submitForm: PropTypes.func,
  addNewAppliance: PropTypes.bool
}