import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Icon, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { uploadMessage } from "../../services/dynamo/streamDynamoDBService";
import PropTypes from 'prop-types'
import MessageAttachMenu from "../menu/MessageAttachMenu";
import ClearIcon from '@mui/icons-material/Clear';
import { Amplify } from "aws-sdk";
import { Auth } from "aws-amplify";
import { uploadImage } from "../../services/s3/streamS3Service";
import { v4 as uuidv4 } from 'uuid';
import * as linkify from "linkifyjs";
import { getLinkPreview } from "../../services/lambda/streamsLambdaService";
import LinkPreviewContainer from "../containers/LinkPreviewContainer";

export default function NewMessageCard(props) {
  const testUser = useOutletContext()

  const [message, setMessage] = useState({
    id: undefined,
    text: ""
  })

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const [imageHover, setImageHover] = useState(false)

  const [links, setLinks] = useState([])
  const [fetchingLinks, setFetchingLinks] = useState([])
  const [linkPreviews, setLinkPreviews] = useState([])
  const [loadingPreview, setLoadingPreview] = useState(false)

  useEffect(() => {
    const handlePaste = (e) => {
      const foundLink = linkify.find(e.clipboardData.getData("text"))
      if (foundLink.length > 0) {
        //console.log(foundLink.find(l => l.value === 'google.com'))
        foundLink.forEach(l => {
          const toAdd = []

          if (links.find(e => e.value === l.value)) {
            console.log('contains value already')
          } else {
            toAdd.push(l)
          }
          if (toAdd.length > 0) {
            setLinks(array => [...array, ...foundLink])
          }
        })

      }
    }

    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, [links]);



  useEffect(() => {
    console.log("links changed", links)
    
    links.forEach(link => {
      if (!fetchingLinks.find(e => e.value === link.value)) {
        console.log(fetchingLinks)
        setFetchingLinks(links => [...links, link])
        setLoadingPreview(true)
        Auth.currentSession()
          .then(res => {
            getLinkPreview(res.getIdToken().getJwtToken(), link.href)
              .then(res => {
                const decoded = new TextDecoder().decode(res.Payload)
                const json = JSON.parse(decoded)
                console.log(json)
                setLoadingPreview(false)
                setLinkPreviews(previews => [...previews, json.body])
              })
              .catch(err => {
                console.error(err)
                setLoadingPreview(false)
              })
          })
          .catch(err => {
            console.error(err)
            setLoadingPreview(false)
          })
      }
    })
  }, [fetchingLinks, links])

  const onLinkPreviewClosed = (preview) => {
    setLinks(links.filter(it => it.href !== preview.url))
    setFetchingLinks(fetchingLinks.filter(it => it.href !== preview.url))
    setLinkPreviews(linkPreviews.filter(it => it.url !== preview.url))
  }

  useEffect(() => {
    if (selectedImage) {
      console.log(selectedImage)
      setImageUrl(URL.createObjectURL(selectedImage));
    } else {
      setImageHover(false)
      setImageUrl(null)
    }
  }, [selectedImage]);

  const onImageHover = () => {
    console.log('hover')
    setImageHover(true)
  }

  const onImageLeft = () => {
    setImageHover(false)
    console.log('left')
  }

  const handleMessageChange = (event) => {

    setMessage({ ...message, text: event.target.value })
  }

  const onPostClicked = () => {
    message.id = uuidv4().toString()
    uploadMessage(testUser, message, selectedImage, linkPreviews)
      .then(res => {
        console.log("message uploaded successfully", res)
        props.addMessage(res.params.Item, selectedImage)
        setMessage({ ...message, text: "" })
        setImageUrl(null)
        setSelectedImage(null)
        setLinks([])
        setFetchingLinks([])
        setLinkPreviews([])
      })
      .catch(err => {
        console.error(err)
      })
  }

  return (
    // <Card sx={{ m: 0.5, width: 1, maxWidth: 500, minWidth: 320, position: 'relative' }}>
    <Card sx={{ m: 0.5, position: 'relative' }}>

      <CardContent >
        <TextField
          fullWidth
          multiline
          maxRows={6}
          variant="standard"
          placeholder="Share thoughts, ideas, or updates"
          InputProps={{ disableUnderline: true }}
          value={message.text}
          onChange={handleMessageChange}
          inputProps={{ style: { fontSize: 20 } }}
        />

        {(imageUrl && selectedImage) &&
          <Box sx={{ mt: 6 }}>
            <Box sx={{ backgroundColor: 'black', display: 'inline-flex' }} onMouseEnter={onImageHover}
              onMouseLeave={onImageLeft}>
              <img
                src={imageUrl}
                alt={selectedImage.name}
                height="100px"

                style={{ opacity: imageHover ? 0.5 : 1 }}
              />
              {imageHover &&
                <Box sx={{ position: 'absolute', maxWidth: '100px', color: 'white' }}>
                  <Stack direction='row'>
                    <Typography sx={{ fontWeight: 'bold', p: 0.5 }}>
                      {selectedImage.name.length > 20 ? `${selectedImage.name.substring(0, 19)}...${selectedImage.name.substring((selectedImage.name.length - 8))}` : selectedImage.name}
                    </Typography>
                    <IconButton sx={{ color: 'white' }} onClick={() => setSelectedImage(null)}>
                      <ClearIcon />
                    </IconButton>
                  </Stack>

                </Box>
              }


            </Box>
          </Box>
        }
        {linkPreviews.length > 0 &&
          <LinkPreviewContainer type='new' previews={linkPreviews} onLinkPreviewClosed={onLinkPreviewClosed}/>
        }
        { loadingPreview && 
            <Stack direction='row' spacing={3}>
              <CircularProgress />
              <Typography sx={{ alignSelf: 'center'}} color='primary' >
                Loading link preview... 
              </Typography>
            </Stack>
          }
        <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
          <MessageAttachMenu setSelectedImage={setSelectedImage} />
          <Button onClick={onPostClicked}>
            Post
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}

NewMessageCard.propTypes = {
  addMessage: PropTypes.func
}