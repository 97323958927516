import { ComponentPropsToStylePropsMapKeys } from '@aws-amplify/ui-react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useOutlet, useOutletContext } from 'react-router-dom'
import { ADMINSTRATOR, COMPANY_NAME, CONTRACT_GROUP, CONTRACT_MANAGER, DEVELOPER, NAME, PAT_TESTER, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE, SITE_ID } from '../../DynamoDbConstants'

export default function RoleSelect(props) {
  const testUser = useOutletContext()

  const [menuOptions, setMenuOptions] = useState([
    CONTRACT_MANAGER, PAT_TESTER
  ])

  useEffect(() => {
    if (!props.formData.new || ((testUser[ROLE] === DEVELOPER || testUser[ROLE] === ADMINSTRATOR) && !menuOptions.includes(REGIONAL_MANAGER))) {
      setMenuOptions((options) => ([...options, ...[REGIONAL_MANAGER, ADMINSTRATOR]]))
    } else {
      setMenuOptions([CONTRACT_MANAGER, PAT_TESTER])
    }
  }, [testUser])

  const onRoleChanged = (event) => {
    props.setError(false)
    switch (event.target.value) {
      case CONTRACT_MANAGER:
        // setRegions([]) //if contracts manager role is chosen then there are no regions
        // setContracts([{ [NAME]: "NO" }]) //intialize contract groups with "NO"
        props.setFormData((formData) => ({ ...formData, contractGroupHolder: { [NAME]: "NO", [CONTRACT_GROUP]: '-111' } })) //intialize formData by adding the contract group field and set the name to "NO"
        props.setFormData((formData) => ({ ...formData, regionalGroupHolder: { [NAME]: '', [REGIONAL_GROUP]: '-111' } })) //the regional group will not be used because contract managers don't have regional groups
        break;
      case REGIONAL_MANAGER:
        //setContracts([])//as above but for regional manager selected
        //setRegions([{ [NAME]: "NO" }])
        props.setFormData((formData) => ({ ...formData, regionalGroupHolder: { [NAME]: "NO", [REGIONAL_GROUP]: '-111' } }))
        props.setFormData((formData) => ({ ...formData, contractGroupHolder: { [NAME]: '', [CONTRACT_GROUP]: '-111' } }))
        break;
      default: //any other role has neither a contract group or a regional group
        //setContracts([])
        //setRegions([])
        props.setFormData((formData) => ({ ...formData, regionalGroupHolder: { [NAME]: '', [REGIONAL_GROUP]: '-111' } }))
        props.setFormData((formData) => ({ ...formData, contractGroupHolder: { [NAME]: '', [CONTRACT_GROUP]: '-111' } }))
    }
    props.setFormData((formData) => ({ ...formData, stockSite: { [COMPANY_NAME]: '', [SITE_ID]: '-111' } }))
    props.setFormData((formData) => ({ ...formData, user: { ...formData.user, [ROLE]: event.target.value } })) //now we add the role to the formdata
  }

  return (
    <FormControl fullWidth disabled={props.disabled}>
      <InputLabel id="demo-simple-select-label" required>Role</InputLabel>
      <Select
        error={props.error}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.formData.user[ROLE]}
        label="Role"
        onChange={onRoleChanged}
      >
        {menuOptions.map((option) => 
          <MenuItem key={option} value={option}>{option}</MenuItem>
        )}  
      </Select>
    </FormControl>
  )
}

RoleSelect.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  error: PropTypes.bool,
  setError: PropTypes.func,
  disabled: PropTypes.bool
}