import { Box, Button, Card, CardActionArea, CardContent, CircularProgress, Container, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import ClearIcon from '@mui/icons-material/Clear';

export default function LinkPreviewContainer(props) {


  const onCardClicked = (preview) => {
    if (preview.url) {
      window.open(preview.url, '_blank')
    } else {
      window.open(preview.URL, '_blank')
    }
    
  }

  if (props.type === 'new') {
    return (
      <Container disableGutters sx={{ mt: 3 }}>
        <Grid container spacing={1}>
          {props.previews.map((p =>
            <Grid key={p.url} item xs={12}>
  
              <Card  variant='outlined' sx={{ width: '100%' }}>
                <Stack direction='row'>
                  <CardActionArea onClick={() => onCardClicked(p)}>
                    <CardContent sx={{ p: 0 }}>
                      <Stack direction='row' spacing={1}>
                        {(p.images && p.images.length > 0) &&
                          <Box sx={{ maxHeight: 150, maxWidth: 150 }}>
                            <Box
                              component="img"
                              sx={{
                                height: 'auto',
                                width: '100%',
                              }}
                              alt={p.images[0]}
                              src={p.images[0]}
                            >
  
                            </Box>
                          </Box>
                        }
                        <Stack spacing={1} sx={{ ml: 1 }}>
                          <Typography sx={{ mt: 0.5, mr: 1 }}>
                            {p.title}
                          </Typography>
                          <Typography variant='caption' sx={{ mr: 1 }}>
                            {p.description}
                          </Typography>
                        </Stack>
  
                      </Stack>
  
  
                    </CardContent>
                  </CardActionArea>
                  <CardContent sx={{ p: 0 }}>
                    <IconButton onClick={() => props.onLinkPreviewClosed(p)}>
                      <ClearIcon />
                    </IconButton>
                  </CardContent>
                </Stack>
              </Card>
            </Grid>
          ))}
          
          
          
        </Grid>
        
      </Container>
    )
  } else {
    return (
      <Container disableGutters sx={{ mt: 3 }}>
        <Grid container spacing={1}>
          {props.previews.map((p =>
            <Grid key={p.URL} item xs={12}>
              <Card  variant='outlined' sx={{ width: '100%' }}>
                  <CardActionArea onClick={() => onCardClicked(p)}>
                    <CardContent sx={{ p: 0 }}>
                      <Stack direction='row' spacing={1}>
                        {(p.IMG && p.IMG.length > 0) &&
                          <Box sx={{ maxHeight: 150, maxWidth: 150 }}>
                            <Box
                              component="img"
                              sx={{
                                height: 'auto',
                                width: '100%',
                              }}
                              alt={p.IMG[0]}
                              src={p.IMG[0]}
                            >
  
                            </Box>
                          </Box>
                        }
                        <Stack spacing={1} sx={{ ml: 1 }}>
                          <Typography sx={{ mt: 0.5, mr: 1 }}>
                            {p.T}
                          </Typography>
                          <Typography variant='caption' sx={{ mr: 1 }}>
                            {p.D}
                          </Typography>
                        </Stack>
  
                      </Stack>
  
  
                    </CardContent>
                  </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    )
  }

  
}

LinkPreviewContainer.propTypes = {
  type: PropTypes.string,
  previews: PropTypes.array,
  onLinkPreviewClosed: PropTypes.func
}