import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import PropTypes from 'prop-types'
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { REPAIR_ON_SITE } from "../../AutoCompleteConstants";
import { COMPANY_NAME, NAME, PK, REGIONAL_GROUP, SK } from "../../DynamoDbConstants";
import { fetchAllSites } from "../../services/dynamoDBservice";

export default function DamagedAppDialog (props) {
  const testUser = useOutletContext()
  const [selected, setSelected] = useState('')
  const [sitesLoading, setSitesLoading] = useState(false)
  const [allSites, setAllSites] = useState([])
  const [note, setNote] = useState('')

  useEffect(() => {
    if (allSites.length === 0) {
      setAllSites((allSites) => [...allSites, { CN: REPAIR_ON_SITE, SK: '-111' }])
      setSelected({ CN: REPAIR_ON_SITE, SK: -111 })
    }
    
    // TODO - sets allSites default: 0: default stock room. 1: repair on site.
  }, [])

  const onSearchClicked = () => {
    if (allSites.length < 2) {
      setSitesLoading(true)
      //setTimeout(function() {
      fetchAllSites(testUser[PK])
        .then(res => {
          //setRegions((regions) => [...regions, ...res.Items])
          setAllSites((allSites) => [...allSites, ...res.Items])
          setSitesLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    }

    // }, 3000)
    console.log('site search clicked')

  }

  const onSiteClicked = (value, site) => {
    setSelected(site)
    console.log(value)
  }

  const getOptionName = (option) => {
    if (!option) {
      return ''
    }
    if (option[NAME]) {
      return `${option[COMPANY_NAME]}, ${option[NAME]}`
    }
    return option.CN
  }

  return (
    <div>
      <DialogTitle>Damaged App</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter as much detail about the damage to the appliance as possible.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="how-damaged"
          label="Damage detail"
          fullWidth
          variant="standard"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          multiline
        />
        {/* haven't yet worked out how to get siteAutocomplete working with value set some of the time */}
        <Autocomplete
            id="combo-box-demo"
            options={allSites.filter(s => s[REGIONAL_GROUP] === testUser[REGIONAL_GROUP] || s[SK] === '-111').map((site) => site)}
            sx={{ width: "md" }}
            value={selected}
            renderInput={(params) => <TextField {...params} label="Move to" variant="standard"/>}
            renderOption={(props, option) => (
              <li {...props} key={option.SK}>
                {getOptionName(option)}
              </li>
            )}
            getOptionLabel={option => getOptionName(option)}
            isOptionEqualToValue={(option, value) => option.SK === value.SK}
            onChange={(e, value) => onSiteClicked(value?.CN, value)}
            onOpen={onSearchClicked}
            loading={sitesLoading}
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancelled}>Cancel</Button>
        <Button onClick={(e) => props.onSubmit(note, selected)}>Submit</Button>
      </DialogActions>
    </div>
  )
}

DamagedAppDialog.propTypes = {
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func
}