import { Box, Container } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import AdminUserList from "../../components/lists/AdminUserList";
import { SmallContainedButton } from "../../components/buttons/customButtons";
import { COMPANY_NAME, CONTRACT_GROUP, EMPTY_USER_ITEM, ID, NAME, PK, REGIONAL_GROUP, SITE_ID } from "../../DynamoDbConstants";
import { FORM_ADD_USER } from "../../FormConstants";

// admin users page
export default function AdminUsers () {
  const navigate = useNavigate()
  const testUser = useOutletContext()
  
  // sets out default formData for adding/editing a user
  const formData = {
    user: EMPTY_USER_ITEM,
    type: FORM_ADD_USER,
    [PK]: testUser[PK],
    new: false,
    // REGIONAL_GROUP and CONTRACT_GROUP are set to -111 so that the autocompletes have something to check against
    regionalGroupHolder: { [NAME]: '', [REGIONAL_GROUP]: '-111' },
    contractGroupHolder: { [NAME]: '', [CONTRACT_GROUP]: '-111' },
    stockSite: { [COMPANY_NAME]: '', [SITE_ID]: '-111' }
  }

  // called when 'add new user' is clicked
  const navigateToAddUserPage = () => {
    // sets formData.new to true so that the addEditUser page knows it's adding a new user
    formData.new = true
    navigate('/appliance-home/admin/add-user', { state: formData })
  }

  return (
    <Container style={{ paddingTop: '20px' }} maxWidth="md">
      <Box>
        <SmallContainedButton 
          label='Add new user'
          onClick={navigateToAddUserPage}
        />
        <AdminUserList 
          formData={formData}
        />
      </Box>
    </Container>
  )
}