import { COMMENT_ID, FIRST_NAME, HAS_COMMENTS, IMAGE_ID, LIKES, LINKS, MESSAGE, MESSAGE_ID, NAME, PK, SK, TABLE_NAME, UPLOAD_DATE, USER_ID } from "../../DynamoDbConstants"
import { v4 as uuidv4 } from 'uuid';

export const createMessageItem = (user, message, selectedImage, linkPreviews) => {
  const date = new Date().getTime()

  const item = {
    [PK]: user[PK],
    [SK]: `${MESSAGE_ID}#${message.id}`,
    [MESSAGE_ID]: message.id,
    [USER_ID]: user[USER_ID],
    [NAME]: `${user[FIRST_NAME]} ${user[NAME]}`,
    [UPLOAD_DATE]: date,
    [MESSAGE]: message.text
  }

  if (selectedImage) {
    item[IMAGE_ID] = selectedImage.name
  }

  if (linkPreviews.length > 0) {
    const previews = []
    linkPreviews.forEach(p => {
      previews.push({
        T: p.title,
        D: p.description,
        IMG: p.images,
        URL: p.url
      })
    })
    item[LINKS] = previews
  }

  return {
    TableName: TABLE_NAME,
    Item: item
  }
}

export const createCommentItem = (user, message, comment) => {
  const date = new Date().getTime()
  const commentId = uuidv4().toString()

  const item = {
    [PK]: message[SK],
    [SK]: `${COMMENT_ID}#${commentId}`,
    [MESSAGE_ID]: message[MESSAGE_ID],
    [COMMENT_ID]: commentId,
    [USER_ID]: user[USER_ID],
    [NAME]: `${user[FIRST_NAME]} ${user[NAME]}`,
    [UPLOAD_DATE]: date,
    [MESSAGE]: comment
  }

  return {
    TableName: TABLE_NAME,
    Item: item
  }
}

export const createUpdateMessageItem = (message) => {
  
  return {
    TableName: TABLE_NAME,
    Key: {
      [PK]: message[PK],
      [SK]: message[SK]
    },
    UpdateExpression: `set ${HAS_COMMENTS} = :hc`,
    ExpressionAttributeValues: {
      ':hc': true
    }
  }
}

export const createFetchMessagesParams = (pk) => {
  return {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and begins_with(${SK}, :sk)`,
    ExpressionAttributeValues: {
      ':pk': pk,
      ':sk': MESSAGE_ID
    }
  }
}

export const createFetchCommentsParams = (messageSk) => {
  return {
    TableName: TABLE_NAME,
    KeyConditionExpression: `${PK} = :pk and begins_with(${SK}, :sk)`,
    ExpressionAttributeValues: {
      ':pk': messageSk,
      ':sk': COMMENT_ID
    }
  }
}

export const createUpdateMesssageLikedParams = (user, message) => {

  let likes = []

  if (message[LIKES]) {
    likes = likes.concat(message[LIKES])
  }
  console.log(likes)
  likes.push({ [NAME]: `${user[FIRST_NAME]} ${user[NAME]}`, [USER_ID]: user[USER_ID] })
  console.log(likes)
  return {
    TableName: TABLE_NAME,
    Key: {
      [PK]: message[PK],
      [SK]: message[SK]
    },
    UpdateExpression: `set ${LIKES} = :lks`,
    ExpressionAttributeValues: {
      ':lks': likes
    }
  }
}

export const createUpdateMesssageUnlikedParams = (user, message) => {

  let likes = []

  if (message[LIKES]) {
    likes = likes.concat(message[LIKES].filter(e => e[USER_ID] !== user[USER_ID]))
  }
  console.log(likes)
  return {
    TableName: TABLE_NAME,
    Key: {
      [PK]: message[PK],
      [SK]: message[SK]
    },
    UpdateExpression: `set ${LIKES} = :lks`,
    ExpressionAttributeValues: {
      ':lks': likes
    }
  }
}

export const createUpdateCommentLikedParams = (user, comment) => {

  let comments = []

  if (comment[LIKES]) {
    comments = comments.concat(comment[LIKES])
  }
  console.log(comments)
  comments.push({ [NAME]: `${user[FIRST_NAME]} ${user[NAME]}`, [USER_ID]: user[USER_ID] })
  console.log(comments)
  return {
    TableName: TABLE_NAME,
    Key: {
      [PK]: comment[PK],
      [SK]: comment[SK]
    },
    UpdateExpression: `set ${LIKES} = :lks`,
    ExpressionAttributeValues: {
      ':lks': comments
    }
  }
}

export const createUpdateCommentUnlikedParams = (user, comment) => {

  let comments = []

  if (comment[LIKES]) {
    comments = comments.concat(comment[LIKES].filter(e => e[USER_ID] !== user[USER_ID]))
  }
  console.log(comments)
  return {
    TableName: TABLE_NAME,
    Key: {
      [PK]: comment[PK],
      [SK]: comment[SK]
    },
    UpdateExpression: `set ${LIKES} = :lks`,
    ExpressionAttributeValues: {
      ':lks': comments
    }
  }
}

export const createDeleteMessageParams = (message) => {
  return {
    TableName: TABLE_NAME,
    Key: {
      [PK]: message[PK],
      [SK]: message[SK]
    }
  }
}

export const createDeleteCommentParams = (comment) => {
  return {
    TableName: TABLE_NAME,
    Key: {
      [PK]: comment[PK],
      [SK]: comment[SK]
    }
  }
}