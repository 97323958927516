import { Alert, Autocomplete, Box, Button, Checkbox, Container, createFilterOptions, Dialog, FormControlLabel, FormGroup, Snackbar, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { COMPANY_ID, COMPANY_NAME, CONTRACT_GROUP, CUSTOMER_ID, CUSTOMER_NAME, EMAIL, ID, IS_STOCK_SITE, NAME, PK, POSTCODE, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE, SITE_ADDR, SITE_CONTACT, SITE_EMAIL, SITE_ID, SITE_NAME, SITE_TEL, SK } from "../../DynamoDbConstants";
import PropTypes from 'prop-types'
import CompanyAutoComplete from "../autoCompletes/CompanyAutoComplete";
import RegionsAutoComplete from "../autoCompletes/RegionsAutoComplete";
import ContractsAutoComplete from "../autoCompletes/ContractsAutoComplete";
// https://www.freecodecamp.org/news/beginner-react-project-build-basic-forms-using-react-hooks/

export default function AddEditSiteForm(props) {
  const testUser = useOutletContext()

  const [companyError, setCompanyError] = useState(false)
  const [siteError, setSiteError] = useState(false)
  const [rgError, setRgError] = useState(false)
  const [cgError, setCgError] = useState(false)
  const [showError, setShowError] = useState(false)


  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  }

  useEffect(() => {
    const checkForm = () => {
      console.log(props.formData)
      var formError = false
      if (!props.formData.site[NAME]) {
        setSiteError(true)
        formError = true
      }
      if (!props.formData.companyHolder[NAME]) {
        setCompanyError(true)
        formError = true
      }
      if (!props.formData.regionalGroupHolder[NAME]) {
        setRgError(true)
        formError = true
      }
      if (!props.formData.site[IS_STOCK_SITE] && !props.formData.contractGroupHolder[NAME]) {
        setCgError(true)
        formError = true
      }
      console.log(props.formData)

      if (formError) {
        setShowError(true)
        props.setCheckForm(false)
        return
      }

      props.submitForm()
    }

    if (props.checkForm) {
      checkForm()
    }

  }, [props.checkForm, props.formData])

  const onSiteChange = (event) => { setSiteError(false); props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [NAME]: event.target.value } })) }
  const onContactChange = (event) => { props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [SITE_CONTACT]: event.target.value } })) }
  const onAddressChange = (event) => { props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [SITE_ADDR]: event.target.value } })) }
  const onPostcodeChange = (event) => { props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [POSTCODE]: event.target.value } })) }
  const onTelNoChange = (event) => { props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [SITE_TEL]: event.target.value } })) }
  const onEmailChange = (event) => { props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [SITE_EMAIL]: event.target.value } })) }

  return (
    <Stack spacing={1}>
      <CompanyAutoComplete
        user={testUser}
        formData={props.formData}
        setFormData={props.setFormData}
        error={companyError}
        setError={setCompanyError}
        disabled={!props.formData.new}
      />
      <TextField
        id="outlined-basic"
        label="Site Name"
        variant="outlined"
        value={props.formData.site[NAME]}
        onChange={onSiteChange}
        required
        disabled={props.formData.site[IS_STOCK_SITE]}
        error={siteError}
        inputProps={{
          autoComplete: "none"
        }} />
      <RegionsAutoComplete
        user={testUser}
        formData={props.formData}
        setFormData={props.setFormData}
        error={rgError}
        setError={setRgError}
        disabled={!props.formData.new || testUser[ROLE] === REGIONAL_MANAGER}
        defaultRM={testUser[ROLE] === REGIONAL_MANAGER}
      />
      {!props.formData.site[IS_STOCK_SITE] &&
        <Box>
          <ContractsAutoComplete
            user={testUser}
            formData={props.formData}
            setFormData={props.setFormData}
            error={cgError}
            setError={setCgError}
            disabled={!props.formData.regionalGroupHolder[PK]}
            limitRegion={props.formData.regionalGroupHolder[REGIONAL_GROUP]}
          />
        </Box>

      }

      <TextField
        id="outlined-basic"
        label="Contact Name"
        variant="outlined"
        value={props.formData.site[SITE_CONTACT] ? props.formData.site[SITE_CONTACT] : ''}
        onChange={onContactChange}
        inputProps={{
          autoComplete: "none"
        }} />
      <TextField
        id="outlined-basic"
        label="Address"
        variant="outlined"
        value={props.formData.site[SITE_ADDR] ? props.formData.site[SITE_ADDR] : ''}
        onChange={onAddressChange}
        multiline
        inputProps={{
          autoComplete: "none"
        }} />
      <TextField
        id="outlined-basic"
        label="Postcode"
        variant="outlined"
        value={props.formData.site[POSTCODE] ? props.formData.site[POSTCODE] : ''}
        onChange={onPostcodeChange}
        inputProps={{
          autoComplete: "none"
        }}
      />
      <TextField
        id="outlined-basic"
        label="Tel No"
        variant="outlined"
        value={props.formData.site[SITE_TEL] ? props.formData.site[SITE_TEL] : ''}
        onChange={onTelNoChange}
        inputProps={{
          autoComplete: "none"
        }} />
      <TextField
        id="outlined-basic"
        label="Email"
        variant="outlined"
        value={props.formData.site[SITE_EMAIL] ? props.formData.site[SITE_EMAIL] : ''}
        onChange={onEmailChange}
        inputProps={{
          autoComplete: "none"
        }}
      />
      <Snackbar open={showError} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
          Some required form fields not filled out.
        </Alert>
      </Snackbar>
    </Stack>

  )
}

AddEditSiteForm.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  checkForm: PropTypes.bool,
  setCheckForm: PropTypes.func,
  submitForm: PropTypes.func
}