import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import PropTypes from 'prop-types'
import { useOutletContext } from "react-router-dom";

export default function ConfirmDeleteDialog (props) {


  return (
    <div>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancelled}>Cancel</Button>
        <Button onClick={(e) => props.onSubmit()}>Confirm</Button>
      </DialogActions>
    </div>
  )
}

ConfirmDeleteDialog.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func
}