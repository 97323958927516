import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from "@mui/material";
import PropTypes from 'prop-types'
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { CONTRACT_GROUP, COVER, COVER_TYPE, FIRST_NAME, NAME } from "../../DynamoDbConstants";

export default function RemoveCoverDialog (props) {
  const testUser = useOutletContext()
  
  const [formData, setFormData] = useState({
    user: props.user,
    cover: props.cover
  })

  return (
    <div>
      <DialogTitle>Remove cover confirmation</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <DialogContentText>
            {props.cover[COVER_TYPE] === true && 
              `Are you sure you want ${props.user[FIRST_NAME]} ${props.user[NAME]} to stop covering ${props.cover[NAME]}?`
            }
            {props.cover[COVER_TYPE] === false && 
              `Are you sure you want ${props.cover[NAME]} to stop covering ${props.user[FIRST_NAME]} ${props.user[NAME]}?`
            }
            
          </DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancelled}>Cancel</Button>
        <Button onClick={(e) => props.onSubmit(formData)}>Confirm</Button>
      </DialogActions>
    </div>
  )
}

RemoveCoverDialog.propTypes = {
  user: PropTypes.object,
  cover: PropTypes.object,
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func
}