import { Box, Button, Card, CardContent, Container, Grid, Stack, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import MessageCard from "../../components/cards/MessageCard";
import NewMessageCard from "../../components/cards/NewMessageCard";
import { COMMENT_ID, FIRST_NAME, HAS_COMMENTS, LIKES, MESSAGE_ID, NAME, PK, SK, USER_ID } from "../../DynamoDbConstants";
import { compareUploadDate, compareUploadDateReversed } from "../../services/dataSortingService";
import { fetchComments, fetchMessages } from "../../services/dynamo/streamDynamoDBService";
import { uploadImage } from "../../services/s3/streamS3Service";
import {ReactComponent as AtlasLogo} from '../../assets/atlas_logo_orig.svg'
import { getLinkPreview } from "../../services/lambda/streamsLambdaService";


export default function Stream () {
  const testUser = useOutletContext()
  const [messages, setMessages] = useState([])
  const [linkTest, setLinkTest]= useState()

  useEffect(() => {
    fetchMessages(testUser[PK])
      .then(res => {
        console.log(res)
        const promises = []
        res.Items.forEach(message => {
          if (message[HAS_COMMENTS]) {
            promises.push(fetchComments(message[SK])
              .then(res => {
                message.comments = res.Items.sort(compareUploadDate)
              })
              .catch(err => {
                console.error(err)
              }))
          }
        })
        if (promises.length > 0) {
          Promise.allSettled(promises)
            .then(promiseResult => {
              setMessages(res.Items)
            })
            .catch(err => {
              console.error(err)
            })
        } else {
          setMessages(res.Items)
        }
        
      })
      .catch(err => {
        console.log(err)
      })
  }, [testUser])

  useEffect(() => {
    console.log(messages)
  }, [messages])

  const setLikes = (message) => {
    const newMessages = messages.map(msg => {
      if (msg[SK] === message[SK]) {
        
        console.log(msg)
        if (msg[LIKES]) {
          if (!msg[LIKES].find(e => e[USER_ID] === testUser[USER_ID])) {
            console.log("liked post")
            return {...msg, [LIKES]: [...msg[LIKES], { [NAME]: `${testUser[FIRST_NAME]} ${testUser[NAME]}`, [USER_ID]: testUser[USER_ID] }]}
          } else {
            console.log("unliked post")
            return {...msg, [LIKES]: msg[LIKES].filter(like => (like[USER_ID] !== testUser[USER_ID]))}
          }
        }
        return {...msg, [LIKES]: [{ [NAME]: `${testUser[FIRST_NAME]} ${testUser[NAME]}`, [USER_ID]: testUser[USER_ID] }]}
      }
      return msg
    })
    
    setMessages(newMessages)
  }

  useEffect(() => {
    // Auth.currentSession()
    //   .then(res => {
    //     getLinkPreview(res.getIdToken().getJwtToken(), "https://atlasfm.com/atlas-city-west-deal/")
    //       .then(res => {
    //         console.log(res)
    //         const decoded = new TextDecoder().decode(res.Payload)
    //         const json = JSON.parse(decoded)
    //         console.log(json)
    //         setLinkTest(json.body)
    //         //console.log(JSON.parse(JSON.stringify(res.Payload)))
    //       })
    //       .catch(err => {
    //         console.error(err)
    //       })
    //   })
    //   .catch(err => {
    //     console.error(err)
    //   })
  }, [])

  const setCommentLikes = (comment) => {
    console.log(comment)
    const newMessages = messages.map(msg => {
      if (msg[MESSAGE_ID] === comment[MESSAGE_ID]) {
        console.log(msg)
        const cmt = msg.comments.find(e => e[COMMENT_ID] === comment[COMMENT_ID])
        console.log(cmt)
        if (cmt[LIKES]) {
          if (!cmt[LIKES].find(e => e[USER_ID] === testUser[USER_ID])) {
            console.log("liked comment")
            cmt[LIKES].push({ [NAME]: `${testUser[FIRST_NAME]} ${testUser[NAME]}`, [USER_ID]: testUser[USER_ID] })
            return msg
          } else {
            console.log("unliked comment")
            cmt[LIKES] = cmt[LIKES].filter(e => e[USER_ID] !== testUser[USER_ID])
            return msg
          }
        }
          
          cmt[LIKES] = []
          cmt[LIKES].push({ [NAME]: `${testUser[FIRST_NAME]} ${testUser[NAME]}`, [USER_ID]: testUser[USER_ID] })
          return msg
      }
      return msg
    })
    
    setMessages(newMessages)
  }

  const setComments = (message, comment) => {
    if (!message[HAS_COMMENTS]) {
      message[HAS_COMMENTS] = true
    }
    const newMessages = messages.map(msg => {
      if (msg[SK] === message[SK] && comment) {
        if (msg.comments) {
          msg.comments.push(comment)
          msg.comments.sort(compareUploadDate)
          return msg
        } else {
          msg.comments = []
          msg.comments.push(comment)
          console.log(msg)
          return msg
        }
      }
      return msg
    })
    setMessages(newMessages)

  }

  const addMessage = (message, selectedImage) => {
    if (selectedImage) {
      message.uploading = true
      message.selectedImage = selectedImage
      Auth.currentSession()
      .then(res => {
        uploadImage(res.getIdToken().getJwtToken(), selectedImage, message[MESSAGE_ID])
          .then(res => {
            message.uploading = false
            setMessages([...messages, message])
            console.log("message uploaded to s3 success")
          })
          .catch(err => {
            message.uploading = false
            message.failedUpload = true
            setMessages([...messages, message])
            console.error(err)
          })
      })
      .catch(err => {
        console.error(err)
        message.uploading = false
        message.failedUpload = true
        setMessages([...messages, message])
      })
    }
    setMessages([...messages, message])
  }

  const removeMessage = (message) => {
    setMessages(messages.filter(e => e[MESSAGE_ID] !== message[MESSAGE_ID]))
  }

  const removeComment = (message, comment) => {
    const newMessages = messages.map(msg => {
      if (msg[SK] === message[SK] && comment) {
        if (msg.comments) {
          msg.comments = msg.comments.filter(c => c[SK] !== comment[SK])
          return msg
        }
      }
      return msg
    })
    setMessages(newMessages)
  }


  return (
    <Container style={{ paddingTop: '20px' }} maxWidth="md" disableGutters>
      {/* { linkTest && 
        <Card>
          <CardContent>
            <Typography>
              {linkTest.siteName}
            </Typography>
            <Typography>
              {linkTest.title}
            </Typography>
            <Typography>
              {linkTest.description}
            </Typography>
            <img alt='link_test' src={linkTest.images[0]}/>
          </CardContent>
        </Card>
      } */}
      {/* <Grid container display='flex' spacing={2} justifyContent='center' sx={{ backgroundColor: 'red'}}> */}
      <Container sx={{ alignItems: 'center', justifyContent: 'center', maxWidth: 700}} disableGutters>
      
      <Box>
      <Stack sx={{ alignItems: 'start', alignSelf: 'start', m: 0.5 }}>
      <AtlasLogo />
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#4c8cb3ff'}}>
        Company feed
      </Typography>
      </Stack>
      
      
        <NewMessageCard addMessage={addMessage}/>
        {messages.sort(compareUploadDateReversed).map((msg) => 
          <MessageCard key={msg[MESSAGE_ID]} message={msg} setLikes={setLikes} setComments={setComments} setCommentLikes={setCommentLikes} removeComment={removeComment} removeMessage={removeMessage}/>
        )}
      </Box>
      
      </Container>
      
      
      
        
        
      {/* </Grid> */}
      
    </Container>
  )
}