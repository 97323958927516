import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Dialog, Divider, IconButton, List, ListItem, Menu, MenuItem, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { COMPANY_ID, COMPANY_NAME, CONTRACT_GROUP, COVER, COVER_TYPE, FIRST_NAME, NAME, PK, PREFIX_CG, PREFIX_U, REGIONAL_GROUP, SITE_ID, SITE_NAME, SK, USERNAME } from '../../DynamoDbConstants'
import { groupRGData, groupRGDataByCompany } from '../../services/dataSortingService'
import { addCover, fetchAllForRG } from '../../services/dynamoDBservice'
import AppChipGroup from '../chips/AppChipGroup'
import ArrangeCoverDialog from '../dialogs/ArrangeCoverDialog'
import CompanySitesList from '../lists/CompanySitesList'
import DeleteIcon from '@mui/icons-material/Delete';
import CoverList from '../lists/CoverList'
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContractMenu from '../menu/ContractMenu'

export default function RMHomeContainer(props) {
  const testUser = useOutletContext()
  const navigate = useNavigate()
  const [cgData, setCgData] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [showCoverDialog, setShowCoverDialog] = useState(false)

  

  const fetchData = useCallback(() => {
    fetchAllForRG(testUser[PK], testUser[REGIONAL_GROUP])
      .then(res => {
        setCgData(groupRGDataByCompany(groupRGData(res.Items)))
      })
      .catch(err => {
        console.log(err)
      })
  }, [testUser])

  useEffect(() => {
    if (testUser[REGIONAL_GROUP]) {
      fetchData()
    } else {
      setCgData([])
    }
    
  }, [fetchData, testUser])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const onCoverDialogSubmit = (formData) => {
    setShowCoverDialog(false)
    console.log(formData)
    //return
    addCover(formData)
      .then(res => {
        fetchData()
      })
      .catch(err => {
        console.log(err)
      })
  }

  

  const onMenuOptionClicked = (value, data) => {
    switch (value) {
      case 'Arrange cover':
        setShowCoverDialog(data.key)
        break;
      case 'Edit User':
        const formData = {
          user: data.user,
          [PK]: testUser[PK],
          new: false,
          // REGIONAL_GROUP and CONTRACT_GROUP are set to -111 so that the autocompletes have something to check against
          regionalGroupHolder: { [NAME]: '', [REGIONAL_GROUP]: '-111' },
          contractGroupHolder: { [NAME]: '', [CONTRACT_GROUP]: '-111' },
          stockSite: { [COMPANY_NAME]: '', [SITE_ID]: '-111' }
        }
        navigate(`admin/edit-user/${data.key}`, { state: formData })
        break;
      default:
        console.log('default option clicked', value)
    }
    
  }

  return (
    <Box>
      <Stack spacing={2}>
        {cgData.map(data =>
        //console.log(data)
        //console.log(data)
        (data.CGN &&
          <Card key={data.key} variant='outlined'>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 2 }}>
                <Typography sx={{ fontSize: 18 }}>
                  {data.CGN}
                </Typography>
                {/* <Button onClick={(e) => setShowCoverDialog(data.key)}>
                      Arrange cover
                    </Button> */}
                <ContractMenu data={data} onMenuOptionClicked={onMenuOptionClicked} />
              </Box>
              {(data.user && data.user[COVER]) &&
                <CoverList data={data} refresh={fetchData} />
              }
              {data.companies &&
                <Accordion expanded={expanded === data.key} elevation={0} disableGutters variant='outlined' onChange={handleChange(data.key)}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`${data.CGN}=content`}
                    id={`${data.CGN}=header`}
                  >
                    <Typography sx={{ fontSize: 16, width: '100%' }}>
                      Sites
                    </Typography>
                    {(!(expanded === data.key) && data.data.find(d => d.key === 'AP')) &&
                      <AppChipGroup apps={data.data.find(d => d.key === 'AP').data} size='small' scrapAndMissing={true} />
                    }

                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    {data.companies.map(company =>
                      <CardContent key={company[COMPANY_ID]} sx={{ pb: 0 }}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                          {company[COMPANY_NAME]}
                        </Typography>
                        <CompanySitesList company={company} scrapAndMissing={true} />
                      </CardContent>
                    )}
                  </AccordionDetails>
                </Accordion>
              }

            </CardContent>
            <Dialog open={showCoverDialog === data.key} scroll='body'>
              <ArrangeCoverDialog
                contractGroup={data.contractGroup}
                onSubmit={onCoverDialogSubmit}
                onCancelled={() => setShowCoverDialog(false)}
              />
            </Dialog>
          </Card>
        )
        )}
      </Stack>
    </Box>
  )
}