import { useEffect, useState } from "react"
import { fetchAllCompanies, fetchAllRegions } from "../../services/dynamoDBservice"
import PropTypes from 'prop-types'
import { COMPANY_ID, FIRST_NAME, NAME, PK, REGIONAL_GROUP, SITE_ID } from "../../DynamoDbConstants"
import { AutoCompleteWithAddNew } from "../inputs/autoComplete"
import { Autocomplete, createFilterOptions, TextField } from "@mui/material"

// deals with displaying and fetching a list of regional group items
/*
 props:
  user: current logged in user
  formData: parent form object
  setFormData: method to update formData
  error: boolean for displaying error
  setError: method to remove error
  disabled: optional boolean to disable autocomplete
  helperText: optional helper text
*/
export default function RegionsAutoComplete (props) {

  // state to hold the fetched region group items
  const [regions, setRegions] = useState([])
  const [regionsLoading, setRegionsLoading] = useState(false)

  // checks to see if part of add or edit form
  useEffect(() => {

    if (!props.formData.new || props.defaultRM) {
      // if part of edit, calls onRegionClicked method with the current regional group ID
      // this will then display the current region in the edit form
      console.log('not new')
      if (props.user && props.defaultRM) { onRegionClicked(props.user[REGIONAL_GROUP]); return }
      if (props.formData.site) { onRegionClicked(props.formData.site[REGIONAL_GROUP]); return }
      if (props.formData.user) { onRegionClicked(props.formData.user[REGIONAL_GROUP]); return }
    }
    if (props.defaultValue) {
      setRegions([{ [NAME]: 'NO', [REGIONAL_GROUP]: '-111'}])
    }
    
  }, [props.formData.new, props.defaultValue, props.stockSite])

  const onRegionClicked = (regionalId) => {
    // checks to see if regions have already been fetched
    if ((props.defaultValue && regions.length < 2) || (!props.defaultValue && regions.length < 1)) {
      setRegionsLoading(true)
      // dynamoDB call to fetch regions
      fetchAllRegions(props.user[PK])
        .then(res => {
          // tells the autocomplete loading has finished
          setRegionsLoading(false)

          var filtered = res.Items

          // sets the regions state to the returned items
          // joins existing regions if already exists
          //if (regions.length > 0) {
            setRegions((regions) => [...regions, ...filtered])
          //} else {
           // setRegions(res.Items)
          //}
          
          // checks to see if on edit form
          if (!props.formData.new || props.defaultRM) {
            // using passed in regional group ID, finds the correct regional group
            const rg = res.Items.filter(item => item[REGIONAL_GROUP] === regionalId)[0]
            // checks matching regional group exists, settings formData.regionalGroup to its value if it does
            if (rg) { props.setFormData((formData) => ({...formData, regionalGroupHolder: rg })) }
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  // called from autocomplete when its value changes
  const onRegionalGroupChanged = (value)=> {
    console.log(value)
    // resets any error that might be showing on autocomplete
    if (props.setError) {
      props.setError(false)
    }
    // updates formData.regionalGroup to equal new value
    props.setFormData((formData) => ({...formData, regionalGroupHolder: value }))
  }

  return (
    <Autocomplete
      disablePortal
      id="regions-autocomplete"
      // the values to display in the autocomplete
      options={regions}
      // default value to display
      value={props.formData.regionalGroupHolder}
      // called when new value selected
      onChange={(e, v) => onRegionalGroupChanged(v)}
      // called when autocomplete initially opened
      onOpen={onRegionClicked}
      // displays loading message
      loading={regionsLoading}
      // renders the display of the autocomplete
      disableClearable
      disabled={props.disabled} 
      renderInput={(params) => {
        const inputProps = params.inputProps;
        inputProps.autoComplete = "none";
        return (
          <TextField 
            {...params} 
            label="Regional Manager"
            disabled={props.disabled} 
            required={true} 
            error={props.error} 
            inputProps={inputProps} 
            helperText={props.helperText} 
          /> 
        )}}
      getOptionLabel={option => option[FIRST_NAME] ? `${option[FIRST_NAME]} ${option[NAME]}` : `${option[NAME]}`}
      isOptionEqualToValue={(option, value) => option[REGIONAL_GROUP] === value[REGIONAL_GROUP]}
    />
  )
}

RegionsAutoComplete.propTypes = {
  user: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  error: PropTypes.bool,
  setError: PropTypes.func,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  defaultValue: PropTypes.bool,
  defaultRM: PropTypes.bool
}