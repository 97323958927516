import { Alert, Autocomplete, Box, Button, Container, createFilterOptions, Dialog, Snackbar, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { CLEANING_COMPANY_ID, COMPANY_ID, COMPANY_NAME, CONTRACT_GROUP, CUSTOMER_ID, CUSTOMER_NAME, EMAIL, ID, NAME, PK, POSTCODE, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE, SITE_ADDR, SITE_CONTACT, SITE_EMAIL, SITE_ID, SITE_NAME, SITE_TEL, SK, USER_ID } from "../../DynamoDbConstants";
import PropTypes from 'prop-types'
import CompanyAutoComplete from "../autoCompletes/CompanyAutoComplete";
import RegionsAutoComplete from "../autoCompletes/RegionsAutoComplete";
import { fetchCleaningCompany } from "../../services/dynamoDBservice";

// https://www.freecodecamp.org/news/beginner-react-project-build-basic-forms-using-react-hooks/

export default function AddStockSiteForm(props) {
  const testUser = useOutletContext()

  const [companyError, setCompanyError] = useState(false)
  const [siteError, setSiteError] = useState(false)
  const [rgError, setRgError] = useState(false)
  const [showError, setShowError] = useState(false)

  const [CC, setCC] = useState('')

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  }

  useEffect(() => {
    fetchCleaningCompany(testUser[PK])
      .then(res => {
        console.log(res)
        if (res.Items) {
          setCC(res.Items[0])
          props.setFormData((formData) => ({...formData, companyHolder: {[NAME]: res.Items[0][NAME], [COMPANY_ID]: res.Items[0][CLEANING_COMPANY_ID] } }))
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [testUser])

  useEffect(() => {
    const checkForm = () => {
      console.log(props.formData)
      var formError = false
      if (!props.formData.site[NAME]) {
        setSiteError(true)
        formError = true
      }
      if (!props.formData.companyHolder[NAME]) {
        setCompanyError(true)
        formError = true
      }
      if (!props.formData.regionalGroupHolder[NAME]) {
        setRgError(true)
        formError = true
      }
      console.log(props.formData)

      if (formError) {
        setShowError(true)
        props.setCheckForm(false)
        return
      }

      props.submitForm()
    }

    if (props.checkForm) {
      checkForm()
    }

  }, [props.checkForm, props.formData])

  const onSiteChange = (event) => { setSiteError(false); props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [NAME]: event.target.value } })) }
  const onContactChange = (event) => { props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [SITE_CONTACT]: event.target.value } })) }
  const onAddressChange = (event) => { props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [SITE_ADDR]: event.target.value } })) }
  const onPostcodeChange = (event) => { props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [POSTCODE]: event.target.value } })) }
  const onTelNoChange = (event) => { props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [SITE_TEL]: event.target.value } })) }
  const onEmailChange = (event) => { props.setFormData((formData) => ({ ...formData, site: { ...formData.site, [SITE_EMAIL]: event.target.value } })) }

  return (
    <Stack spacing={1}>
      <TextField
        id="outlined-basic-cc-field"
        label="Company"
        variant="outlined"
        value={CC ? CC[NAME] : ''}
        disabled={true}
      />
      <TextField
        id="outlined-basic"
        label="Site Name"
        variant="outlined"
        value={props.formData.site[NAME]}
        onChange={onSiteChange}
        required
        error={siteError}
        inputProps={{
          autoComplete: "none"
        }} />
      <RegionsAutoComplete
        user={testUser}
        formData={props.formData}
        setFormData={props.setFormData}
        error={rgError}
        setError={setRgError}
        defaultRM={true}
        disabled={testUser[ROLE] === REGIONAL_MANAGER}
      />
      <TextField
        id="outlined-basic"
        label="Contact Name"
        variant="outlined"
        value={props.formData.site[SITE_CONTACT] ? props.formData.site[SITE_CONTACT] : ''}
        onChange={onContactChange}
        inputProps={{
          autoComplete: "none"
        }} />
      <TextField
        id="outlined-basic"
        label="Address"
        variant="outlined"
        value={props.formData.site[SITE_ADDR] ? props.formData.site[SITE_ADDR] : ''}
        onChange={onAddressChange}
        multiline
        inputProps={{
          autoComplete: "none"
        }} />
      <TextField
        id="outlined-basic"
        label="Postcode"
        variant="outlined"
        value={props.formData.site[POSTCODE] ? props.formData.site[POSTCODE] : ''}
        onChange={onPostcodeChange}
        inputProps={{
          autoComplete: "none"
        }}
      />
      <TextField
        id="outlined-basic"
        label="Tel No"
        variant="outlined"
        value={props.formData.site[SITE_TEL] ? props.formData.site[SITE_TEL] : ''}
        onChange={onTelNoChange}
        inputProps={{
          autoComplete: "none"
        }} />
      <TextField
        id="outlined-basic"
        label="Email"
        variant="outlined"
        value={props.formData.site[SITE_EMAIL] ? props.formData.site[SITE_EMAIL] : ''}
        onChange={onEmailChange}
        inputProps={{
          autoComplete: "none"
        }}
      />
      <Snackbar open={showError} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
          Some required form fields not filled out.
        </Alert>
      </Snackbar>
    </Stack>

  )
}

AddStockSiteForm.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  checkForm: PropTypes.bool,
  setCheckForm: PropTypes.func,
  submitForm: PropTypes.func
}