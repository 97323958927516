import { Box, Dialog } from "@mui/material";
import ConfirmDeleteDialog from "../dialogs/ConfirmDeleteDialog";
import ErrorDialog from "../dialogs/ErrorDialog";
import PropTypes from 'prop-types'


export default function EditCompanyDialogGroup (props) {
  return (
    <Box>
      <Dialog open={props.showDeleteDialog}>
        <ConfirmDeleteDialog 
          title='Delete Company'
          text='Are you sure? This will permanently delete this Company.'
          onSubmit={props.onDeleteConfirmClicked}
          onCancelled={props.onDeleteCancelClicked}
        />
      </Dialog>
      <Dialog open={props.showErrorDialog}>
        <ErrorDialog 
          title='Unable to Delete'
          text='Companies cannot be deleted until all associated Sites have been deleted.'
          onCancelled={props.onErrorCancelClicked}
        />
      </Dialog>
    </Box>
  )
}

EditCompanyDialogGroup.propTypes = {
  showDeleteDialog: PropTypes.bool,
  onDeleteConfirmClicked: PropTypes.func,
  onDeleteCancelClicked: PropTypes.func,
  showErrorDialog: PropTypes.bool,
  onErrorCancelClicked: PropTypes.func
}