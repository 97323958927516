import { useLocation } from "react-router-dom"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, CircularProgress, Container, Grid, Stack, TextField, Typography } from "@mui/material";
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { APP_DESCR, APP_ID, APP_SERIAL, AUDIT_DATE, COMPANY_NAME, IS_STOCK_SITE, MISSING_DATE, NAME, PAT_DATE, PK, SCRAP_DATE, SITE_NAME } from "../../DynamoDbConstants";
import { fetchApp } from "../../services/dynamoDBservice";
import ActionButtonGroup from "../../components/buttonGroups/ActionButtonGroup";
import { checkApplianceExpired, unixToAppDate } from "../../services/dateServices";
import ApplianceHistory from "../../components/tables/ApplianceHistory";

// page for showing specifc appliance
export default function Appliance () {
  const location = useLocation()
  const testUser = useOutletContext()
  const navigate = useNavigate()

  //const [appInfo, setAppInfo] = useState({ [APP_ID] })
  const [app, setApp] = useState([])

  // function for fetching appliance item from dynamo
  // will be called on initial page load from useEffect, and also when app needs to be refreshed (when it's attributes/status changes)
  const fetchAppInfo = useCallback(() => {
    // passes in user partition key and appInfo[NAME], which is equal to APP_ID
    fetchApp(testUser[PK], location.state[NAME])
    .then(res => {
      setApp(res.Items[0])
    })
    .catch(err => {
      console.log(err)
    })
  }, [location.state[NAME], testUser[PK]])

  // fetches app info when page is loaded
  useEffect(() => {
    fetchAppInfo()
  }, [fetchAppInfo, location.state, testUser[PK]])

  return (
    <Container style={{ paddingTop: '20px' }} maxWidth="md">
      {/* buttons that are displayed at the top of the appliance view, and allow certain actions on the appliance */}
      <ActionButtonGroup 
        selectedApps={[app]}
        refresh={fetchAppInfo}
        partialRefresh={fetchAppInfo}
        goBack={navigate}
        isSiteView={false}
        isStockSite={app[IS_STOCK_SITE]}
      />
      <Grid>
        <Grid item>
        <Typography align="center" variant="h6">
          Appliance ID
        </Typography>
        </Grid>
        <Grid item>
        <Typography align="center" variant="h4" sx={{ color: 'green', fontWeight: 'bold' }}>
          {app[APP_ID]}
        </Typography>
        { app[MISSING_DATE] && 
          <Typography align="center" variant="h5" sx={{ color: 'red' }}>
            (MISSING)
          </Typography>
        }
        { app[SCRAP_DATE] && 
          <Typography align="center" variant="h5" sx={{ color: 'red' }}>
            (SCRAPPED)
          </Typography>
        }
        </Grid>
        
      </Grid>
      
      {app.length !== 0 && 
      <div>
      <TextField
          margin="dense"
          id="company-name"
          label="Company Name"
          fullWidth
          //variant="standard"
          value={app[COMPANY_NAME]}
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          margin="dense"
          id="site-name"
          label="Site Name"
          fullWidth
          //variant="standard"
          value={app[SITE_NAME]}
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          margin="dense"
          id="app-ID"
          label="Appliance ID"
          fullWidth
          //variant="standard"
          value={app[APP_ID]}
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          margin="dense"
          id="app-make"
          label="Make"
          fullWidth
          //variant="standard"
          value={app[NAME] ? app[NAME] : ''}
          InputProps={{
            readOnly: true
          }}
        />
        { app[APP_DESCR] &&
          <TextField
            margin="dense"
            id="app-description"
            label="Description"
            fullWidth
            //variant="standard"
            value={app[APP_DESCR]}
            InputProps={{
              readOnly: true
            }}
          />
        }
        
        { app[APP_SERIAL] &&
          <TextField
            margin="dense"
            id="app-serial"
            label="Serial number"
            fullWidth
            //variant="standard"
            value={app[APP_SERIAL]}
            InputProps={{
              readOnly: true
            }}
        />
        }
        <TextField
          margin="dense"
          id="app-PAT"
          label="PAT date"
          fullWidth
          error={checkApplianceExpired(app)}
          //variant="standard"
          value={(app[PAT_DATE] !== 0) ? unixToAppDate(app[PAT_DATE]) : ''}
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          margin="dense"
          id="app-audit"
          label="Last audit"
          fullWidth
          //variant="standard"
          value={(app[AUDIT_DATE] !== 0) ? unixToAppDate(app[AUDIT_DATE]) : ''}
          InputProps={{
            readOnly: true
          }}
        />
        { app[MISSING_DATE] &&
          <TextField
            margin="dense"
            id="app-missing"
            label="Marked as missing"
            fullWidth
            //variant="standard"
            value={(app[MISSING_DATE] !== 0) ? unixToAppDate(app[MISSING_DATE]) : ''}
            InputProps={{
              readOnly: true
            }}
        />
        }
        {/* accordian which when expanded will show the appliances log history */}
        <Accordion TransitionProps={{ unmountOnExit: true }} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>View Appliance history</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ApplianceHistory appId={app[APP_ID]}/>
          </AccordionDetails>
        </Accordion>
      </div>
      }
    </Container>
  )
}