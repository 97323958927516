import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import PropTypes from 'prop-types'
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { COMPANY_NAME, NAME, PK, SITE_ID, SK } from "../../DynamoDbConstants";
import { fetchAllSites } from "../../services/dynamoDBservice";

export default function FoundAppDialog (props) {
  const testUser = useOutletContext()
  const [selected, setSelected] = useState('')
  const [sitesLoading, setSitesLoading] = useState(false)
  const [allSites, setAllSites] = useState([])

  useEffect(() => {
    if (allSites.length < 1) {
      setSitesLoading(true)
      //setTimeout(function() {
      fetchAllSites(testUser[PK])
        .then(res => {
          //setRegions((regions) => [...regions, ...res.Items])
          setAllSites((allSites) => [...allSites, ...res.Items])
          setSelected(res.Items.filter(site => site[SITE_ID] === props.siteId)[0])
          console.log(res.Items.filter(site => site[SITE_ID] === props.siteId)[0])
          setSitesLoading(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [allSites.length, props.siteId, testUser[PK]])

  const onSiteClicked = (value, site) => {
    setSelected(site)
    console.log(value)
  }

  const getOptionName = (option) => {
    if (!option) {
      return ''
    }
    if (option[NAME]) {
      return `${option[COMPANY_NAME]}, ${option[NAME]}`
    }
    return option[COMPANY_NAME]
  }

  return (
    <div>
      <DialogTitle>Found App</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select where Appliance was found.
        </DialogContentText>
        <Autocomplete
            id="combo-box-demo"
            options={allSites.map((site) => site)}
            sx={{ width: "md" }}
            value={selected}
            renderInput={(params) => <TextField {...params} label="Found at" variant="standard"/>}
            renderOption={(props, option) => (
              <li {...props} key={option[SK]}>
                {getOptionName(option)}
              </li>
            )}
            getOptionLabel={option => getOptionName(option)}
            isOptionEqualToValue={(option, value) => option[SK] === value[SK]}
            onChange={(e, value) => onSiteClicked(value?.[COMPANY_NAME], value)}
            loading={sitesLoading}
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancelled}>Cancel</Button>
        <Button onClick={(e) => props.onSubmit(selected)}>Submit</Button>
      </DialogActions>
    </div>
  )
}

FoundAppDialog.propTypes = {
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func
}