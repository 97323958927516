import { Box, Container, Dialog, Stack } from "@mui/material";
import { useState } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid';
import { DeleteButton, StandardButton } from "../../components/buttons/customButtons";
import AddEditUserForm from "../../components/forms/AddEditUserForm";
import { CONTRACT_GROUP, CONTRACT_MANAGER, EMAIL, ID, NAME, PK, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE, SK, USERNAME, USER_ID } from "../../DynamoDbConstants"
import { createUser, deleteUser } from "../../services/cognitoService";
import { splitPk } from "../../services/dataSortingService";
import { addDynamoUser, addGroupItem, deleteUserDynamo, fetchCG, fetchUser, fetchUserWithPK, updateDynamoUser, updateGroupItem } from "../../services/dynamoDBservice";
import ConfirmDeleteDialog from "../../components/dialogs/ConfirmDeleteDialog";

// add/edit user page
export default function AddEditUser() {
  // React router hook to navigate between pages
  const navigate = useNavigate()
  // React router hook which gets the passed in props from parent page (navigated from)
  const location = useLocation()
  // React router hook to get whatever was passed in at the parent component (App.js)
  const testUser = useOutletContext()

  const [formData, setFormData] = useState(location.state)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)
  const [checkForm, setCheckForm] = useState(false)

  // email error is in parent component so cognito user already exists error can be dealt with
  const [EError, setEError] = useState(false)
  const [EEText, setEEText] = useState('')

  const onDeleteConfirmClicked = () => {
    // 1: delete user from cognito
    deleteUser(formData.user[USER_ID])
      .then(res => {
        console.log('cognito user deleted', res)
        // 2: if successful, delete user from dynamo
        deleteUserDynamo(formData.user[PK], formData.user[SK])
          .then(res => {
            console.log('dynamo user deleted', res)
            navigate(-1)
          })
          .catch(err => {
            console.log('failed to delete dynamo user', err)
          })
      })
      .catch(err => {
        console.log('failed to delete cognito user', err)
      })

  }

  const onDeleteCancelClicked = () => { setShowDeleteDialog(false) }

  const onSubmitClicked = (e) => {
    setCheckForm(true)
  }

  const submitForm = () => {
    setCheckForm(false)
    formData.newUser = false
    //if it's a contract manager and they are not taking over a new contract group then we need to give them a contract group
    //which surely must have a name? We also set the new property to true so we know to create a new contract group
    if (formData.new && (formData.user[ROLE] === CONTRACT_MANAGER) && formData.contractGroupHolder[NAME] === "NO") {
      let group_id = uuidv4().toString()
      formData.contractGroupHolder = { [NAME]: "NO", [CONTRACT_GROUP]: group_id }
      formData.newUser = true
    }
    //if it's a regional manager and they are not taking on another regonal group then set a new ID in the regional group
    if (formData.new && formData.user[ROLE] === REGIONAL_MANAGER && formData.regionalGroupHolder[NAME] === "NO") {
      let group_id = uuidv4().toString()
      formData.regionalGroupHolder = { [NAME]: "NO", [REGIONAL_GROUP]: group_id }
      formData.contractGroupHolder = { [NAME]: "NO", [CONTRACT_GROUP]: group_id }
      formData.newUser = true
    }

    console.log(formData)

    // checks to see if on edit or add page (new = add)
    if (formData.new) {
      // creates a cognito user, using the set Email and the company ID (extracted from the users Partition Key)
      createUser(formData.user[EMAIL], splitPk(testUser[PK]))
        .then(res => {
          console.log(res)
          // sets the formData.user USER_ID equal to the created Cognito users Username(sub)
          formData.user[USER_ID] = res.User.Username
          // creates a dynamo user item using the completed formData
          addDynamoUser(formData)
            .then(result => {
              console.log(result)
              // if the new users role is contract or regional manager, then a contract/regional group will need to be created/updated
              if (formData.user[ROLE] === REGIONAL_MANAGER || formData.user[ROLE] === CONTRACT_MANAGER) {
                // if new user, create a new contract/regional group
                if (formData.newUser) {
                  addGroupItem(formData)
                    .then(res => {
                      console.log(res)
                      //resetForm()
                      navigate(-1)
                    })
                    .catch(err => {
                      console.log(err)
                    })
                } else {
                  // if not a new user, then they must be taking over existing contract/regional group, so updates the corresponding item on dynamo
                  if (formData.user[ROLE] === REGIONAL_MANAGER) {
                    // fetch the regional managers user item (to get their associated CG)
                    fetchUserWithPK(testUser[PK], formData.regionalGroupHolder[USERNAME])
                      .then(res => {
                        if (res.Items.length > 0) {
                          if (res.Items[0][CONTRACT_GROUP]) {
                            formData.regionalGroupHolder[CONTRACT_GROUP] = res.Items[0][CONTRACT_GROUP]
                          }
                          updateGroupItem(formData)
                            .then(res => {
                              console.log(res)
                              //resetForm()
                              navigate(-1)
                            })
                            .catch(err => {
                              console.error(err)
                            })
                        }
                      })
                      .catch(err => {
                        console.error(err)
                      })
                  } else {
                    updateGroupItem(formData)
                      .then(res => {
                        console.log(res)
                        //resetForm()
                        navigate(-1)
                      })
                      .catch(err => {
                        console.error(err)
                      })
                  }

                }
              } else {
                console.log(`User of type ${formData.user[ROLE]} successfully added`)
                navigate(-1)
              }
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(err => {
          console.log(err)
          if (err.name === "UsernameExistsException") {
            setEEText('User with chosen email already exists')
            setEError(true)
          }
        })
    } else {
      // called when on Edit User page and form is submitted
      updateDynamoUser(formData)
        .then(res => {
          console.log('updated dynamo user', res)
          navigate(-1)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <Container style={{ paddingTop: '20px' }} maxWidth="md">
      <Stack spacing={1}>
        {!formData.new &&
          <Box display='flex' justifyContent='flex-end' sx={{ pb: 1 }}>
            <DeleteButton
              onClick={() => setShowDeleteDialog(true)}
            />
          </Box>
        }
        <AddEditUserForm
          formData={formData}
          setFormData={setFormData}
          checkForm={checkForm}
          setCheckForm={setCheckForm}
          submitForm={submitForm}
          EError={EError}
          setEError={setEError}
          EEText={EEText}
          setEEText={setEEText}
        />
        <StandardButton
          fullWidth={true}
          label='Submit'
          onClick={onSubmitClicked}
        />
        <Dialog open={showDeleteDialog}>
          <ConfirmDeleteDialog
            title='Delete User'
            text='Are you sure? This will permanently delete this user.'
            onCancelled={onDeleteCancelClicked}
            onSubmit={onDeleteConfirmClicked}
          />
        </Dialog>

      </Stack>
    </Container>
  )
}