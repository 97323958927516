import { Box, Container, Dialog, Stack } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import AddSiteButtonGroup from "../../components/buttonGroups/AddSiteButtonGroup";
import { StandardButton } from "../../components/buttons/customButtons";
import AddSiteDialogGroup from "../../components/dialogGroups/AddSiteDialogGroup";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import AddEditSiteForm from "../../components/forms/AddEditSiteForm";
import { APP_SITE_ID, CONTRACT_GROUP, IS_STOCK_SITE, NAME, PK, SITE_ID, SITE_NAME } from "../../DynamoDbConstants";
import { addNewCompanyAndSite, addNewSite, deleteSite, fetchAppsForSite, updateAppCG } from "../../services/dynamoDBservice";

// Add and Edit site page
export default function AddEditSite() {
  // React Router hook to navigate between pages
  const navigate = useNavigate()
  // React Router hook which gets the passed in props from parent page (navigated from)
  const location = useLocation()
  // React Router hook to get whatever was passed in at the parent component (App.js)
  const testUser = useOutletContext()

  const [formData, setFormData] = useState(location.state)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)
  const [checkForm, setCheckForm] = useState(false)

  const [showAppErrorDialog, setShowAppErrorDialog] = useState(false)
  const [appErrorCount, setAppErrorCount] = useState({ total: 0, err: 0 })

  // function to navigate to Site view on view site clicked
  const onViewSiteClicked = () => {
    // splits site name up and sets lower case, removing blanks
    const elements = formData.site[NAME].toLowerCase().replaceAll('/', ' ').split(' ').filter(element => element)
    console.log(elements)
    if (elements.length > 1) {
      // if site name was more than 2 words, navigates using the elements joined by '-'
      
      navigate(`/appliance-home/admin/view-site/${elements.join('-')}`, { state: formData.site })
      return
    }
    // otherwise navigate just using the site name
    navigate(`/appliance-home/admin/view-site/${formData.site[NAME].toLowerCase().trim()}`, { state: formData.site })
  }

  // called when delete button is clicked
  const onDeleteClicked = () => {
    // dynamoDB call to fetch all appliances for current site
    fetchAppsForSite(testUser[PK], formData.site[SITE_ID])
      .then(res => {
        // if the site has appliances, inform user that the site cannot be deleted
        if (res.Items.length > 0) {
          setShowErrorDialog(true)
        } else {
          setShowDeleteDialog(true)
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  // called when delete confirmation clicked
  const onDeleteConfirmClicked = () => {
    setShowDeleteDialog(false)
    // dynamoDB call to delete the current site
    deleteSite(testUser[PK], formData.site[SITE_ID])
      .then(res => {
        console.log('site deleted successfully')
        // navigates back to site list
        navigate(-1)
      })
      .catch(err => {
        console.error(err)
      })
  }

  // called when form submit is clicked
  const onSubmitClicked = (e) => {
    console.log(formData)
    // sets the checkForm boolean to true, which triggers a form check in child form component
    setCheckForm(true)
  }

  // called from child form component if form check was successful
  const submitForm = () => {
    setCheckForm(false)
    // if the company in the form is new, adds a new company and site
    if (formData.companyHolder.new) {
      console.log('adding company and site')
      //if (!formData.contractGroupHolder.newRgContract) {
        addNewCompanyAndSite(formData)
          .then(res => {
            console.log(res)
            navigate(-1)
          })
          .catch(err => {
            console.error(err)
          })
      //} else {
        // addNewCompanyAndSiteWithRGContract(formData)
        //   .then(res => {
        //     console.log(res)
        //     navigate(-1)
        //   })
        //   .catch(err => {
        //     console.error(err)
        //   })
     // }

    } else {
      // otherwise just add the new site
      console.log('adding site')
      //if (!formData.contractGroupHolder.newRgContract) {
        // checks if on edit site and changing CG 
        if (!formData.new && (formData.site[CONTRACT_GROUP] !== formData.contractGroupHolder[CONTRACT_GROUP] || (formData.oldSiteName && formData.oldSiteName !== formData.site[NAME]))) {
          // #1 duplicate site item with new CG details
          // keeps track of old site id so it can be used to delete the old site on success
          formData.oldSiteId = formData.site[SITE_ID]
          // sets formData.site SITE_ID to null so new ID is created
          formData.site[SITE_ID] = null
          addNewSite(formData)
            .then(res => {
              console.log('duplicate site added', res)
              duplicateSiteAndApps()
            })
            .catch(err => {
              console.error(err)
            })

        } else {
          addNewSite(formData)
            .then(res => {
              console.log('new site added', res)
              navigate(-1)
            })
            .catch(err => {
              console.error(err)
            })
        }
      //} else {
        // if (!formData.new && (formData.site[CONTRACT_GROUP] !== formData.contractGroupHolder[CONTRACT_GROUP] || (formData.oldSiteName && formData.oldSiteName !== formData.site[NAME]))) {
        //   // #1 duplicate site item with new CG details
        //   // keeps track of old site id so it can be used to delete the old site on success
        //   formData.oldSiteId = formData.site[SITE_ID]
        //   // sets formData.site SITE_ID to null so new ID is created
        //   formData.site[SITE_ID] = null
        //   addNewSiteWithRGContract(formData)
        //   .then(res => {
        //     console.log('new site added with RG contract + duplicate site added', res)
        //     duplicateSiteAndApps()
        //   })
        //   .catch(err => {
        //     console.error(err)
        //   })
        // } else {
        //   addNewSiteWithRGContract(formData)
        //   .then(res => {
        //     console.log('new site added with RG contract', res)
        //     navigate(-1)
        //   })
        //   .catch(err => {
        //     console.error(err)
        //   })
       // }
        
      //}

    }
  }

  const duplicateSiteAndApps = () => {
    // #2 move old CG site apps in duplicated site
    const promises = []
    formData.site.apps.forEach(app => {
      // updates the app attributes that need to be updated
      app[APP_SITE_ID] = formData.site[SITE_ID]
      app[SITE_NAME] = formData.site[NAME]
      if (!formData.site[IS_STOCK_SITE]) {
        app[CONTRACT_GROUP] = formData.contractGroupHolder[CONTRACT_GROUP]
      } else {
        app[CONTRACT_GROUP] = undefined
      }
      promises.push(updateAppCG(app))
    })

    Promise.allSettled(promises)
      .then(res => {
        console.log('site apps moved CG', res)
        const rejected = res.filter(r => r.status === 'rejected')
        if (rejected.length > 0) {
          // #3 if any apps fail to move, inform user
          setAppErrorCount((errorCount) => ({ ...errorCount, total: formData.site.apps.length, err: rejected.length }))
          setShowAppErrorDialog(true)
          console.log(`${rejected.length} apps failed to move`)
        } else {
          // #4 if all apps moved successfully, delete old site
          console.log(`${res.length} apps successfully moved`)
          deleteSite(testUser[PK], formData.oldSiteId)
            .then(res => {
              console.log('old site deleted successfully')
              navigate(-1)
            })
            .catch(err => {
              console.error('failed to delete old site', err)
              navigate(-1)
            })

        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  const onDeleteCancelClicked = () => { setShowDeleteDialog(false) }
  const onErrorCancelClicked = () => { setShowErrorDialog(false) }
  const onAppErrorCancelClicked = () => { setShowAppErrorDialog(false); navigate(-1) }

  return (
    <Container style={{ paddingTop: '20px' }} maxWidth="md">
      {/* show the view/delete buttons only if editing a site */}
      <Stack spacing={1}>
        {!formData.new &&
          <AddSiteButtonGroup
            onViewSiteClicked={onViewSiteClicked}
            onDeleteSiteClicked={onDeleteClicked}
          />
        }
        <AddEditSiteForm
          formData={formData}
          setFormData={setFormData}
          checkForm={checkForm}
          setCheckForm={setCheckForm}
          submitForm={submitForm}
        />
        <StandardButton
          fullWidth={true}
          label={formData.new ? 'Submit' : 'Save changes'}
          onClick={onSubmitClicked}
        />
        <AddSiteDialogGroup
          showDeleteDialog={showDeleteDialog}
          showErrorDialog={showErrorDialog}
          onDeleteConfirmClicked={onDeleteConfirmClicked}
          onDeleteCancelClicked={onDeleteCancelClicked}
          onErrorCancelClicked={onErrorCancelClicked}
        />
        <Dialog open={showAppErrorDialog}>
          <ErrorDialog
            title='Error'
            text={`Failed to update ${appErrorCount.err} out of ${appErrorCount.total} appliances. Please try to move them manually.`}
            onCancelled={onAppErrorCancelClicked}
          />
        </Dialog>
      </Stack>

    </Container>
  )
}