
import { Outlet } from 'react-router';
import './App.css';
import * as AWS from 'aws-sdk'
import ResponsiveAppBar from './components/appBar/ResponsiveAppBar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Authenticator, Heading, Image, Text, useTheme, View } from '@aws-amplify/ui-react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useEffect, useState } from 'react';
import { ADMINSTRATOR, CONTRACT_GROUP, CONTRACT_MANAGER, DEFAULT_STOCK_SITE, demo_table, DEVELOPER, IS_ADMIN, IS_DEVELOPER, NAME, PAT_TESTER, PK, PROD_DOMAIN, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE, SK, TABLE_NAME, TEST_DOMAIN, USER_ID } from './DynamoDbConstants';
import awsconfig from './aws-exports';
import { Amplify, Auth, Hub } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { fetchCleaningCompany, fetchUser, testCred } from './services/dynamoDBservice';
import { Box, Container, Typography } from '@mui/material';
import { AMPLIFY_TEST, DEV, RELEASE } from './DevConstants';
import {ReactComponent as AtlasLogo} from './assets/atlas_logo.svg'

// options - RELEASE, RELEASE_RYEFORDS, DEV, AMPLIFY_TEST
const ENV = RELEASE
      
Amplify.configure(ENV.config)

function App() {
  //const [user, setUser] = useState()
  const [testUser, setTestUser] = useState(
    // {
    //   [PK]: 'ID#b1539c68-159d-4f76-8484-a16646247bff',
    //   [SK]: 'RM#89417f67-e99f-4174-b833-962fb7704d22',
    //   [NAME]: 'Vicky Nichols',
    //   [REGIONAL_GROUP]: '3',
    //   [CONTRACT_GROUP]: '3',
    //   [USER_ID]: '89417f67-e99f-4174-b833-962fb7704d22',
    //   [ROLE]: PAT_TESTER
    // }
  )
  const [noCC, setNoCC] = useState(false)

  const listener = (data) => {
    switch (data.payload.event) {
      case 'signIn':
        setNoCC(false)
        console.log('user signed in')
        Auth.currentAuthenticatedUser()
          .then(res => {
            console.log(res)
            navigateToSubdomain(res.username, res.attributes['custom:cc_id'])
            // fetchUser(res.username, ccId)
            //   .then(res => {
            //     if (res.Items.length === 0) {
            //       setTestUser('NO_USER')
            //       return
            //     }
            //     if (res.Items[0][ROLE] === DEVELOPER) {
            //       res.Items[0][IS_DEVELOPER] = true
            //     }
            //     setTestUser(res.Items[0])
            //     console.log(res)
            //     navigateToSubdomain(ccId)
            //   })
            //   .catch(err => {
            //     console.log(err)
            //   })
          })
          .catch(err => {
            console.log(err)
          })
        break
      case 'signUp':
        console.log('user signed up')
        break
      case 'signOut':
        // console.log(data)
        console.log('user signed out')
        break
      case 'signIn_failure':
        console.log('user sign in failed')
        break
      case 'tokenRefresh':
        console.log('token refresh succeeded')
        break
      case 'tokenRefresh_failure':
        console.log('token refresh failed')
        break
      case 'configured':
        console.log('the Auth module is configured')
        break
      default:
        console.log('unknown auth case')
    }
  }

  useEffect(() => {
    Hub.listen('auth', listener)
    return () => {
      Hub.remove('auth', listener)
    }
  })

  useEffect(() => {
    setNoCC(false)
    // checks to see if the user item has been fetched from dynamoDB
    if (!testUser || testUser === 'NO_USER') {
      // if not, gets the current Cognito user (locally cached cookies)
      Auth.currentAuthenticatedUser()
      .then(res => {
          // if successful, calls method to navigate to correct subdomain for users company 
          // passing in Cognito username and cleaning company ID
          navigateToSubdomain(res.username, res.attributes['custom:cc_id'])
        })
        .catch(err => {
          console.log(err)
        })
      }
    }, [testUser])
  

  const navigateToSubdomain = (userId, ccId) => {
    // calls method to fetch cleaning company for user
    fetchCleaningCompany(ccId)
      .then(res => {
        console.log(res)
        // if cleaning company exists
        if (res.Items.length > 0) {
          // gets the current domain name
          const currentDomain = window.location.host
          // checks to see if currently on cleaning companies sub domain
          const ccName = res.Items[0][NAME].toLowerCase()
          // this is achieved by checking if the current domain name includes the cleaning company name
          if (currentDomain.includes(ccName) || ENV.amplify) {          
            // if it does, attempts to fetch dynamoDB user item
            console.log('on correct subdomain, fetching user')
            fetchUser(userId, ccId)
          .then(res => {
                // checks to see if returned user exists
            if (res.Items.length === 0) {
                  // if not, sets local user item to 'NO_USER' which will display message to user
              setTestUser('NO_USER')
              return
            }
                // check to see if logged in user is a developer (allows certain actions in the web app)
            if (res.Items[0][ROLE] === DEVELOPER) {
              res.Items[0][IS_DEVELOPER] = true
            }
            if (res.Items[0][ROLE] === ADMINSTRATOR) {
              res.Items[0][IS_ADMIN] = true
            }
            setTestUser(res.Items[0])
            console.log(res)
          })
          .catch(err => {
            console.log(err)
          })
          } else {
            // if not on the correct subdomain, navigates to said subdomain
            console.log('not on company subdomain, navigating now')
            const domain = `https://${res.Items[0][NAME].toLowerCase()}.${ENV.domain}${ENV === DEV ? ':3000' : ''}/`
            window.location.assign(domain)
          }  
        } else {
          // no cleaning company was found with the given ID
          setNoCC(true)
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
    
  const setUserRole = (role) => {
    console.log(role)
    const rg = role[REGIONAL_GROUP] ? role[REGIONAL_GROUP] : ''
    const cg = role[CONTRACT_GROUP] ? role[CONTRACT_GROUP] : ''
    const ss = role[DEFAULT_STOCK_SITE] ? role[DEFAULT_STOCK_SITE] : ''
    //const rg = '7b2ded78-3a95-45e0-9875-f72a86915e06'
    //const cg = 'd85e3851-1f12-481e-8f4a-811b74ead0fa'
    //const ss = '0de60e19-035f-4eba-9e17-e331125a0ae9'
    //const rg = "3"
    //const cg = "1"
    setTestUser((testUser) => ({...testUser, [ROLE]: role[ROLE]}))
    if (role[ROLE] === REGIONAL_MANAGER) {
      setTestUser((testUser) => ({...testUser, [REGIONAL_GROUP]: rg}))
      setTestUser((testUser) => ({...testUser, [CONTRACT_GROUP]: null}))
      setTestUser((testUser) => ({...testUser, [DEFAULT_STOCK_SITE]: ss}))
    }
    if (role[ROLE] === CONTRACT_MANAGER) {
      setTestUser((testUser) => ({...testUser, [REGIONAL_GROUP]: rg}))
      setTestUser((testUser) => ({...testUser, [CONTRACT_GROUP]: cg}))
      setTestUser((testUser) => ({...testUser, [DEFAULT_STOCK_SITE]: ss}))
    }
    if (role[ROLE] === PAT_TESTER) {
      setTestUser((testUser) => ({...testUser, [DEFAULT_STOCK_SITE]: ss}))
    }
    if (role[ROLE] === ADMINSTRATOR) {
      setTestUser((testUser) => ({...testUser, [REGIONAL_GROUP]: null}))
      setTestUser((testUser) => ({...testUser, [CONTRACT_GROUP]: null}))
      setTestUser((testUser) => ({...testUser, [DEFAULT_STOCK_SITE]: null}))
    }
  }

  const components = {
    SignIn: {
      Header() {
        const { tokens } = useTheme()

        return (
          <Text
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          If you are unsure of your sign in details, please contact your system Administrator.
          <br/>
          Any problems, please <a href="mailto:oscar.nowell@zergles.co.uk">contact us</a>.
        </Text>
        )
      }
    }
  }

  const formFields = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: 'Enter Your Email Here',
        isRequired: true,
        label: 'Email:'
      },
    },
    resetPassword: {
      username: {
        labelHidden: false,
        placeholder: 'Enter Your Email Here',
        isRequired: true,
        label: 'Email:'
      },
    }
  }
  
  return (
    <div className="App">
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Authenticator
        components={components}
        formFields={formFields}
        variation='modal'
        hideSignUp={true}
      >
      {({ signOut, user}) => (
        <div>
          {testUser && testUser !== 'NO_USER' &&
          <div>
          <ResponsiveAppBar user={(testUser && testUser !== 'NO_USER' ? testUser : {})} setRole={setUserRole} signOut={signOut}/>
          { TABLE_NAME === demo_table &&
            <Box>
            <Typography textAlign='center'>
              DEMO SITE
            </Typography>
            </Box>
            
          }
          <Outlet context={testUser}/>
          </div>
          }
          {(testUser === 'NO_USER' || noCC) &&
          <div>
          <ResponsiveAppBar user={(testUser && testUser !== 'NO_USER' ? testUser : {})} setRole={setUserRole} signOut={signOut}/>
          <Container style={{ paddingTop: '20px' }} maxWidth="md">
          {testUser === 'NO_USER' &&
            <Typography variant='h5'>
              Unable to fetch your profile.
              <br/>
              Please contact your system Administrator.
            </Typography>
          }
          {noCC &&
            <Typography variant='h5'>
              Unable to find your associated company in our database.
              <br/>
              Please contact your system Administrator.
            </Typography>
          }
            </Container>
          </div>
          }
        </div>
      )}
        
      </Authenticator>
    </LocalizationProvider>
      
    </div>
  );
}

export default App;
