import { Button } from "@mui/material";
import PropTypes from 'prop-types'
import { IS_STOCK_SITE, NAME } from "../../DynamoDbConstants";

export default function SiteButton (props) {
  return (
    <Button
        variant="outlined"
        value={props.site[NAME]}
        onClick={(e) => props.onClick(e, props.site)}
      >
        {props.site[NAME]} {props.site[IS_STOCK_SITE] ? '(stock)' : ''} {(props.site.apps && props.site.apps.length > 0) ? `(${props.site.apps.length})` : ''}
    </Button>
  )
}

SiteButton.propTypes = {
  onClick: PropTypes.func,
  site: PropTypes.object
}