import { useCallback, useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import PropTypes from 'prop-types'
import { fetchAllForCG, fetchAppsCG, fetchCG, fetchExpiredAppsCG, removeCover } from "../../services/dynamoDBservice"
import { COMPANY_ID, COMPANY_NAME, CONTRACT_GROUP, COVER, COVER_TYPE, FIRST_NAME, NAME, PK, ROLE } from "../../DynamoDbConstants"
import { compareKeys, groupCGData, groupCompanies, groupRGDataByCompany } from "../../services/dataSortingService"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Dialog, Divider, Grid, IconButton, Stack, Typography } from "@mui/material"
import CompanySitesList from "../lists/CompanySitesList"
import { ExpandMore } from "@mui/icons-material"
import AppChipGroup from "../chips/AppChipGroup"
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCoverDialog from "../dialogs/RemoveCoverDialog"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function CMHomeContainer(props) {
  const testUser = useOutletContext()
  const [data, setData] = useState([])
  const [coverData, setCoverData] = useState([])
  const [cover, setCover] = useState()
  const [expanded, setExpanded] = useState(false)
  const [showRemoveCoverDialog, setShowRemoveCoverDialog] = useState(false)

  const fetchCGData = useCallback(() => {
    fetchAllForCG(testUser[PK], testUser[CONTRACT_GROUP])
      .then(res => {
        console.log(groupRGDataByCompany(groupCGData(res.Items))[0])
        setData(groupRGDataByCompany(groupCGData(res.Items))[0])
      })
      .catch(err => {
        console.log(err)
      })
  }, [testUser])

  useEffect(() => {
    if (testUser[CONTRACT_GROUP]) {
      fetchCGData()
    } else {
      setData([])
    }
    
  }, [testUser, fetchCGData])

  const checkCover = useCallback(() => {
    setCoverData([])
    setCover(null)
    if (data.user && data.user[COVER]) {
      const covering = data.user[COVER]
      if (covering && covering[COVER_TYPE] === true) {
        fetchAllForCG(data.user[PK], covering[CONTRACT_GROUP])
          .then(res => {
            console.log(groupRGDataByCompany(groupCGData(res.Items))[0])
            setCoverData(groupRGDataByCompany(groupCGData(res.Items))[0])
          })
          .catch(err => {
            console.log(err)
          })
      }
      setCover(covering)
      //setCoveredBy(data.user[COVER].find(cov => cov[COVER_TYPE] === false))
    }
  }, [data.user])

  useEffect(() => {
    checkCover()
  }, [checkCover, data.user])

  const onRemoveCoverDialogSubmit = (formData) => {
    setShowRemoveCoverDialog(false)
    console.log(formData)
    fetchCG(formData.user[PK], formData.cover[CONTRACT_GROUP])
      .then(res => {
        console.log(res)
        if (res.Items.length > 0) {
          formData.cover.cg = res.Items[0]
          removeCover(formData)
            .then(res => {
              fetchCGData()
            })
            .catch(err => {
              console.log(err)
            })
        }

      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const checkCompanies = () => {
    let showAllCaughtUp = true
    if (data.companies) {
      data.companies.forEach(company => {
        if ((company.hasApps && company.hasExpired) || props.viewAllSites) {
          showAllCaughtUp = false
        } 
      })
    }
    return showAllCaughtUp
  }

  return (
    <Box>
      <Stack spacing={2}>
        {(cover && cover[COVER_TYPE] === false) &&
          <Card variant="outlined">
            <CardContent>
              <Typography color='error'>
                YOU ARE CURRENTLY BEING COVERED BY - {cover[NAME]}
              </Typography>
              <Button sx={{ pb: 0 }} onClick={() => setShowRemoveCoverDialog(cover[CONTRACT_GROUP])}>
                End cover
              </Button>
            </CardContent>
            <Dialog open={showRemoveCoverDialog === cover[CONTRACT_GROUP]}>
              <RemoveCoverDialog
                user={data.user}
                cover={cover}
                onSubmit={onRemoveCoverDialogSubmit}
                onCancelled={() => setShowRemoveCoverDialog(false)}
              />
            </Dialog>
          </Card>
        }
        {(coverData && coverData.user) &&
          <Card variant='outlined'>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack>
                  <Typography color='error'>COVERING</Typography>
                  <Typography sx={{ fontSize: 18 }}>
                    {`${coverData.user[FIRST_NAME]} ${coverData.user[NAME]}`}
                  </Typography>
                </Stack>

                <IconButton edge='end' aria-label='delete' onClick={() => setShowRemoveCoverDialog(coverData.user[CONTRACT_GROUP])}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Dialog open={showRemoveCoverDialog === coverData.user[CONTRACT_GROUP]}>
                <RemoveCoverDialog
                  user={data.user}
                  cover={cover}
                  onSubmit={onRemoveCoverDialogSubmit}
                  onCancelled={() => setShowRemoveCoverDialog(false)}
                />
              </Dialog>
              {coverData.companies &&
                <Accordion expanded={expanded === coverData.key} elevation={0} disableGutters variant="outlined" onChange={handleChange(coverData.key)}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`${coverData.user[NAME]}=content`}
                    id={`${coverData.user[NAME]}=header`}
                  >
                    <Typography sx={{ fontSize: 16, width: '100%' }}>
                      Sites
                    </Typography>
                    {!(expanded === coverData.key) &&
                      <AppChipGroup apps={coverData.data.find(d => d.key === 'AP').data} size='small' scrapAndMissing={false} />
                    }
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    {coverData.companies.map(company =>
                      <CardContent key={company[COMPANY_ID]} sx={{ pb: 0 }}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                          {company[COMPANY_NAME]}
                        </Typography>
                        <CompanySitesList
                          company={company}
                          scrapAndMissing={false}
                        />
                      </CardContent>
                    )}
                  </AccordionDetails>
                </Accordion>
              }
            </CardContent>
          </Card>
        }
        <Card variant='outlined'>
          {data.companies &&
            data.companies.map(company =>
              ((company.hasApps && company.hasExpired) || props.viewAllSites) &&
              <CardContent key={company[COMPANY_ID]} sx={{ pb: 0 }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  {company[COMPANY_NAME]}
                </Typography>
                <CompanySitesList company={company} role={testUser[ROLE]} viewAll={props.viewAllSites} />
              </CardContent>
            )
            
          }
          
        </Card>
        { checkCompanies() && 
                <Grid container>
                <Grid item xs={4} display='flex' justifyContent='center' flexDirection='column'>
                <Divider/>
                </Grid>
                <Grid item xs={4} display='flex' justifyContent='center'>
                {/* <Box sx={{ alignSelf: 'center', justifySelf: 'center' }}> */}
                <CheckCircleOutlineIcon sx={{ color: 'green', fontSize: 100}} />
                {/* </Box> */}
                
                </Grid>
                <Grid item xs={4} display='flex' justifyContent='center' flexDirection='column'>
                <Divider/>
                </Grid>
                <Grid item xs={12} display='flex' justifyContent='center' mt={2} >
                <Typography variant='h5'>
                  You're all caught up
                </Typography>
                </Grid>  
                </Grid>
          }
      </Stack>
    </Box>
  )
}

CMHomeContainer.propTypes = {
  viewAllSites: PropTypes.bool
}