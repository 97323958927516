import { Box, Container, Stack } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddEditCompanyForm from "../../components/forms/AddEditCompanyForm";
import { DeleteButton, StandardButton } from "../../components/buttons/customButtons";
import { addNewCompany, deleteCompany, fetchCompanySites } from "../../services/dynamoDBservice";
import { COMPANY_ID, ID, PK, SK } from "../../DynamoDbConstants";
import EditCompanyDialogGroup from "../../components/dialogGroups/EditCompanyDialogGroup";

// add/edit company page
export default function AddEditCompany () {
  const navigate = useNavigate()
  const location = useLocation()

  // sets formData to equal passed in state from parent navigate()
  const [formData, setFormData] = useState(location.state)
  // boolean which informs child form component whether the form entries need validating
  const [checkForm, setCheckForm] = useState(false)
  // booleans for showing respective dialogs
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  // called from this component when the submit button is clicked
  const onSubmitClicked = (e) => {
    console.log(formData)
    // sets check form boolean to true so child form component will validate entries
    setCheckForm(true)
  }

  // called from child form component if validation check passes
  const submitForm = () => {
    setCheckForm(false)
    // calls dynamo function to add a new company item
    addNewCompany(formData)
      .then(res => {
        console.log(res)
        // navigates back one page
        navigate(-1)
      })
      .catch(err => {
        console.log(err)
      })
  }

  // called when delete button clicked
  const deleteButtonClicked = () => {
    console.log(formData)
    // fetches all sites for the current company (only returning PK, SK and CUID)
    fetchCompanySites(formData[PK], formData.company[COMPANY_ID])
      .then(res => {
        console.log(res.Items)
        // maps returned items to include only those that are sites (removes the company)
        const sites = res.Items.map(item => item[SK].startsWith('S#'))
        if (sites.includes(true)) {
          // if the company has sites, shows unable to delete dialog
          setShowErrorDialog(true)
        } else {
          // if the company has no sites, shows delete dialog
          setShowDeleteDialog(true)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  // called when delete confirmation is clicked in the delete dialog
  const onDeleteConfirmClicked = () => {
    // first hides the delete dialog
    setShowDeleteDialog(false)
    // then calls dynamo function to delete company item
    deleteCompany(formData[PK], formData.company[COMPANY_ID])
      .then(res => {
        console.log('company deleted succesfully')
        // navigates back one page
        navigate(-1)
      })
      .catch(err => {
        console.log(err)
      })
  }

  // functions for closing respective dialogs
  const onDeleteCancelClicked = () => { setShowDeleteDialog(false) }
  const onErrorCancelClicked = () => { setShowErrorDialog(false) }

  return (
    // container keeps all components centered. maxWidth set to medium
    <Container style={{ paddingTop: '20px' }} maxWidth="md">
    {/* if the formData.new === false, then this is an edit form, and therefore shows the delete button */}
    { !formData.new && 
      <Box display='flex' justifyContent='flex-end' sx={{ pb: 1 }}>
        <DeleteButton 
          onClick={deleteButtonClicked}
        />
      </Box>
    }
      <Stack spacing={1}>
      {/* child form component. Passes in formData and methods for changing and submitting formData */}
        <AddEditCompanyForm 
          formData={formData}
          setFormData={setFormData}
          checkForm={checkForm}
          setCheckForm={setCheckForm}
          submitForm={submitForm}
        />
        {/* generic full width button which allows submission */}
        <StandardButton 
          fullWidth={true}
          label="Submit"
          onClick={onSubmitClicked}
        />
        {/* parent component for two dialogs (error/delete) */}
        <EditCompanyDialogGroup 
        showDeleteDialog={showDeleteDialog}
        showErrorDialog={showErrorDialog}
        onDeleteConfirmClicked={onDeleteConfirmClicked}
        onDeleteCancelClicked={onDeleteCancelClicked}
        onErrorCancelClicked={onErrorCancelClicked}
      />
      </Stack>
    </Container>
  )
}