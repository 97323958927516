import { Box, Button, ButtonGroup, Chip, CircularProgress, Dialog, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import PropTypes from 'prop-types'
import { deleteApp, fetchAppHistory, updateAppAuditDate, updateAppAuditDateDamaged, updateAppFound, updateAppMissing, updateAppMoveSite, updateAppPassDate, updateAppScrapped } from "../../services/dynamoDBservice";
import DamagedAppDialog from "../dialogs/DamagedAppDialog";
import { ADMINSTRATOR, APP_ID, CONTRACT_MANAGER, DEVELOPER, PAT_TESTER, PK, REGIONAL_MANAGER, SITE_ID, FIRST_NAME, NAME, MISSING_DATE, SCRAP_DATE, AUDIT_DATE, ROLE, PAT_DATE, SITE_NAME, COMPANY_NAME } from "../../DynamoDbConstants";
import ConfirmDeleteDialog from "../dialogs/ConfirmDeleteDialog";
import FoundAppDialog from "../dialogs/FoundAppDialog";
import MoveAppDialog from "../dialogs/MoveAppDialog";

// group of buttons which show various actions that can be done on an appliance
export default function ActionButtonGroup (props) {
  const testUser = useOutletContext()
  const [loadingWorking, setLoadingWorking] = useState(false)
  const [loadingPass, setLoadingPass] = useState(false)

  const [openDamagedDialog, setOpenDamagedDialog] = useState(false)
  const [openMoveDialog, setOpenMoveDialg] = useState(false)
  const [openFoundDialog, setOpenFoundDialog] = useState(false)
  const [openConfirmScrapDialog, setOpenConfirmScrapDialog] = useState(false)
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false)
  const navigate = useNavigate()

  const [selectedMissingApps, setSelectedMissingApps] = useState([])
  const [selectedScrappedApps, setSelectedScrappedApps] = useState([])

  // called when the selected apps change (this will be called once if on appliance view, as it's just showing the single appliance)
  useEffect(() => {
    // checks to see if any appliances are marked as missing
    if (props.selectedApps.filter(app => app[MISSING_DATE]).length !== 0) {
      setSelectedMissingApps(props.selectedApps.filter(app => app[MISSING_DATE]))
    } else {
      setSelectedMissingApps([])
    }
    // checks to see if any appliances are marked as scrapped
    if (props.selectedApps.filter(app => app[SCRAP_DATE]).length !== 0) {
      setSelectedScrappedApps(props.selectedApps.filter(app => app[SCRAP_DATE]))
    } else {
      setSelectedScrappedApps([])
    }
  }, [props.selectedApps])

  // called when a user clicks the 'working' button (this will only be displayed to those that can audit appliances)
  const onClickWorking = ()=>{
    console.log("on click working clicked")
    setLoadingWorking(true)
    console.log(props.selectedApps)
    const promises = []
    // loops through all the selected appliances, calling updateAppAuditDate for all of them and adding that to a promise array
    props.selectedApps.forEach(app => {
      const auditData = {
        app: app,
        user: `${testUser[FIRST_NAME]} ${testUser[NAME]}`,
        category: props.isStockSite ? 'In Stock' : 'Visual Audit'
      }
      promises.push(updateAppAuditDate(testUser[PK], auditData))
    })
  
    // will run through all promises in array before executing .then (even if any fail)
    Promise.allSettled(promises)
      .then(res => {
        console.log(`${props.selectedApps.length} appliances visually audited`)
        console.log(res)
        setLoadingWorking(false)
        props.partialRefresh()
        // sets the new audit date locally so displayed dates are updated, without having to re-fetch from dynamo
        props.selectedApps.forEach(app => {
          app[AUDIT_DATE] = (new Date().getTime())
        })
      })
      .catch(err => {
        setLoadingWorking(false)
        console.log(err)
      })
  }
  
  // called when a user clicks on the pass button (in place of 'working' if user can't audit appliances)
  const onClickPass = () => {
    console.log("on pass clicked")
    setLoadingPass(true)
    const promises = []
    // loops through all selected appliances, calling updateAppPassDate for each and adding to a promise array
    props.selectedApps.forEach(app => {
      const auditData = {
        app: app,
        user: `${testUser[FIRST_NAME]} ${testUser[NAME]}`,
        category: 'PAT pass'
      }
      promises.push(updateAppPassDate(testUser[PK], auditData))
    })
  
    // will run through all promises in array before executing .then (even if any fail)
    Promise.allSettled(promises)
      .then(res => {
        console.log(`${props.selectedApps.length} appliances passed PAT`)
        console.log(res)
        setLoadingPass(false)
        // calls the passed in partialRefresh function, which is determined by the parent component
        props.partialRefresh()
        // sets the new pass date locally so displayed dates are updated, without having to re-fetch from dynamo
        props.selectedApps.forEach(app => {
          app[PAT_DATE] = (new Date().getTime())
        })
      })
      .catch(err => {
        setLoadingPass(false)
        console.log(err)
      })
  }
  
  // called when 'damaged' is clicked
  const onClickDamaged = (event)=>{
    console.log("on click damaged clicked")
    // shows the damaged dialog
    setOpenDamagedDialog(true)
  }
  const onDamagedDialogCancelled = () => {
    setOpenDamagedDialog(false)
  }

  // called when the damaged dialog is submitted
  const onDamagedDialogSubmitted = (note, site) => {
    setOpenDamagedDialog(false)
    const promises = []
    // loops through all the selected appliances and calls updateAppAuditDamaged, adding each to the promise array
    props.selectedApps.forEach(app => {
      const auditData = {
        app: app,
        user: `${testUser[FIRST_NAME]} ${testUser[NAME]}`,
        category: 'Damaged',
        movedSite: site
      }
      if (note) { auditData.note = note } else { auditData.note = ''}
      console.log(auditData)
      promises.push(updateAppAuditDateDamaged(testUser[PK], auditData))
    })
  
    // will run through all promises in array before executing .then (even if any fail)
    Promise.allSettled(promises)
      .then(res => {
        console.log(`${props.selectedApps.length} appliances marked as damaged`)
        console.log(res)
        props.refresh()
      })
      .catch(err => {
        console.log(err)
      })
  }
  
  // called when 'move' clicked
  const onClickMove = (event)=> {
    console.log("on click move clicked")
    // opens the move dialog
    setOpenMoveDialg(true)
  }
  
  const onMoveDialogCancelled = () => {
    setOpenMoveDialg(false)
  }
  
  // called when the move dialog is submitted
  const onMoveDialogSubmitted = (note, site) => {
    setOpenMoveDialg(false)
    const promises = []
    // checks if user trying to move them to the site they're already on, in which case nothing happens (appliances and unselected)
    if (site && (props.selectedApps[0][APP_ID] === site[SITE_ID])) {
      console.log("Appliances not moved - same site")
      props.partialRefresh()
      return
    }
  
    // loops through all selected appliances, calling updateAppMoveSite for each and adding to promise array
    props.selectedApps.forEach(app => {
      const auditData = {
        app: app,
        user: `${testUser[FIRST_NAME]} ${testUser[NAME]}`,
        category: 'Moved',
        movedSite: site
      }
      if (note) { auditData.note = note } else { auditData.note = ''}
      console.log(auditData)
      promises.push(updateAppMoveSite(testUser[PK], auditData))
    })
  
    // will run through all promises in array before executing .then (even if any fail)
    Promise.allSettled(promises)
      .then(res => {
        console.log(`${props.selectedApps.length} appliances moved`)
        console.log(res)
        props.refresh()
      })
      .catch(err => {
        console.log(err)
      })
  }
  
  // called when 'missing' clicked
  const onClickMissing = (event)=>{
    const promises = []
    // loops through all selected appliances and calls updateAppMissing for each, adding to the promise array
    props.selectedApps.forEach(app => {
      const auditData = {
        app: app,
        user: `${testUser[FIRST_NAME]} ${testUser[NAME]}`,
        category: 'Missing'
      }
      console.log(auditData)
      promises.push(updateAppMissing(testUser[PK], auditData))
    })
  
    // will run through all promises in array before executing .then (even if any fail)
    Promise.allSettled(promises)
      .then(res => {
        console.log(`${props.selectedApps.length} appliances marked as missing`)
        console.log(res)
        props.refresh()
      })
      .catch(err => {
        console.log(err)
      })
  }

  // called when 'found' clicked, which is only visible if appliance is marked as missing
  const onClickFound = () => {
    setOpenFoundDialog(true)
  }

  const onFoundDialogCancelled = () => {
    setOpenFoundDialog(false)
  }

  // called when found dialog is submitted
  const onFoundDialogSubmitted = (site) => {
    const promises = []
    setOpenFoundDialog(false)
    // loops through all selected appliances and calls updateAppFound, adding each call to promise array
    props.selectedApps.forEach(app => {
      const auditData = {
        app: app,
        user: `${testUser[FIRST_NAME]} ${testUser[NAME]}`,
        category: 'Found',
        movedSite: site
      }
      promises.push(updateAppFound(testUser[PK], auditData))
    })
  
    // will run through all promises in array before executing .then (even if any fail)
    Promise.allSettled(promises)
      .then(res => {
        console.log(`${props.selectedApps.length} appliances found`)
        console.log(res)
        props.refresh()
      })
      .catch(err => {
        console.log(err)
      })
  }

  // called when 'scrap' clicked
  const onClickScrap = () => {
    setOpenConfirmScrapDialog(true)
  }

  // called when confirm scrap submitted
  const onConfirmScrapSubmitClicked = () => {
    setOpenConfirmScrapDialog(false)
    const promises = []
  
    // loops through all selected appliances and calls updateAppScrapped for each, adding each call to promise array
    props.selectedApps.forEach(app => {
      const auditData = {
        app: app,
        user: `${testUser[FIRST_NAME]} ${testUser[NAME]}`,
        category: 'Scrapped'
      }
      console.log(auditData)
      promises.push(updateAppScrapped(testUser[PK], auditData))
    })
  
    // will run through all promises in array before executing .then (even if any fail)
    Promise.allSettled(promises)
      .then(res => {
        console.log(`${props.selectedApps.length} appliances marked as scrapped`)
        console.log(res)
        props.refresh()
      })
      .catch(err => {
        console.log(err)
      })

  }

  const onConfirmScrapCancelled = () => [
    setOpenConfirmScrapDialog(false)
  ]

  // called when 'delete' is clicked (delete will only show if item is scrapped or missing, and user has authority to delete appliances)
  const onClickDelete = () => {
    setOpenConfirmDeleteDialog(true)
  }

  // called when delete confirm clicked
  const onConfirmDeleteSubmit = () => {
    setOpenConfirmDeleteDialog(false)
    
    // loops through all selected apps
    props.selectedApps.forEach(app => {
      // for each app, fetches the log history
      fetchAppHistory(app[APP_ID])
        .then(res => {
          // calls deleteApp, which will delete the log history, and the appliance
          deleteApp(testUser[PK], app[APP_ID], res.Items)
            .then(res => {
              console.log(res)
              console.log(`${app[APP_ID]} deleted successfully`)
              // if called from the siteView, just refreshes the list of apps
              if (props.isSiteView) {
                props.refresh()
              } else {
                // if called from the appliance view, navigates back to previous page
                props.goBack(-1)
              }
             
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(err => {
          console.log(err)
        })
    })
  }

  const onConfirmDeleteCancelled = () => {
    setOpenConfirmDeleteDialog(false)
  }

  // called when 'view' clicked
  const onClickView = () => {
    const value = props.selectedApps[0][APP_ID]
    console.log(value)
    console.log('navigating', value)
    // navigates to the appliance page
    navigate(`view-appliance/${value}`, { state: { [NAME]: value } })
  }

  // ScrapButton component
  const ScrapButton = () => {
      // checks to see if the user has authority to delete appliances
      if (testUser.RL === REGIONAL_MANAGER || testUser.RL === ADMINSTRATOR || testUser.RL === DEVELOPER) {
        // if they do, checks to see if all selectedApps are scrappedApps or missingApps
        if ((props.selectedApps.length > 0 && props.selectedApps.length === selectedScrappedApps.length)
        || (props.selectedApps.length > 0 && props.selectedApps.length === selectedMissingApps.length)) {
          // if they are, shows the delete button
          return <Button disabled={shouldDisable()} color="error" value="delete-appliance" onClick={onClickDelete}>Delete App</Button>
        }
        // by default, show the scrap button
        return (
          <Button disabled={shouldDisable()} color="error" value="scrap-appliance" onClick={onClickScrap}>Scrap</Button>
        )
      } else {
        // checks selected apps aren't already scrapped (Contract managers by default can't see scrapped appliances)
        if (!(props.selectedApps.length > 0 && props.selectedApps.length === selectedScrappedApps.length)) {
          // shows the scrap button
          return <Button disabled={shouldDisable()} color="error" value="scrap-appliance" onClick={onClickScrap}>Scrap</Button>
        }
      }
  }

  // function for checking if the user should see 'pass' button
  const shouldShowPass = () => {
    if (testUser[ROLE] === PAT_TESTER || testUser[ROLE] === DEVELOPER) {
      // if they can, checks to see if any apps are missing or scrapped
      if (selectedMissingApps.length > 0 || selectedScrappedApps.length > 0) {
        // if yes, don't show the pass button
        return false
      }
      return true
    } else {
      // defaults to not showing pass button
      return false
    }
  }

  // function for checking if the user should see 'working' button
  const shouldShowWorking = () => {
    if (testUser[ROLE] === REGIONAL_MANAGER || testUser[ROLE] === CONTRACT_MANAGER || testUser[ROLE] === ADMINSTRATOR || testUser[ROLE] === DEVELOPER) {
      // if they can, checks to see if any apps are missing or scrapped
      if (selectedMissingApps.length > 0 || selectedScrappedApps.length > 0) {
        // if yes, don't show the working button
        return false
      }
      return true
    } else {
      // defaults to not shwing working button
      return false
    }
  }

  // function for checking if the user should see 'damaged' button (not limited by role)
  const shouldShowDamaged = () => {
    // doesn't show button if any selected apps are missing or scrapped
    if (selectedMissingApps.length > 0 || selectedScrappedApps.length > 0) {
      return false
    }
    return true
  }

  // function for checking if the user should see 'missing' button (not limited by role)
  const shouldShowMissing = () => {
    // doesn't show button if any selected apps are missing or scrapped
    if (selectedMissingApps.length > 0 || selectedScrappedApps.length > 0) {
      return false
    }
    return true
  }

  // function for checking if the button should be disabled
  const shouldDisable = () => {
    // if no apps are selected, disable the button
    if (props.selectedApps.length < 1) {
      return true
    }
    // if both missing and scrapped apps are selected, disable button (can only work on one type at a time)
    if (selectedMissingApps.length > 0 && selectedScrappedApps.length > 0) {
      return true
    }
    // if missing apps are selected as well as normal apps, or the same with scrapped, disable the button
    if ((props.selectedApps.length > 0 && selectedMissingApps.length > 0 && props.selectedApps.length !== selectedMissingApps.length) 
    || (props.selectedApps.length > 0 && selectedScrappedApps.length > 0 && props.selectedApps.length !== selectedScrappedApps.length)) {
      return true
    }
    return false
  }

  // function for checking if a warning should be displayed to the user (informing them they have multiple types of app selected)
  const shouldShowWarning = () => {
    // if missing apps are selected as well as normal apps, or the same with scrapped, show the warning
    if ((props.selectedApps.length > 0 && selectedMissingApps.length > 0 && props.selectedApps.length !== selectedMissingApps.length) 
    || (props.selectedApps.length > 0 && selectedScrappedApps.length > 0 && props.selectedApps.length !== selectedScrappedApps.length)) {
      return true
    }
    return false
  }

  const getWorkingText = () => {
    if (props.isStockSite) {
      return 'In Stock'
    } else {
      return 'Working'
    }
  }

  return (
    <Grid sx={{ position: 'sticky', top: '64px', zIndex: 1100}}>
          <Box sx={{ backgroundColor: 'white'}}>
            <ButtonGroup disabled={shouldDisable()} variant="outlined" fullWidth sx={{ pb: 1 }}>
            {shouldShowPass() &&
              <Button onClick={onClickPass} value="pass">{loadingPass ? <CircularProgress size={24}/> : 'Pass'}</Button>
            }
            {shouldShowWorking() &&
              <Button onClick={onClickWorking} value={props.isStockSite ? 'in stock' : 'working'}>{loadingWorking ? <CircularProgress size={24}/> : getWorkingText()}</Button>
            }
            {shouldShowDamaged() &&
              <Button onClick={onClickDamaged} value="damaged">Damaged</Button>
            }
            {props.selectedApps.length > 0 && props.selectedApps.length === selectedMissingApps.length &&
              <Button onClick={onClickFound} value="found">Found</Button>
            }
            {shouldShowMissing() &&
              <Button onClick={onClickMissing} value="missing">Missing</Button>
            }
              
            { props.selectedApps.length < 2 && props.isSiteView && <Button onClick={onClickView} value="view">View</Button> }
            </ButtonGroup>
            <ButtonGroup variant="outlined" fullWidth sx={{ mt: 1, mb: 1 }}>
            {!(props.selectedApps.length === 1 && props.selectedApps[0].MD) && 
              <Button disabled={shouldDisable()}  value="move-appliance" onClick={onClickMove}>Move</Button>
            }
            {/* <Button  color="error" disabled={props.selectedApps.length < 1} value="scrap-appliance" onClick={onClickScrap}>Scrap</Button> */}
            <ScrapButton />
            </ButtonGroup>
            
            { shouldShowWarning() && 
              <Typography>
                WARNING: some appliances selected are marked missing or scrapped
              </Typography>
            }
            
          </Box>
        {/* various dialogs that are shown when certain buttons are clicked */}
        <Dialog open={openDamagedDialog} scroll="paper">
          <DamagedAppDialog 
            onCancelled={onDamagedDialogCancelled}
            onSubmit={onDamagedDialogSubmitted}
          />
        </Dialog>
        <Dialog open={openMoveDialog} scroll="paper">
          <MoveAppDialog 
            onCancelled={onMoveDialogCancelled}
            onSubmit={onMoveDialogSubmitted}
          />
        </Dialog>
        <Dialog open={openFoundDialog} scroll="paper">
          <FoundAppDialog
            siteId={props.selectedApps[0]?.APSID} 
            onCancelled={onFoundDialogCancelled}
            onSubmit={onFoundDialogSubmitted}
          />
        </Dialog>
        <Dialog open={openConfirmScrapDialog} scroll="paper">
          <ConfirmDeleteDialog 
            title='Scrap Appliance'
            text="Are you sure? This will mark selected Appliances as 'Scrapped'."
            onCancelled={onConfirmScrapCancelled}
            onSubmit={onConfirmScrapSubmitClicked}
          />
        </Dialog>
        <Dialog open={openConfirmDeleteDialog} scroll="paper">
          <ConfirmDeleteDialog 
            title='Delete Appliance'
            text="Are you sure? This will permanently delete selected Appliances."
            onCancelled={onConfirmDeleteCancelled}
            onSubmit={onConfirmDeleteSubmit}
          />
        </Dialog>
      </Grid>
  )
}

ActionButtonGroup.propTypes = {
  selectedApps: PropTypes.array,
  refresh: PropTypes.func,
  partialRefresh: PropTypes.func,
  goBack: PropTypes.func,
  isSiteView: PropTypes.bool,
  isStockSite: PropTypes.bool
}