import { useEffect, useState } from "react"
import { fetchAllCompanies, fetchAllContracts, fetchAllRegions, fetchUser, fetchUserWithPK } from "../../services/dynamoDBservice"
import PropTypes from 'prop-types'
import { COMPANY_ID, CONTRACT_GROUP, COVER, COVER_TYPE, FIRST_NAME, NAME, PK, REGIONAL_GROUP, SITE_ID, USERNAME } from "../../DynamoDbConstants"
import { v4 as uuidv4 } from 'uuid';
import { AutoCompleteWithAddNew } from "../inputs/autoComplete"
import { Autocomplete, createFilterOptions, TextField } from "@mui/material"
import { compareFirstNames } from "../../services/dataSortingService";

// deals with displaying and fetching a list of contract group items
/*
 props:
  user: current logged in user
  formData: parent form object
  setFormData: method to update formData
  error: boolean for displaying error
  setError: method to remove error
  disabled: optional boolean to disable autocomplete
  helperText: optional helper text
*/
export default function ContractsAutoComplete (props) {

  // state to hold the fetched contract group items
  const [contracts, setContracts] = useState([])
  const [contractsLoading, setContractsLoading] = useState(false)

  // checks to see if part of add or edit form
  useEffect(() => {
    if (!props.formData.new) {
      // if part of edit, calls onContractClicked method with the current contract group ID
      // this will then display the current contract in the edit form
      console.log('not new')
      if (props.formData.site) { 
          onContractClicked(props.formData.site[CONTRACT_GROUP])    
        return 
      }
      if (props.formData.user) { onContractClicked(props.formData.user[CONTRACT_GROUP]); return }
    }
    if (props.defaultValue) {
      setContracts([{ [NAME]: 'NO', [CONTRACT_GROUP]: 'NO' }])
    }
  }, [props.formData.new, props.defaultValue])

  const onContractClicked = (contractId, override) => {
    // checks to see if contracts have already been fetched
    if ((props.defaultValue && contracts.length < 2) || (!props.defaultValue && contracts.length < 1)) {
      setContractsLoading(true)
      // dynamoDB call to fetch contracts
      fetchAllContracts(props.user[PK])
        .then(res => {
          // tells the autocomplete loading has finished
          setContractsLoading(false)
          // if dontInclude is set then ignore passed in value (don't show in list)
          var filtered = res.Items
          if (props.dontInclude) {
            console.log(props.dontInclude)
            if (props.dontInclude[COVER] && props.dontInclude[COVER][COVER_TYPE] === false) {
              filtered = filtered.filter(
                i => i[CONTRACT_GROUP] !== props.dontInclude[COVER][CONTRACT_GROUP])
            }
            filtered = filtered.filter(i => i[CONTRACT_GROUP] !== props.dontInclude[CONTRACT_GROUP])
          }

          // sets the contracts state to the returned items
            setContracts((contracts) => [...contracts, ...filtered.sort(compareFirstNames)])
          
          // checks to see if on editSiteForm
          if (contractId) {
            // using passed in contract group ID, finds the correct contract group
            const cg = res.Items.filter(item => item[CONTRACT_GROUP] === contractId)[0]
            console.log(cg)
            // checks matching contract group exists, settings formData.contractGroup to its value if it does
            if (cg) { props.setFormData((formData) => ({...formData, contractGroupHolder: cg}))}
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  // called from autocomplete when its value changes
  const onContractGroupChanged = (value)=> {
    console.log(value)
    // resets any error that might be showing on autocomplete
    if (props.setError) {
      props.setError(false)
    }
    // updates formData.contractGroup to equal new value
    props.setFormData((formData) => ({...formData, contractGroupHolder: value }))
  }

  return (
    <Autocomplete
          id="contracts-autocomplete"
          // the values to display in the autocomplete
          options={props.limitRegion ? contracts.filter(c => c[REGIONAL_GROUP] === props.limitRegion || c[CONTRACT_GROUP] === 'NO') : contracts}
          // value to display
          value={props.formData.contractGroupHolder}
          // called when new value selected
          onChange={(e, v) => onContractGroupChanged(v)}
          // called when autocomplete initially opened
          onOpen={onContractClicked}
          // displays loading message
          loading={contractsLoading}
          // renders the display of the autocomplete
          disabled={props.disabled} 
          disableClearable
          renderInput={(params) => {
            const inputProps = params.inputProps;
            inputProps.autoComplete = "none";
            return (
              <TextField 
                {...params} 
                label={props.label ? props.label : 'Contract Manager'}
                disabled={props.disabled} 
                required={true} 
                error={props.error} 
                inputProps={inputProps} 
                helperText={props.helperText} 
              /> 
            )}}
          getOptionLabel={option => option[FIRST_NAME] ? `${option[FIRST_NAME]} ${option[NAME]}` : `${option[NAME]}`}
          isOptionEqualToValue={(option, value) => option[CONTRACT_GROUP] === value[CONTRACT_GROUP]}
        />
  )
}

ContractsAutoComplete.propTypes = {
  user: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.bool,
  setError: PropTypes.func,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  defaultValue: PropTypes.bool,
  dontInclude: PropTypes.object,
  limitRegion: PropTypes.string,
  forUsers: PropTypes.bool
}