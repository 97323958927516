
import { Box, Container, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import ApplianceAutoComplete from "../components/autoCompletes/ApplianceAutoComplete";
import { SimpleSitesAutoComplete } from "../components/autoCompletes/SitesAutoComplete";
import { StandardButton } from "../components/buttons/customButtons";
import CMHomeContainer from "../components/containers/CMHomeContainer";
import HomeContainer from "../components/containers/HomeContainer";
import RMHomeContainer from "../components/containers/RMHomeContainer";
import { ADMINSTRATOR, COMPANY_NAME, CONTRACT_GROUP, CONTRACT_MANAGER, DEFAULT_STOCK_SITE, DEVELOPER, NAME, PAT_TESTER, PK, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE } from "../DynamoDbConstants";
import { groupCompanies } from "../services/dataSortingService";
import { fetchAppsCG, fetchAppsRG, fetchExpiredAppsCG, fetchExpiredAppsRG, fetchSite } from "../services/dynamoDBservice";

// the default page all all users will see
export default function AppHome () {
  // user object passed down from App.js (dynamo user item)
  const testUser = useOutletContext()
  // array which contains expired apps, limited by contract/regional group (admin/PAT doesn't see this list)
  const [expiredApps, setExpiredApps] = useState([])
  const navigate = useNavigate()

  // boolean which sets whether the user has selected view all sites (this will show them all their apps/sites, regardless of expiry)
  const [viewAllSites, setViewAllSites] = useState(false)

  const onStockSiteClicked = () => {
    if (testUser[DEFAULT_STOCK_SITE]) {
      console.log(testUser)
      fetchSite(testUser[PK], testUser[DEFAULT_STOCK_SITE])
        .then(res => {
          if (res.Items.length > 0) {
            const site = res.Items[0]
            const siteName = site[NAME] ? `${site[COMPANY_NAME]} ${site[NAME]}` : site[COMPANY_NAME]
  
            console.log('navigating', siteName.toLowerCase())
            // splits the full site name into individual words, ready for displaying in the domain name
            const elements = siteName.toLowerCase().split(' ').filter(element => element)
            console.log(elements)
            if (elements.length > 1) {
              // navigates to the site view, displaying the full site name joined by dashes
              navigate(`site/${elements.join('-')}`, { state: site })
              return
            }
            // if the full site name was one word, no need to join it and can just navigate
            navigate(`site/${siteName.toLowerCase().trim()}`, { state: site })
          }
          
        })
        .catch(err => {
          console.log(err)
        })
    }
  }


  return (
    <Container style={{ paddingTop: '20px' }} maxWidth="md">
      <Stack spacing={1}>
        { /* only Contract Managers will see the 'view all sites' button */}
        <Stack direction='row' spacing={1}>
        {(testUser[ROLE] === CONTRACT_MANAGER || testUser[ROLE] === REGIONAL_MANAGER) &&
          <StandardButton 
              label='Check Stock'
              onClick={(e) => onStockSiteClicked()}
              fullWidth
            /> 
        }
        {testUser[ROLE] === CONTRACT_MANAGER &&
            <StandardButton 
              label={viewAllSites ? 'View expired sites' : 'View all sites'}
              onClick={(e) => setViewAllSites(!viewAllSites)}
              fullWidth
            />
          
        }
        </Stack>
        {testUser[ROLE] === REGIONAL_MANAGER &&
          <RMHomeContainer/>
        }
        {/* only Contract managers will see default companies/sites data */}
        {(testUser[ROLE] === CONTRACT_MANAGER) && 
          
          <CMHomeContainer viewAllSites={viewAllSites}/>
        }
      </Stack>
    </Container>
  )
}
