import { Box, Chip, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { MISSING_DATE, PAT_DATE, SCRAP_DATE } from '../../DynamoDbConstants'
import { checkApplianceExpired, checkApplianceWarning } from '../../services/dateServices'

export default function AppChipGroup (props) {
  const [expired, setExpired] = useState(0)
  const [warnings, setWarnings] = useState(0)
  const [working, setWorking] = useState(0)
  const [scrapOrMissing, setScrapOrMissing] = useState(0)

  useEffect(() => {
    var ex = 0
    var warn = 0
    var ok = 0
    var sM = 0
    if (props.apps) {
      props.apps.forEach(app => {
        if (!(app[SCRAP_DATE] || app[MISSING_DATE]) && checkApplianceExpired(app)) {
          ex += 1
        } else if (!(app[SCRAP_DATE] || app[MISSING_DATE]) && checkApplianceWarning(app)) {
          warn += 1
        } else if (app[PAT_DATE] !== 0 && !app[SCRAP_DATE] && !app[MISSING_DATE]) {
          ok += 1
        } else if ((app[SCRAP_DATE] || app[MISSING_DATE]) && props.scrapAndMissing) {
          sM += 1
        }
      })
    }
    
    setExpired(ex)
    setWarnings(warn)
    setWorking(ok)
    setScrapOrMissing(sM)
  }, [props.apps])

  return (
    <Box>
      <Stack direction='row' spacing={1}>
        {expired > 0 &&
          <Chip label={expired} color='error' sx={{ fontSize: 16 }} size={props.size ? props.size : ''}/>
        }
        {warnings > 0 &&
          <Chip label={warnings} sx={{ background: 'orange', color: 'white', fontSize: 16 }} size={props.size ? props.size : ''}/>
        }
        {working > 0 &&
          <Chip label={working} sx={{ background: 'green', color: 'white', fontSize: 16 }} size={props.size ? props.size : ''}/>
        }
        {scrapOrMissing > 0 &&
          <Chip label={scrapOrMissing} sx={{ background: 'grey', color: 'white', fontSize: 16 }} size={props.size ? props.size : ''}/>
        }
      </Stack>
    </Box>
  )
}

AppChipGroup.propTypes = {
  apps: PropTypes.array,
  size: PropTypes.string,
  scapAndMissing: PropTypes.bool
}