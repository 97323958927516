import PropTypes from 'prop-types'
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { ADMINSTRATOR, COMPANY_NAME, CONTRACT_GROUP, CONTRACT_MANAGER, DEFAULT_STOCK_SITE, EMAIL, FIRST_NAME, NAME, PAT_TESTER, PK, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE, SITE_ID } from '../../DynamoDbConstants';
import { isValidEmail } from '../../services/checkDataService';
import RegionsAutoComplete from '../autoCompletes/RegionsAutoComplete';
import { useOutletContext } from 'react-router-dom';
import ContractsAutoComplete from '../autoCompletes/ContractsAutoComplete';
import { FormSitesAutoComplete } from '../autoCompletes/SitesAutoComplete';
import RoleSelect from '../selects/RoleSelect';
import { fetchSite } from '../../services/dynamoDBservice';
import { FORM_EDIT_USER } from '../../FormConstants';


export default function AddEditUserForm (props) {
  const testUser = useOutletContext()

  const [FNError, setFNError] = useState(false)
  const [LNError, setLNError] = useState(false)
  const [RLError, setRLError] = useState(false)
  const [RGError, setRGError] = useState(false)
  const [SSError, setSSError] = useState(false)

  const [showError, setShowError] = useState(false)

  //const [stockSite, setStockSite] = useState({ [COMPANY_NAME]: '', [SITE_ID]: '-111' })

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  }

  useEffect(() => {
    if (props.formData.regionalGroupHolder[DEFAULT_STOCK_SITE]) {
      fetchSite(testUser[PK], props.formData.regionalGroupHolder[DEFAULT_STOCK_SITE])
        .then(res => {
          if (res.Items.length > 0) {
            props.setFormData((formData) => ({...formData, stockSite: res.Items[0]}))
          }
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      props.setFormData((formData) => ({...formData, stockSite: { [COMPANY_NAME]: '', [SITE_ID]: '-111' }}))
    }
    props.setFormData((formData) => ({...formData, contractGroupHolder: { [NAME]: 'NO', [CONTRACT_GROUP]: 'NO' } }))
  }, [props.formData.regionalGroupHolder, testUser])

  useEffect(() => {
    const checkForm = () => {
      console.log(props.formData)
      var formError = false
      if (!props.formData.user[FIRST_NAME]) {
        setFNError(true)
        formError = true
      }
      if (!props.formData.user[NAME]) {
        setLNError(true)
        formError = true
      }
      if (props.formData.new && (!props.formData.user[EMAIL] || !isValidEmail(props.formData.user[EMAIL]))) {
        props.setEError(true)
        if (props.formData.user[EMAIL] && !isValidEmail(props.formData.user[EMAIL])) {
          props.setEEText('Entered email is not a valid email address')
        }
        formError = true
      }
      if (props.formData.new && !props.formData.user[ROLE]) {
        setRLError(true)
        formError = true
      }
      if (props.formData.user[ROLE] === CONTRACT_MANAGER && (!props.formData.regionalGroupHolder || !props.formData.regionalGroupHolder[NAME])) {
        setRGError(true)
        formError = true
      }
      // if ((props.formData.user[ROLE] === CONTRACT_MANAGER 
      //   || props.formData.user[ROLE] === REGIONAL_MANAGER 
      //   || props.formData.user[ROLE] === PAT_TESTER) && (!props.formData.stockSite || !props.formData.stockSite[COMPANY_NAME])) {
      //     setSSError(true)
      //     formError = true
      //   }
      if (formError) {
        setShowError(true)
        props.setCheckForm(false)
        return
      }

      props.submitForm()
    }

    if (props.checkForm) {
      checkForm()
    }
  }, [props.checkForm, props.formData])

  const onFirstNameChange = (event) => { setFNError(false); props.setFormData((formData) => ({ ...formData, user: {...formData.user, [FIRST_NAME]: event.target.value }})) }
  const onLastNameChange = (event) => { setLNError(false); props.setFormData((formData) => ({ ...formData, user: {...formData.user, [NAME]: event.target.value }})) }
  const onEmailChange = (event) => { props.setEError(false); props.setEEText(''); props.setFormData((formData) => ({ ...formData, user: {...formData.user, [EMAIL]: event.target.value }})) }

  const shouldLimitRegionCheck = () => {
    // #1: if current user is RM
    if (props.user && props.user[ROLE] === REGIONAL_MANAGER) {
      // #1.1: limit regions available by the current users regional group
      return props.user[REGIONAL_GROUP]
    }
  }

  const getDefaultRegion = () => {
    // #1: if editing a user
    if (props.formData.type === FORM_EDIT_USER) {
      // #1.1: return that users regional group
      return props.formData.user[REGIONAL_GROUP]
    }
  }

  const shouldLimitContractCheck = () => {
    // #1: if current user is RM
    if (props.user && props.user[ROLE] === REGIONAL_MANAGER) {
      // #1.1: if on edit user form
      if (props.formData.type === FORM_EDIT_USER) {
        // #1.2: limit contracts by the edit user's set regional manager
        return props.formData.regionalGroupHolder && props.formData.regionalGroupHolder[REGIONAL_GROUP]
      }
    }
  }

  return (
    <Stack spacing={1}>
        <TextField
          required
          error={FNError}
          id="outlined-basic"
          label="First Name"
          variant="outlined"
          value={props.formData.user[FIRST_NAME]}
          onChange={onFirstNameChange}
          inputProps={{
            autoComplete: "none"
          }} />

        <TextField
          required
          error={LNError}
          id="outlined-basic"
          label="Last Name"
          variant="outlined"
          value={props.formData.user[NAME]}
          onChange={onLastNameChange}
          inputProps={{
            autoComplete: "none"
          }} />
        
          <TextField
          required
          error={props.EError}
          helperText={props.EEText}
          disabled={!props.formData.new}
          id="outlined-basic"
          label="Email"
          variant="outlined"
          value={props.formData.user[EMAIL]}
          onChange={onEmailChange}
          inputProps={{
            autoComplete: "none"
          }} />
        
        
        <RoleSelect 
          formData={props.formData}
          setFormData={props.setFormData}
          error={RLError}
          setError={setRLError}
          disabled={!props.formData.new}
        />
        {(props.formData.new && props.formData.user[ROLE] === REGIONAL_MANAGER) &&
          <Stack spacing={1}>
            <Typography>
              Are they taking over from another regional manager?
            </Typography>
            <RegionsAutoComplete //this is the autocomplete with the list of regional groups
              user={testUser}
              formData={props.formData}
              setFormData={props.setFormData}
              error={RGError}
              setError={setRGError}
              defaultValue={true}
            />
          </Stack>
        }
        {(!props.formData.new && props.formData.user[ROLE] === CONTRACT_MANAGER) &&
          <RegionsAutoComplete //this is the autocomplete with the list of regional groups
              user={testUser}
              formData={props.formData}
              setFormData={props.setFormData}
              error={RGError}
              setError={setRGError}
              disabled={true}
              defaultRM={testUser[ROLE] === REGIONAL_MANAGER}
            />
        }
        {(props.formData.new && props.formData.user[ROLE] === CONTRACT_MANAGER) && 
          <Stack spacing={1}>
            <RegionsAutoComplete //this is the autocomplete with the list of regional groups
              user={testUser}
              formData={props.formData}
              setFormData={props.setFormData}
              error={RGError}
              setError={setRGError}
              defaultRM={testUser[ROLE] === REGIONAL_MANAGER}
              disabled={testUser[ROLE] === REGIONAL_MANAGER}
            />
            <Typography>
              Are they taking over from another contract manager?
            </Typography>
            <ContractsAutoComplete 
              user={testUser}
              formData={props.formData}
              setFormData={props.setFormData}
              defaultValue={true}
              limitRegion={props.formData.regionalGroupHolder[REGIONAL_GROUP]}
              disabled={!props.formData.regionalGroupHolder || !props.formData.regionalGroupHolder[PK]}
            />
          </Stack>
        }
        {(props.formData.user[ROLE] === CONTRACT_MANAGER || props.formData.user[ROLE] === REGIONAL_MANAGER || props.formData.user[ROLE] === PAT_TESTER) && 
          <FormSitesAutoComplete 
            user={testUser}
            formData={props.formData}
            setFormData={props.setFormData}
            label="Stock Site"
            error={SSError}
            setError={setSSError}
            required={false}
            stockSites={true}
            disabled={props.formData.new && (!props.formData.regionalGroupHolder[PK] && props.formData.user[ROLE] !== PAT_TESTER)}
            defaultRM={
              (props.formData.user[ROLE] !== PAT_TESTER && (props.formData.regionalGroupHolder || !props.formData.new)) 
              ? (!props.formData.new ? props.formData.user[REGIONAL_GROUP] : props.formData.regionalGroupHolder[REGIONAL_GROUP]) : undefined}
          />
        }

        <Snackbar open={showError} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'right'}}>
          <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%'}}>
            Some required form fields aren't filled out correctly.
          </Alert>
        </Snackbar>
      </Stack>
      
  )
}

AddEditUserForm.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  checkForm: PropTypes.bool,
  setCheckForm: PropTypes.func,
  submitForm: PropTypes.func,
  EError: PropTypes.bool,
  setEError: PropTypes.func,
  EEText: PropTypes.string,
  setEEText: PropTypes.func
}