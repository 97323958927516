import { Autocomplete, createFilterOptions, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { APP_ID, NAME, PK } from '../../DynamoDbConstants'
import { fetchAllAppsKeysOnly } from '../../services/dynamoDBservice'

// autoComplete component for showing a list of all appliance id's for a cleaning company
export default function ApplianceAutoComplete (props) {
  const navigate = useNavigate()

  const [allApps, setAllApps] = useState([])
  const [appsLoading, setAppsLoading] = useState(false)

  const onAppIdClicked = () => {
    // checks if apps have already been loaded
    if (allApps.length === 0) {
      setAppsLoading(true)
      fetchAllAppsKeysOnly(props.user[PK])
        .then(res => {
          console.log('fetched all apps for user')
          setAllApps((apps) => [...apps, ...res.Items])
          setAppsLoading(false)
        })
        .catch(err => {
          console.log(err)
          setAppsLoading(false)
        })
    }
  }

  // when an appliance id is clicked, navigates to the appliance view 
  const onAppClicked = (value) => {
    // checks if parent has overridden onChange
    if (props.overrideOnChange) {
      props.overrideOnChange(value)
      return
    }
    console.log('navigating', value)
    navigate(`/appliance-home/view-appliance/${value[APP_ID]}`, { state: { [NAME]: value[APP_ID] } })
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start'
  })

  return (
    <Autocomplete
      id="appliance-auto-complete"
      // custom filter options
      filterOptions={filterOptions}
      // maps the returned apps to show APID
      options={allApps.sort()}

      //value={props.defaultValue ? props.defaultValue : undefined}
      // renders what the user sees in the list
      renderInput={(params) => <TextField 
        {...params} 
        label={props.label} 
        variant={props.overrideVariant ? props.overrideVariant : 'outlined'}
        helperText={props.helperText ? props.helperText : ''}  
        />}
      // called when a value is clicked
      onChange={(e, value) => onAppClicked(value)}
      // called when field is first clicked
      onOpen={onAppIdClicked}
      // displays loading message 
      loading={appsLoading}
      // sets what to display to the user
      getOptionLabel={option => option[APP_ID]}
      // overridden equality check to compare APP_ID attribute (otherwise would try to compare whole app object)
      isOptionEqualToValue={(option, value) => option[APP_ID] === value[APP_ID]}
    />
  )
}

ApplianceAutoComplete.propTypes = {
  user: PropTypes.object,
  label: PropTypes.string,
  overrideOnChange: PropTypes.func,
  overrideVariant: PropTypes.string,
  helperText: PropTypes.string
}