import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COMPANY_NAME, CONTRACT_GROUP, CONTRACT_MANAGER, DEFAULT_STOCK_SITE, IS_STOCK_SITE, NAME, PK, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE, SITE_ID } from "../../DynamoDbConstants";
import { fetchAllSites } from "../../services/dynamoDBservice";
import PropTypes from 'prop-types'
import { compareCompanyNames } from "../../services/dataSortingService";

export function FormSitesAutoComplete(props) {
  const navigate = useNavigate()

  const [allSites, setAllSites] = useState([])
  const [sitesLoading, setSitesLoading] = useState(false)

  useEffect(() => {
    if (!props.formData.new) {
      console.log('setting default site')
      if (props.formData.user[DEFAULT_STOCK_SITE]) { onSitesOpened(props.formData.user[DEFAULT_STOCK_SITE]); return }
    }
  }, [props.formData.new])

  // called when sites autocomplete first clicked
  const onSitesOpened = (ssId) => {
    // checks if sites have previously been fetched
    if (allSites.length === 0) {
      // shows the loading indicator in the autocomplete
      setSitesLoading(true)
      // fetches all sites for the users company 
      fetchAllSites(props.user[PK])
        .then(res => {
          // filters fetched sites by role - this isn't the most efficient way to do this as it will fetch all sites for all users. Oscar 26/8/22
          var filtered = []
          filtered = res.Items.sort(compareCompanyNames)


          if (props.stockSites) {
            filtered = filtered.filter(i => i[IS_STOCK_SITE])
          }

          if (filtered.length < 1) {
            setAllSites([])
            setSitesLoading(false)
          }
          
          console.log(filtered)
          setAllSites((allSites) => [...allSites, ...filtered])
          setSitesLoading(false)

          if (!props.formData.new && ssId) {
            const ss = res.Items.filter(item => item[SITE_ID] === ssId)[0]

            if (ss) { props.setFormData((formData) => ({ ...formData, stockSite: ss })) }
          }
        })
        .catch(err => {
          console.log(err)
          setSitesLoading(false)
        })
    }
  }

  const onStockSiteChange = (value) => { props.setError(false); props.setFormData((formData) => ({ ...formData, stockSite: value })) }

  return (
    <Autocomplete
      disablePortal
      id="sites-auto-complete"
      // the values to display in the autocomplete
      options={props.defaultRM ? allSites.filter(s => s[REGIONAL_GROUP] === props.defaultRM) : allSites}
      // default value to display
      value={props.formData.stockSite}
      // renders the display of the autocomplete
      renderInput={(params) => <TextField
        {...params}
        label={props.label}
        variant={props.overrideVariant ? props.overrideVariant : 'outlined'}
        error={props.error}
        required={props.required}
        disabled={props.disabled}
      />}
      // returns the label which the user sees (either company name + site name, or just company if site name undefined)
      getOptionLabel={option => option[NAME] ? `${option[COMPANY_NAME]}, ${option[NAME]}` : option[COMPANY_NAME]}
      // overridden equality check so values compare by SITE_ID
      isOptionEqualToValue={(option, value) => option[SITE_ID] === value[SITE_ID]}
      // called when a value in the autocomplete is clicked
      onChange={(e, value) => onStockSiteChange(value)}
      disabled={props.disabled}
      disableClearable
      // called when autocomplete initially clicked
      onOpen={onSitesOpened}
      loading={sitesLoading}
    />
  )
}

FormSitesAutoComplete.propTypes = {
  user: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.bool,
  setError: PropTypes.func,
  required: PropTypes.bool,
  stockSites: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultRM: PropTypes.string
}

// displays a simple list of sites for a user
export function SimpleSitesAutoComplete(props) {
  const navigate = useNavigate()

  const [allSites, setAllSites] = useState([])
  const [sitesLoading, setSitesLoading] = useState(false)

  // called when sites autocomplete first clicked
  const onSitesOpened = () => {
    // checks if sites have previously been fetched
    if (allSites.length === 0) {
      // shows the loading indicator in the autocomplete
      setSitesLoading(true)
      // fetches all sites for the users company 
      fetchAllSites(props.user[PK])
        .then(res => {
          // filters fetched sites by role - this isn't the most efficient way to do this as it will fetch all sites for all users. Oscar 26/8/22
          switch (props.user[ROLE]) {
            case REGIONAL_MANAGER:
              setAllSites(res.Items.filter(site => site[REGIONAL_GROUP] === props.user[REGIONAL_GROUP]).sort(compareCompanyNames))
              break;
            case CONTRACT_MANAGER:
              setAllSites(res.Items.filter(site => site[REGIONAL_GROUP] === props.user[REGIONAL_GROUP]).sort(compareCompanyNames))
              //setAllSites(res.Items.filter(site => site[CONTRACT_GROUP] === props.user[CONTRACT_GROUP]).sort(compareCompanyNames))
              break;
            default:
              setAllSites(res.Items.sort(compareCompanyNames))
          }
          setSitesLoading(false)
        })
        .catch(err => {
          console.log(err)
          setSitesLoading(false)
        })
    }
  }

  console.log(allSites)

  // function for navigating to the clicked on site
  const onSiteClicked = (site) => {
    // checks to see if parent has overridden onChange
    if (props.overrideOnChange) {
      // if yes, calls override function
      props.overrideOnChange(site)
      return
    }

    // creates a full site name out of company name and optional site name
    var siteName = site[NAME] ? `${site[COMPANY_NAME]} ${site[NAME]}` : site[COMPANY_NAME]

    console.log('navigating', siteName.toLowerCase())
    // splits the full site name into individual words, ready for displaying in the domain name
    siteName = siteName.replaceAll('/', ' ')
    const elements = siteName.toLowerCase().split(' ').filter(element => element)
    console.log(elements)
    if (elements.length > 1) {
      // navigates to the site view, displaying the full site name joined by dashes
      navigate(`site/${elements.join('-')}`, { state: site })
      return
    }
    // if the full site name was one word, no need to join it and can just navigate
    navigate(`site/${siteName.toLowerCase().trim()}`, { state: site })
  }

  return (
    <Autocomplete

      id="sites-auto-complete"
      // the values to display in the autocomplete
      options={allSites}
      // displays optional default value
      value={props.defaultValue}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option[SITE_ID]}>
            {option[NAME] ? `${option[COMPANY_NAME]}, ${option[NAME]}` : option[COMPANY_NAME]}
          </li>
        );
      }}
      // renders the display of the autocomplete
      renderInput={(params) => <TextField
        {...params}
        label={props.label}
        variant={props.overrideVariant ? props.overrideVariant : 'outlined'}
        error={props.error}
        required={props.required}
      />}
      // returns the label which the user sees (either company name + site name, or just company if site name undefined)
      getOptionLabel={option => option[NAME] ? `${option[COMPANY_NAME]}, ${option[NAME]}` : option[COMPANY_NAME]}
      // overridden equality check so values compare by SITE_ID
      isOptionEqualToValue={(option, value) => option[SITE_ID] === value[SITE_ID]}
      // called when a value in the autocomplete is clicked
      onChange={(e, value) => onSiteClicked(value)}
      // called when autocomplete initially clicked
      onOpen={onSitesOpened}
      disableClearable
      loading={sitesLoading}
    />
  )
}

SimpleSitesAutoComplete.propTypes = {
  user: PropTypes.object,
  label: PropTypes.string,
  defaultValue: PropTypes.object,
  overrideOnChange: PropTypes.func,
  overrideVariant: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  stockSites: PropTypes.bool
}