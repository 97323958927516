
import { Title } from "@mui/icons-material";
import { Button, Checkbox, Radio, Stack, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { checkApplianceExpired, checkAppliancesForExpiry, dateNowAsUnix, datePlusYear, getDateTextStyle, unixToAppDate } from '../../services/dateServices'
import PropTypes from 'prop-types'
import { AuditIcon } from "../icons/icons";
import { getFullAppName } from "../../services/dataSortingService";
import { APP_ID } from "../../DynamoDbConstants";

export default function AppDataTableSmall (props) {
  let params = useParams()
  let navigate = useNavigate()
  const [responsive] = useState('standard')

  const columns = [{
    name: 'APID',
    label: 'ID',
    options: {
      sort: true,
      customBodyRender: (value, tableMeta) => {
        const item = props.apps.find(app => app[APP_ID] === tableMeta.rowData[0])
        return (
          <Stack direction="row" spacing={0.5} sx={{ opacity: (item.MD || item.SD) ? '50%' : '100%'}}>
          <Typography
          sx={{ fontSize: 16}}
            style={getDateTextStyle(tableMeta.rowData[2], item)}
          >
            {value}
          </Typography>
          {AuditIcon(item.AD)}
          {item.MD &&
          <Typography >
            {'(M)'}
          </Typography>
          }
          {item.SD &&
            <Typography >
              {'(S)'}
            </Typography>
            }
          </Stack>
        )
      }
    }
  },
  {
  name: 'N',
    label: 'Make',
    options: {
      sort: true,
      customBodyRender: (value, tableMeta) => {
        const item = props.apps.find(app => app[APP_ID] === tableMeta.rowData[0])
        return (
          <Typography
              sx={{ fontSize: 16, opacity: (item.MD || item.SD) ? '50%' : '100%'}}
              style={getDateTextStyle(tableMeta.rowData[2], item)}
            >
              {getFullAppName(item)}
            </Typography>
        )
        
      }
    }
  },
  {
  name: 'D',
    label: 'Expiry Date',
    options: {
      sort: true,
      customBodyRender: (value, tableMeta) => {
        const item = props.apps.find(app => app[APP_ID] === tableMeta.rowData[0])
        if (value > 0) {
          return (
            <Typography
            sx={{ fontSize: 16, opacity: (item.MD || item.SD) ? '50%' : '100%'}}
                style={getDateTextStyle(tableMeta.rowData[2], item)}
              >
                {unixToAppDate(datePlusYear(value))}
              </Typography>
          )
        }
      }
    }
  }]

  const CustomCheckbox = (props) => {
    return (
      <Checkbox {...props} size="small" sx={{ p: 0 }}/>
    )
  };
  
  const options = {
    filterType: 'checkbox',
    //selectableRowsHideCheckboxes: true,
    download: false,
    elevation: 0,
    filter: false,
    print: false, 
    search: true,
    pagination: true,
    rowsPerPage: 25,
    viewColumns: false,
    selectToolbarPlacement: 'none',
    rowsPerPageOptions:[25],
    responsive,
    selectableRowsOnClick: true,
    selectableRowsHeader: props.apps.length < 1 ? false : true,
    setTableProps: () => {
      return {
        size: 'small'
      }
    },
    rowsSelected: props.selectedRows,
    onRowSelectionChange: (currentRow, allRows, newRows) => {
      if (currentRow.length === 0) {
        props.onAllAppsUnSelected(props.apps[0].SID)
        return
      }
      
      currentRow.forEach(row => {
        const app = props.apps[row.dataIndex]
        newRows.indexOf(row.dataIndex) > -1 ? props.onAppSelected(app, row.dataIndex) : props.onAppUnSelected(app, row.dataIndex)
      })
    }
  };
  
  return (
    <div>
    <MUIDataTable
      data={props.apps}
      columns={columns}
      options={options}
      components={{
        Checkbox: CustomCheckbox
      }}
    />
    </div>
    
  )
  
}

AppDataTableSmall.propTypes = {
  apps: PropTypes.array,
  selectedRows: PropTypes.array,
  onAppSelected: PropTypes.func,
  onAppUnSelected: PropTypes.func,
  onAllAppsUnSelected: PropTypes.func
}