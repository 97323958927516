import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { APP_DESCR, MOVED_SITE, NAME, PAT_CATEGORY, PAT_DATE, SK } from '../../DynamoDbConstants'
import { unixToAppDate } from '../../services/dateServices'
import { fetchAppHistory } from '../../services/dynamoDBservice'

export default function ApplianceHistory (props) {
  const [appHistory, setAppHistory] = useState([])

  const compareDate = (a, b) => {
    if (a[PAT_DATE] < b[PAT_DATE]) return 1
    if (a[PAT_DATE] > b[PAT_DATE]) return -1
    return 0
  }

  useEffect(() => {
    fetchAppHistory(props.appId)
      .then(res => {
        console.log(res)
        if (res.Items.length === 0) {
          setAppHistory([])
        } else {
          setAppHistory(res.Items.sort(compareDate))
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [props.appId])

  if (appHistory.length === 0) {
    return (
      <Typography>No appliance history</Typography>
    )
  } else {
    return (
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Site</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>By</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appHistory.map((history) =>
              <TableRow key={history[SK]}>
                <TableCell>{history[PAT_CATEGORY]}</TableCell>
                <TableCell>{history[MOVED_SITE] ? history[MOVED_SITE] : ''}</TableCell>
                <TableCell>{history[APP_DESCR]}</TableCell>
                <TableCell>{history[NAME]}</TableCell>
                <TableCell>{unixToAppDate(history[PAT_DATE])}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

ApplianceHistory.propTypes = {
  appId: PropTypes.string
}