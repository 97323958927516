import { Box, Dialog, IconButton, List, ListItem, Typography } from "@mui/material";
import PropTypes from 'prop-types'
import DeleteIcon from '@mui/icons-material/Delete';
import { CONTRACT_GROUP, COVER, COVER_TYPE, NAME, PK } from "../../DynamoDbConstants";
import { useState } from "react";
import RemoveCoverDialog from "../dialogs/RemoveCoverDialog";
import { fetchCG, removeCover } from "../../services/dynamoDBservice";

export default function CoverList(props) {
  const [showRemoveCoverDialog, setShowRemoveCoverDialog] = useState(false)

  const onRemoveCoverDialogSubmit = (formData) => {
    setShowRemoveCoverDialog(false)
    console.log(formData)
    fetchCG(formData.user[PK], formData.cover[CONTRACT_GROUP])
      .then(res => {
        console.log(res)
        if (res.Items.length > 0) {
          formData.cover.cg = res.Items[0]
          removeCover(formData)
            .then(res => {
              props.refresh()
            })
            .catch(err => {
              console.log(err)
            })
        }
        
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <List dense>
      {/* {props.data.user[COVER].map(cov => */}
      { props.data.user[COVER] && 
        <Box key={props.data.user[COVER][CONTRACT_GROUP]}>
          <ListItem secondaryAction={
            <IconButton edge='end' aria-label='delete' onClick={() => setShowRemoveCoverDialog(props.data.user[COVER][CONTRACT_GROUP])}>
              <DeleteIcon />
            </IconButton>
          }>
            <Typography color='error'>
              {`${props.data.user[COVER][COVER_TYPE] ? 'Covering' : 'Covered by'} - ${props.data.user[COVER][NAME]}`}
            </Typography>
          </ListItem>
          <Dialog open={showRemoveCoverDialog === props.data.user[COVER][CONTRACT_GROUP]}>
            <RemoveCoverDialog
              user={props.data.user}
              cover={props.data.user[COVER]}
              onSubmit={onRemoveCoverDialogSubmit}
              onCancelled={() => setShowRemoveCoverDialog(false)}
            />
          </Dialog>
        </Box>
      }
        
      {/* )} */}

    </List>
  )
}

CoverList.propTypes = {
  data: PropTypes.object,
  refresh: PropTypes.func
}