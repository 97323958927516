import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import PropTypes from 'prop-types'
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { COMPANY_NAME, DEFAULT_STOCK_SITE, NAME, PK, SITE_ID, SK } from "../../DynamoDbConstants";
import { fetchAllSites, fetchSite } from "../../services/dynamoDBservice";
import { SimpleSitesAutoComplete } from "../autoCompletes/SitesAutoComplete";

export default function MoveAppDialog (props) {
  const testUser = useOutletContext()
  const [selected, setSelected] = useState({ [COMPANY_NAME]: '', [SITE_ID]: '-111' })
  const [note, setNote] = useState('')

  useEffect(() => {
    if (testUser[DEFAULT_STOCK_SITE]) {
      fetchSite(testUser[PK], testUser[DEFAULT_STOCK_SITE])
        .then(res => {
          setSelected(res.Items[0])
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [testUser])

  return (
    <div>
      <DialogTitle>Move App</DialogTitle>
      <DialogContent>
          <DialogContentText>
          Please detail below anything wrong with the appliance.
        </DialogContentText>
        <TextField
          margin="dense"
          id="move-app"
          label="Note (optional)"
          fullWidth
          variant="standard"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          multiline
        />
        <SimpleSitesAutoComplete 
          user={testUser}
          defaultValue={selected}
          label='Move to'
          overrideOnChange={setSelected}
          overrideVariant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancelled}>Cancel</Button>
        <Button onClick={(e) => props.onSubmit(note, selected)}>Submit</Button>
      </DialogActions>
    </div>
  )
}

MoveAppDialog.propTypes = {
  onCancelled: PropTypes.func,
  onSubmit: PropTypes.func
}