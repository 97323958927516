import { useEffect, useState } from "react"
import { fetchAllCompanies } from "../../services/dynamoDBservice"
import PropTypes from 'prop-types'
import { COMPANY_ID, COMPANY_NAME, IS_STOCK_SITE, NAME, PK, SITE_ID } from "../../DynamoDbConstants"
import { AutoCompleteWithAddNew } from "../inputs/autoComplete"
import { Autocomplete, createFilterOptions, TextField } from "@mui/material"

const filter = createFilterOptions();

// deals with displaying and fetching a list of company items
/*
 props:
  user: current logged in user
  formData: parent form object
  setFormData: method to update formData
  error: boolean for displaying error
  setError: method to remove error
  disabled: optional boolean to disable autocomplete
  helperText: optional helper text
*/
export default function CompanyAutoComplete (props) {

  // state to hold the fetch company items
  const [companies, setCompanies] = useState([])
  const [companiesLoading, setCompaniesLoading] = useState(false)

  // checks to see if part of add or edit form
  useEffect(() => {
    if (props.formData.site[IS_STOCK_SITE]) {
      props.setFormData((formData) => ({...formData, companyHolder: {[NAME]: props.formData.site[COMPANY_NAME]} }))
    }
    else if (!props.formData.new) {
      // if part of edit, calls onCompanyClicked method with the current site id
      // this will then display the current company in the edit form
      console.log('not new')
      onCompanyClicked()
      return
    }
  }, [props.formData.new])

  const onCompanyClicked = () => {
    // check to see if companies have already been fetched
    if (companies.length === 0) {
      setCompaniesLoading(true)
      // dynamoDB call to fetch companies
      fetchAllCompanies(props.user[PK])
        .then(res => {
          // tells the autocomplete loading has finished
          setCompaniesLoading(false)
          // sets the companies state to the returned items
          setCompanies(res.Items)
          // checks to see if on editSiteForm
          if (!props.formData.new) {
            // using the current sites company id, finds the correct company
            const cuId = props.formData.site[COMPANY_ID]
            const company = res.Items.filter(item => item[COMPANY_ID] === cuId)[0]
            // checks matching company exists, setting formData.company to its value if it does
            if (company) { props.setFormData((formData) => ({...formData, companyHolder: company })) }
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  // called from autocomplete when its value changes
  const onCompanyChange = (value)=> {
    // resets any error that might be showing on autocomplete
    props.setError(false)
    console.log(value)
    // goes through checks to see what value is equal to
    if (typeof value === 'string') {
      // unsure if value is ever equal to string? - 17/8/22 Oscar
      props.setFormData((formData) => ({...formData, companyHolder: {[NAME]: value, new: true} }))
      // if value valid and value has input value, then it's a new company 
    } else if (value && value.inputValue) {
      props.setFormData((formData) => ({...formData, companyHolder: {[NAME]:value.inputValue, new: true } }))
      // else it's an already existing company
    } else {
      props.setFormData((formData) => ({...formData, companyHolder: value }))
    } 
  }

  return (
    <Autocomplete
        disablePortal
        // feeSolo - allows non existing entries to be entered
        freeSolo
        // the values to display in the autocomplete
        options={companies}
        // function called when initially clicked
        onOpen={onCompanyClicked}
        // displays loading message 
        loading={companiesLoading}
        id="company-autocomplete"
        // value to display
        value={props.formData.companyHolder}
        // called when value changes
        onChange={(e, v) => onCompanyChange(v)}
        // filters the options(values) and applies custom method
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          const { inputValue } = params;
          // Suggest the creation of a new value if input doesn't match company
          const isExisting = options.some((option) => inputValue === option[COMPANY_ID]);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              [NAME]: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        disabled={props.disabled}
        // overrides default option label to use object property as label
        getOptionLabel={option => option[NAME]}
        // overrides option equality check (checking on company ID protects against matching names)
        isOptionEqualToValue={(option, value) => option[COMPANY_ID] === value[COMPANY_ID]}
        selectOnFocus
        // enables home and end key usage on keyboard to go through options
        handleHomeEndKeys
        // renders the display of the autocomplete
        renderInput={(params) => {
          const inputProps = params.inputProps;
          // sets suggestions from browser to not appear
          inputProps.autoComplete = "none";
          
          return (
            <TextField {...params}
              required={true}
              label={"Company"}
              helperText={props.helperText}
              inputProps={inputProps}
              error={props.error}
              disabled={props.disabled}  
            />
        )}} />
  )
}

CompanyAutoComplete.propTypes = {
  user: PropTypes.object,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  error: PropTypes.bool,
  setError: PropTypes.func,
  disabled: PropTypes.bool,
  helperText: PropTypes.string
}