import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import PropTypes from 'prop-types'
import { NAME } from "../../DynamoDbConstants";

const filter = createFilterOptions();

export function AutoCompleteWithAddNew (props) {
  return (
    <Autocomplete
        disablePortal
        freeSolo
        onOpen={props.onOpen}
        loading={props.loading}
        id="combo-box-demo"
        value={props.value}
        onChange={(e, v) => props.onChange(v)}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option[NAME]);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              N: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        disabled={props.disabled}
        options={props.options}
        getOptionLabel={option => option[NAME]}
        isOptionEqualToValue={(option, value) => option[NAME] === value[NAME]}
        selectOnFocus
        handleHomeEndKeys
        renderInput={(params) => {
        const inputProps = params.inputProps;
        inputProps.autoComplete = "none";
        
        return (
        <TextField {...params}
        required={props.required}
        label={props.label}
        helperText={props.helperText}
        inputProps={inputProps}
        error={props.error}
        disabled={props.disabled}  
        />
        )}} />
    )
}

AutoCompleteWithAddNew.propTypes = {
  options: PropTypes.array,
  onOpen: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.object,
  loading: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool
}

export function AutoCompleteWithoutAddNew (props) {
  return (
    <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={props.options}
          sx={{ width: "md" }}
          value={props.value}
          renderInput={(params) => {
            const inputProps = params.inputProps;
            inputProps.autoComplete = "none";
            return (
              <TextField {...params} disabled={props.disabled} required={props.required} error={props.error} inputProps={inputProps} label={props.label} helperText={props.helperText} /> 
            )}}
          // renderOption={(props, option) => (
          //   <li {...props} key={option.SK}>
          //     {getOptionName(option)}
          //   </li>
          // )}
          getOptionLabel={option => option.FN ? `${option.FN} ${option.N}` : option.N}
          isOptionEqualToValue={(option, value) => option.SK === value.SK}
          onChange={(e, v) => props.onChange(v)}
          onOpen={props.onOpen}
          loading={props.loading}
        />
  )
}

AutoCompleteWithoutAddNew.propTypes = {
  options: PropTypes.array,
  onOpen: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.object,
  loading: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool
}