import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import AdminSites from './pages/admin/adminSites';
import AddEditCompany from './pages/admin/addEditCompany';
import AdminCompanies from './pages/admin/adminCompanies';
import AddEditSite from './pages/admin/addEditSite';
import Site from './pages/site/site';
import Appliance from './pages/appliance/appliance';
import AdminUsers from './pages/admin/adminUsers';
import AddEditUser from './pages/admin/addEditUser';
import AddStockSite from './pages/admin/addStockSite';
import Stream from './pages/stream/stream';
import AppHome from './pages/appHome';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route exact path='/' element={<App />} >
        <Route path='/' element={<Navigate replace to='/home'/>}/>
        <Route exact path='/home' element={<Stream />} />
        <Route exact path='/appliance-home' element={<AppHome />} />
        <Route path='/appliance-home/view-appliance/:appliance' element={<Appliance />} />
        <Route path='/appliance-home/site/:site/view-appliance/:appliance' element={<Appliance />} />
        <Route path='/appliance-home/site/:site' element={<Site />} />
        <Route path='/appliance-home/site/:site' element={<Site />} />
        <Route path='/appliance-home/site/:site/view-appliance/:appliance' element={<Appliance />} />
        <Route path='/appliance-home/view-appliance/:appliance' element={<Appliance />} />
        {/* <Route exact path='/users/:add-user' element={<AdminCreateUser />} />
        <Route exact path='/admin/edit-user/:user' element={<AdminEditUserView />} /> */}
        <Route exact path='/appliance-home/sites' element={<AdminSites />} />
        <Route exact path='/appliance-home/admin/add-site' element={<AddEditSite />} />
        <Route exact path='/appliance-home/admin/add-stock-site' element={<AddStockSite />} />
        <Route exact path='/appliance-home/admin/edit-site/:site' element={<AddEditSite />} />
        <Route exact path='/appliance-home/admin/view-site/:site' element={<Site />} />
        <Route path='/appliance-home/admin/view-site/:site/view-appliance/:appliance' element={<Appliance />} />
        <Route exact path='/appliance-home/companies' element={<AdminCompanies />} />
        <Route exact path='/appliance-home/admin/add-company' element={<AddEditCompany />} />
        <Route exact path='/appliance-home/admin/edit-company/:company' element={<AddEditCompany />} />
        <Route exact path='/appliance-home/users' element={<AdminUsers />} />
        <Route exact path='/appliance-home/admin/add-user' element={<AddEditUser />} />
        <Route exact path='/appliance-home/admin/edit-user/:user' element={<AddEditUser />} /> 
        {/* <Route exact path='/company-feed' element={<Stream/>}/> */}
        <Route
        path="*"
        element={
        <main style={{ padding: "1rem" }}>
          <p>There's nothing here!</p>
        </main>
      }
    />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
