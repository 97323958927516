
// DynamoDB
const main_table = 'PAT_Test_Tracking'
const test_table = 'Zergles_pat_testing_version_3'
export const demo_table = 'DEMO_RYEFORDS_TABLE'
export const TABLE_NAME = demo_table

export const TEST_DOMAIN = 'test123.com'
export const PROD_DOMAIN = 'audit-app.co.uk'

export const RG_DATE_INDEX = 'GSI-RG-D-index'
export const CG_DATE_INDEX = 'GSI-CG-D-index'
export const SITE_INDEX = 'GSI-PK-APSID-index'
export const ALL_APPS_INDEX = 'GSI-PK-APID-index'
export const SITES_FOR_COMPANY_INDEX = 'GSI-PK-CUID-index'
export const RG_INDEX = 'GSI-PK-RG-index'
export const CG_INDEX = 'GSI-PK-CG-index'

//attributes
export const ID = 'ID'
export const SK = 'SK'
export const PK = 'PK'
export const CONTRACT_GROUP = 'CG'
export const COVER = 'COV'
export const COVER_TYPE = 'CT'
export const REGIONAL_GROUP = 'RG'
export const CUSTOMER_ID = 'CUID'
export const USER_ID = 'UID'
export const CUSTOMER_NAME = 'CN'
export const CLEANING_COMPANY_ID = 'CCID'
export const APP_SITE_ID = 'APSID'
export const PAT_DATE = 'D'
export const AUDIT_DATE = 'AD'
export const APP_NOTE = 'AN'
export const APP_ID = 'APID'
export const SITE_NAME = 'SN'
export const COMPANY_NAME = 'CN'
export const NAME = 'N'
export const FIRST_NAME = 'FN'
export const APP_DESCR = 'NT'
export const EMAIL = 'E'
export const POSTCODE = 'PC'
export const PAT_CATEGORY = 'C'
export const APP_SERIAL = "AS"
export const MOVED_SITE = "MS"
export const MISSING_DATE = "MD"
export const ROLE = "RL"
export const SCRAP_DATE = "SD"

// streams
export const MESSAGE_ID = "MID"
export const LIKES = "LK"
export const MESSAGE = "MSG"
export const UPLOAD_DATE = "UD"
export const IMAGE_ID = "IMG"
export const COMMENT_ID = "CID"
export const HAS_COMMENTS = "COM"
export const LINKS = 'LNK'

// custom attributes
export const IS_DEVELOPER = "isDev"
export const IS_ADMIN = "isAdmin"

export const SITE_ADDR = 'SA'
export const SITE_CONTACT = 'SC'
export const SITE_TEL = 'ST'
export const SITE_ID = 'SID'
export const SITE_EMAIL = 'SE'
export const SITE_POSTCODE = 'SP'

export const DEFAULT_STOCK_SITE = 'SSID'
export const IS_STOCK_SITE = 'SS'

export const COMPANY_ADDR = 'CA'
export const COMPANY_CONTACT = 'CC'
export const COMPANY_TEL = 'CT'
export const COMPANY_ID = 'CUID'
export const COMPANY_EMAIL = 'CE'
export const COMPANY_POSTCODE = 'CP'

//export const GROUP_ID = 'GID' 
export const USERNAME = 'UN'



//prefixs for SK ids
export const PREFIX_U = 'U#'
export const PREFIX_CM = 'U#'
export const PREFIX_RM = 'U#'
export const PREFIX_ADMIN = 'U#'
export const PREFIX_PATTESTER = 'U#'

export const PREFIX_CG = 'gCG#'
export const PREFIX_RG = 'gRG#'
export const PREFIX_GROUPS = 'g'
export const PREFIX_CC = 'CC#'

export const PREFIX_SITE = 'S#'
export const PREFIX_APP = 'AP#'
export const PREFIX_COMPANY = 'CU#'
export const PREFIX_APP_HISTORY ='AH#'

export const PK_PREFIX_FOR_APPLIANCE_HISTORY = 'ID#'

//roles
export const PAT_TESTER = 'Pat Tester'
export const CONTRACT_MANAGER = 'Contracts Manager'
export const REGIONAL_MANAGER = 'Regional Manager' 
export const ADMINSTRATOR = 'Administrator'
export const DEVELOPER = "Developer"

//items
export const EMPTY_SITE_ITEM = {
  [CONTRACT_GROUP]: '',
  [REGIONAL_GROUP]: '',
  [COMPANY_NAME]: '',
  [COMPANY_ID]: '',
  [SITE_ID]: '',
  [NAME]: ''
}

export const EMPTY_COMPANY_ITEM = {
  [NAME]: '',
  [COMPANY_ID]: '',
}

export const EMPTY_USER_ITEM = {
  [FIRST_NAME]: '',
  [NAME]: '',
  [EMAIL]: '',
  [ROLE]: ''
}