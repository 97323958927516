
import { fromInstanceMetadata } from '@aws-sdk/credential-providers';
import { skeletonClasses } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { REPAIR_ON_SITE } from '../AutoCompleteConstants';
import { PK, SK, PREFIX_RG, PREFIX_SITE, PREFIX_COMPANY, SCRAP_DATE, APP_SITE_ID, PREFIX_CG, DEFAULT_STOCK_SITE, REGIONAL_GROUP, PREFIX_CM, PREFIX_RM, PREFIX_PATTESTER, PREFIX_ADMIN, CUSTOMER_ID, CUSTOMER_NAME, NAME, SITE_ADDR, POSTCODE, SITE_CONTACT, SITE_TEL, EMAIL, USERNAME, CONTRACT_GROUP, AUDIT_DATE, APP_NOTE, COMPANY_NAME, SITE_NAME, PREFIX_APP, PAT_DATE, PK_PREFIX_FOR_APPLIANCE_HISTORY, PREFIX_APP_HISTORY, PAT_CATEGORY, APP_ID, APP_DESCR, APP_SERIAL, MOVED_SITE, MISSING_DATE, USER_ID, FIRST_NAME, ROLE, ID, SITE_ID, SITE_EMAIL, TABLE_NAME, CONTRACT_MANAGER, REGIONAL_MANAGER, PAT_TESTER, ADMINSTRATOR, COMPANY_ID, PREFIX_U, IS_STOCK_SITE, COVER, COVER_TYPE, SITE_POSTCODE } from '../DynamoDbConstants'



export const createSiteItem = (formData) => {
  const siteItem = {}
  let id = formData.site[SITE_ID] ? formData.site[SITE_ID] : uuidv4().toString()
  formData.site[SITE_ID] = id

  siteItem[PK] = formData[PK]
  siteItem[SK] = `${PREFIX_SITE}${id}`
  siteItem[SITE_ID] = id
  siteItem[NAME] = formData.site[NAME]
  siteItem[COMPANY_ID] = formData.companyHolder[COMPANY_ID]
  siteItem[COMPANY_NAME] = formData.companyHolder[NAME]
  siteItem[REGIONAL_GROUP] = formData.regionalGroupHolder[REGIONAL_GROUP]
  if (!formData.site[IS_STOCK_SITE]) {
    siteItem[CONTRACT_GROUP] = formData.contractGroupHolder[CONTRACT_GROUP]
    siteItem[IS_STOCK_SITE] = undefined
  } else {
    siteItem[IS_STOCK_SITE] = true
    siteItem[CONTRACT_GROUP] = undefined
  }
  siteItem[POSTCODE] = formData.site[POSTCODE]
  siteItem[SITE_CONTACT] = formData.site[SITE_CONTACT]
  siteItem[SITE_ADDR] = formData.site[SITE_ADDR]
  siteItem[SITE_EMAIL] = formData.site[SITE_EMAIL]
  siteItem[SITE_TEL] = formData.site[SITE_TEL]
  return siteItem
}
export const createCompanyParams = (formData) => {
  let id = formData.company[COMPANY_ID] ? formData.company[COMPANY_ID] : uuidv4().toString()

  formData.company[PK] = formData[PK]
  formData.company[SK] = `${PREFIX_COMPANY}${id}`
  formData.company[COMPANY_ID] = id

  return formData.company
}


export const createCompanyAndSiteParams = (formData) => {
  var params = []
  let id = uuidv4().toString()
  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: {
        [PK]: formData[PK],
        [SK]: `${PREFIX_COMPANY}${id}`,
        [COMPANY_ID]: id,
        [NAME]: formData.companyHolder[NAME]
      }
    }
  })

  formData.companyHolder[COMPANY_ID] = id

  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: createSiteItem(formData)
    }
  })

  return params
}

export const createNewRGContractParams = (formData) => {
  const groupItem = {
    [PK]: formData[PK],
    [SK]: `${PREFIX_CG}${formData.user[CONTRACT_GROUP]}`,
    [CONTRACT_GROUP]: formData.user[CONTRACT_GROUP],
    [REGIONAL_GROUP]: formData.regionalGroupHolder[REGIONAL_GROUP],
    [FIRST_NAME]: formData.user[FIRST_NAME],
    [NAME]: formData.user[NAME],
    [USERNAME]: formData.user[USER_ID]
  }

  if (formData.stockSite[PK]) {
    groupItem[DEFAULT_STOCK_SITE] = formData.stockSite[DEFAULT_STOCK_SITE]
  }

  return groupItem
}

// export const createUserAndStockSiteParams = (formData) => {
//   const params = []
//   params.push({
//     Put: {
//       TableName: TABLE_NAME,
//       Item: createUserItem(formData)
//     }
//   })
//   if (formData[ROLE] === CONTRACT_MANAGER) {
//     params.push({
//       Update: {
//         TableName: TABLE_NAME,
//         Key: {
//           [PK]: formData[PK],
//           [SK]: `${PREFIX_CG}${formData[CONTRACT_GROUP]}`
//         },
//         UpdateExpression: `set ${DEFAULT_STOCK_SITE} = :dsid`,
//         ExpressionAttributeValues: {
//           ':dsid': formData.stockSite[SITE_ID]
//         }
//       }
//     })
//   } else if (formData[ROLE] === REGIONAL_MANAGER) {
//     params.push({
//       Update: {
//         TableName: TABLE_NAME,
//         Key: {
//           [PK]: formData[PK],
//           [SK]: `${PREFIX_RG}${formData[REGIONAL_GROUP]}`
//         },
//         UpdateExpression: `set ${DEFAULT_STOCK_SITE} = :dsid`,
//         ExpressionAttributeValues: {
//           ':dsid': formData.stockSite[SITE_ID]
//         }
//       }
//     })
//   }
//   return params
// }

export const createUserItem = (formData) => {

  formData.user[PK] = formData[PK]
  formData.user[SK] = `${PREFIX_U}${formData.user[USER_ID]}`
  //formData.user[USER_ID] = formData[USER_ID]


  switch (formData.user[ROLE]) {
    case CONTRACT_MANAGER:
      formData.user[CONTRACT_GROUP] = formData.contractGroupHolder[CONTRACT_GROUP]
      formData.user[REGIONAL_GROUP] = formData.regionalGroupHolder[REGIONAL_GROUP]
      // checks to see if stocksite set (pk will be undefined if not)
      if (formData.stockSite[PK]) {
        formData.user[DEFAULT_STOCK_SITE] = formData.stockSite[SITE_ID]
      }
      break;
    case REGIONAL_MANAGER:
      formData.user[REGIONAL_GROUP] = formData.regionalGroupHolder[REGIONAL_GROUP]
      formData.user[CONTRACT_GROUP] = formData.contractGroupHolder[CONTRACT_GROUP]
      if (formData.stockSite[PK]) {
        formData.user[DEFAULT_STOCK_SITE] = formData.stockSite[SITE_ID]
      }
      break;
    case PAT_TESTER:
      formData.user[REGIONAL_GROUP] = undefined
      formData.user[CONTRACT_GROUP] = undefined
      if (formData.stockSite[PK]) {
        formData.user[DEFAULT_STOCK_SITE] = formData.stockSite[SITE_ID]
      }
      break;
    default:
      formData.user[REGIONAL_GROUP] = undefined
      formData.user[CONTRACT_GROUP] = undefined
      formData.user[DEFAULT_STOCK_SITE] = undefined
      break;
  }

  // formData.stockSite && (userItem[DEFAULT_STOCK_SITE] = formData.stockSite[SITE_ID])


  return formData.user
}

export const createRemoveCGCoverParams = (formData) => {
  const params = []
  // check if user has other cover items
  // const cover = formData.user[COVER].filter(cov => cov[CONTRACT_GROUP] !== formData.cover[CONTRACT_GROUP])
  // // if they do, update with filtered list
  // if (cover.length > 0) {
  //   params.push({
  //     Update: {
  //       TableName: TABLE_NAME,
  //       Key: {
  //         [PK]: formData.user[PK],
  //         [SK]: formData.user[SK]
  //       },
  //       UpdateExpression: `set ${COVER} = :cov`,
  //       ExpressionAttributeValues: {
  //         ':cov': cover
  //       }
  //     }
  //   })
  //   params.push({
  //     Update: {
  //       TableName: TABLE_NAME,
  //       Key: {
  //         [PK]: formData.user[PK],
  //         [SK]: `${PREFIX_CG}${formData.user[CONTRACT_GROUP]}`
  //       },
  //       UpdateExpression: `set ${COVER} = :cov`,
  //       ExpressionAttributeValues: {
  //         ':cov': cover
  //       }
  //     }
  //   })
  // else simply remove cover from needed items
  //} else {
  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: formData.user[PK],
        [SK]: formData.user[SK]
      },
      UpdateExpression: `remove ${COVER}`
    }
  })
  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: formData.user[PK],
        [SK]: `${PREFIX_CG}${formData.user[CONTRACT_GROUP]}`
      },
      UpdateExpression: `remove ${COVER}`
    }
  })
  //}

  // checks to see if removed cover user has other cover items
  // const coverCover = formData.cover.cg[COVER].filter(cov => cov[CONTRACT_GROUP] !== formData.user[CONTRACT_GROUP])
  // // if they do, update with filtered list
  // if (coverCover.length > 0) {
  //   params.push({
  //     Update: {
  //       TableName: TABLE_NAME,
  //       Key: {
  //         [PK]: formData.cover.cg[PK],
  //         [SK]: formData.cover.cg[SK]
  //       },
  //       UpdateExpression: `set ${COVER} = :cov`,
  //       ExpressionAttributeValues: {
  //         ':cov': coverCover
  //       }
  //     }
  //   })
  //   params.push({
  //     Update: {
  //       TableName: TABLE_NAME,
  //       Key: {
  //         [PK]: formData.cover.cg[PK],
  //         [SK]: `${PREFIX_U}${formData.cover.cg[USERNAME]}`
  //       },
  //       UpdateExpression: `set ${COVER} = :cov`,
  //       ExpressionAttributeValues: {
  //         ':cov': coverCover
  //       }
  //     }
  //   })
  //   // else simply remove cover from needed items
  // } else {
  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: formData.cover.cg[PK],
        [SK]: formData.cover.cg[SK]
      },
      UpdateExpression: `remove ${COVER}`
    }
  })
  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: formData.cover.cg[PK],
        [SK]: `${PREFIX_U}${formData.cover.cg[USERNAME]}`
      },
      UpdateExpression: `remove ${COVER}`
    }
  })
  // }

  return params

}

export const createCoverContractGroupParams = (formData) => {
  const params = []
  var covering = {}
  var cover = {}

  // add covering user item
  covering = {
    [NAME]: `${formData.coverContractGroupHolder[FIRST_NAME]} ${formData.coverContractGroupHolder[NAME]}`,
    [USERNAME]: formData.coverContractGroupHolder[USERNAME],
    [CONTRACT_GROUP]: formData.coverContractGroupHolder[CONTRACT_GROUP],
    [COVER_TYPE]: true
  }

  // add user that is providing cover
  cover = {
    [NAME]: `${formData.contractGroupHolder[FIRST_NAME]} ${formData.contractGroupHolder[NAME]}`,
    [USERNAME]: formData.contractGroupHolder[USERNAME],
    [CONTRACT_GROUP]: formData.contractGroupHolder[CONTRACT_GROUP],
    [COVER_TYPE]: false
  }

  // check to see if covering user has cover set
  if (formData.contractGroupHolder[COVER]) {
    // checks if currently being covered by another user
    // const isCovered = formData.contractGroupHolder[COVER][COVER_TYPE] === fals
    // if (isCovered) {
    //   // if yes - adds that to the cover array so still covered
    //   covering.push(isCovered)
    // }
    // checks if currently covering another user
    if (formData.contractGroupHolder[COVER]) {
      // if yes - removes that user from covering
      params.push({
        Update: {
          TableName: TABLE_NAME,
          Key: {
            [PK]: formData.contractGroupHolder[PK],
            [SK]: `${PREFIX_U}${formData.contractGroupHolder[COVER][USERNAME]}`
          },
          UpdateExpression: `remove ${COVER}`
        }
      })
      params.push({
        Update: {
          TableName: TABLE_NAME,
          Key: {
            [PK]: formData.contractGroupHolder[PK],
            [SK]: `${PREFIX_CG}${formData.contractGroupHolder[COVER][CONTRACT_GROUP]}`
          },
          UpdateExpression: `remove ${COVER}`
        }
      })
    }
    // if (formData.contractGroupHolder[COVER][COVER_TYPE] === false) {
    //   // if yes - removes that user from covering
    //   params.push({
    //     Update: {
    //       TableName: TABLE_NAME,
    //       Key: {
    //         [PK]: formData.contractGroupHolder[PK],
    //         [SK]: `${PREFIX_U}${formData.contractGroupHolder[COVER][USERNAME]}`
    //       },
    //       UpdateExpression: `remove ${COVER}`
    //     }
    //   })
    //   params.push({
    //     Update: {
    //       TableName: TABLE_NAME,
    //       Key: {
    //         [PK]: formData.contractGroupHolder[PK],
    //         [SK]: `${PREFIX_CG}${formData.contractGroupHolder[COVER][CONTRACT_GROUP]}`
    //       },
    //       UpdateExpression: `remove ${COVER}`
    //     }
    //   })
    // }
  }
  // check to see if user being covered has cover set
  if (formData.coverContractGroupHolder[COVER]) {
    // checks if currently covering another user
    // const isCovering = formData.coverContractGroupHolder[COVER].find(cov => cov[COVER_TYPE] === true)
    // if (isCovering) {
    //   cover.push(isCovering)
    // }
    // checks to see if being covered by another user
    //const isCovered = formData.coverContractGroupHolder[COVER].find(cov => cov[COVER_TYPE] === false)
    if (formData.coverContractGroupHolder[COVER]) {
      // if yes - removes that user from covering
      params.push({
        Update: {
          TableName: TABLE_NAME,
          Key: {
            [PK]: formData.contractGroupHolder[PK],
            [SK]: `${PREFIX_U}${formData.coverContractGroupHolder[COVER][USERNAME]}`
          },
          UpdateExpression: `remove ${COVER}`
        }
      })
      params.push({
        Update: {
          TableName: TABLE_NAME,
          Key: {
            [PK]: formData.contractGroupHolder[PK],
            [SK]: `${PREFIX_CG}${formData.coverContractGroupHolder[COVER][CONTRACT_GROUP]}`
          },
          UpdateExpression: `remove ${COVER}`
        }
      })
    }
  }

  // adds update parameters for user who is providing cover
  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: formData.contractGroupHolder[PK],
        [SK]: `${PREFIX_U}${formData.contractGroupHolder[USERNAME]}`
      },
      UpdateExpression: `set ${COVER} = :cov`,
      ExpressionAttributeValues: {
        ':cov': covering
      }
    }
  })
  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: formData.contractGroupHolder[PK],
        [SK]: `${PREFIX_CG}${formData.contractGroupHolder[CONTRACT_GROUP]}`
      },
      UpdateExpression: `set ${COVER} = :cov`,
      ExpressionAttributeValues: {
        ':cov': covering
      }
    }
  })
  // adds update parameters for user who is being covered

  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: formData.coverContractGroupHolder[PK],
        [SK]: `${PREFIX_U}${formData.coverContractGroupHolder[USERNAME]}`
      },
      UpdateExpression: `set ${COVER} = :cov`,
      ExpressionAttributeValues: {
        ':cov': cover
      }
    }
  })
  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: formData.coverContractGroupHolder[PK],
        [SK]: `${PREFIX_CG}${formData.coverContractGroupHolder[CONTRACT_GROUP]}`
      },
      UpdateExpression: `set ${COVER} = :cov`,
      ExpressionAttributeValues: {
        ':cov': cover
      }
    }
  })

  // if (formData.contractGroupHolder[COVER] && formData.contractGroupHolder[CONTRACT_GROUP] !== formData.contractGroupHolder[COVER][CONTRACT_GROUP]) {

  // }

  return params
}

export const createUpdateUserItem = (formData) => {
  const params = []
  if (formData.user[ROLE] === CONTRACT_MANAGER) {
    const userUpdate = {
      TableName: TABLE_NAME,
      Key: {
        [PK]: formData[PK],
        [SK]: formData.user[SK]
      },
      UpdateExpression: `set ${FIRST_NAME} = :fn, ${NAME} = :n, ${REGIONAL_GROUP} = :rg`,
      ExpressionAttributeValues: {
        ':fn': formData.user[FIRST_NAME],
        ':n': formData.user[NAME],
        ':rg': formData.regionalGroupHolder[REGIONAL_GROUP]
      }
    }
    if (formData.stockSite[PK]) {
      userUpdate.UpdateExpression += `, ${DEFAULT_STOCK_SITE} = :ss`
      userUpdate.ExpressionAttributeValues[':ss'] = formData.stockSite[SITE_ID]
    }
    params.push({
      Update: userUpdate
    })

    const contractUpdate = {
      TableName: TABLE_NAME,
      Key: {
        [PK]: formData[PK],
        [SK]: `${PREFIX_CG}${formData.user[CONTRACT_GROUP]}`
      },
      UpdateExpression: `set ${FIRST_NAME} = :fn, ${NAME} = :n, ${REGIONAL_GROUP} = :rg`,
      ExpressionAttributeValues: {
        ':fn': formData.user[FIRST_NAME],
        ':n': formData.user[NAME],
        ':rg': formData.regionalGroupHolder[REGIONAL_GROUP]
      }
    }
    if (formData.stockSite[PK]) {
      contractUpdate.UpdateExpression += `, ${DEFAULT_STOCK_SITE} = :ss`
      contractUpdate.ExpressionAttributeValues[':ss'] = formData.stockSite[SITE_ID]
    }
    params.push({ //add the new user details to the regional group
      Update: contractUpdate
    })
  } else if (formData.user[ROLE] === REGIONAL_MANAGER || formData.user[ROLE] === PAT_TESTER) {
    const userUpdate = {
      TableName: TABLE_NAME,
      Key: {
        [PK]: formData[PK],
        [SK]: formData.user[SK]
      },
      UpdateExpression: `set ${FIRST_NAME} = :fn, ${NAME} = :n`,
      ExpressionAttributeValues: {
        ':fn': formData.user[FIRST_NAME],
        ':n': formData.user[NAME]
      }
    }
    if (formData.stockSite[PK]) {
      userUpdate.UpdateExpression += `, ${DEFAULT_STOCK_SITE} = :ss`
      userUpdate.ExpressionAttributeValues[':ss'] = formData.stockSite[SITE_ID]
    }
    params.push({
      Update: userUpdate
    })
    if (formData.user[ROLE] === REGIONAL_MANAGER) {
      const regionUpdate = {
        TableName: TABLE_NAME,
        Key: {
          [PK]: formData[PK],
          [SK]: `${PREFIX_RG}${formData.user[REGIONAL_GROUP]}`
        },
        UpdateExpression: `set ${FIRST_NAME} = :fn, ${NAME} = :n`,
        ExpressionAttributeValues: {
          ':fn': formData.user[FIRST_NAME],
          ':n': formData.user[NAME]
        }
      }
      if (formData.stockSite[PK]) {
        regionUpdate.UpdateExpression += `, ${DEFAULT_STOCK_SITE} = :ss`
        regionUpdate.ExpressionAttributeValues[':ss'] = formData.stockSite[SITE_ID]
      }
      params.push({ //add the new user details to the regional group
        Update: regionUpdate
      })
      const contractUpdate = {
        TableName: TABLE_NAME,
        Key: {
          [PK]: formData[PK],
          [SK]: `${PREFIX_CG}${formData.user[CONTRACT_GROUP]}`
        },
        UpdateExpression: `set ${FIRST_NAME} = :fn, ${NAME} = :n`,
        ExpressionAttributeValues: {
          ':fn': formData.user[FIRST_NAME],
          ':n': formData.user[NAME]
        }
      }
      if (formData.stockSite[PK]) {
        contractUpdate.UpdateExpression += `, ${DEFAULT_STOCK_SITE} = :ss`
        contractUpdate.ExpressionAttributeValues[':ss'] = formData.stockSite[SITE_ID]
      }
      params.push({ //add the new user details to the regional group
        Update: contractUpdate
      })
    }
  } else {
    params.push({
      Update: {
        TableName: TABLE_NAME,
        Key: {
          [PK]: formData[PK],
          [SK]: formData.user[SK]
        },
        UpdateExpression: `set ${FIRST_NAME} = :fn, ${NAME} = :n`,
        ExpressionAttributeValues: {
          ':fn': formData.user[FIRST_NAME],
          ':n': formData.user[NAME]
        }
      }
    })
  }
  return params
}

export const createGroupItem = (formData) => {
  var groupItem = {}
  switch (formData.user[ROLE]) {
    case REGIONAL_MANAGER:
      groupItem = {
        [PK]: formData[PK],
        [SK]: `${PREFIX_RG}${formData.regionalGroupHolder[REGIONAL_GROUP]}`,
        [REGIONAL_GROUP]: formData.regionalGroupHolder[REGIONAL_GROUP],
        [FIRST_NAME]: formData.user[FIRST_NAME],
        [NAME]: formData.user[NAME],
        [USERNAME]: formData.user[USER_ID]
      }
      if (formData.stockSite[PK]) {
        groupItem[DEFAULT_STOCK_SITE] = formData.stockSite[SITE_ID]
      }
      break;
    case CONTRACT_MANAGER:
      groupItem = {
        [PK]: formData[PK],
        [SK]: `${PREFIX_CG}${formData.contractGroupHolder[CONTRACT_GROUP]}`,
        [CONTRACT_GROUP]: formData.contractGroupHolder[CONTRACT_GROUP],
        [REGIONAL_GROUP]: formData.regionalGroupHolder[REGIONAL_GROUP],
        [FIRST_NAME]: formData.user[FIRST_NAME],
        [NAME]: formData.user[NAME],
        [USERNAME]: formData.user[USER_ID]
      }
      if (formData.stockSite[PK]) {
        groupItem[DEFAULT_STOCK_SITE] = formData.stockSite[SITE_ID]
      }
      break
    default:
      console.log('dynamoItemCreator: unexpected role when creating group item')
  }
  return groupItem
}

export const createUpdateGroupParams = (formData) => {
  var params = []
  switch (formData.user[ROLE]) {
    case REGIONAL_MANAGER:
      const regionUpdate = {
        TableName: TABLE_NAME,
        Key: {
          [PK]: formData[PK],
          [SK]: `${PREFIX_RG}${formData.regionalGroupHolder[REGIONAL_GROUP]}`
        },
        UpdateExpression: `set ${NAME} = :n, ${FIRST_NAME} = :fn, ${USERNAME} = :un`,
        ExpressionAttributeValues: {
          ':n': formData.user[NAME],
          ':fn': formData.user[FIRST_NAME],
          ':un': formData.user[USER_ID]
        }
      }
      if (formData.stockSite[PK]) {
        regionUpdate.UpdateExpression += `, ${DEFAULT_STOCK_SITE} = :ss`
        regionUpdate.ExpressionAttributeValues[':ss'] = formData.stockSite[SITE_ID]
      }
      params.push({ //add the new user details to the regional group
        Update: regionUpdate
      })

      if (formData.regionalGroupHolder[CONTRACT_GROUP]) {
        const contractUpdate = {
          TableName: TABLE_NAME,
          Key: {
            [PK]: formData[PK],
            [SK]: `${PREFIX_CG}${formData.regionalGroupHolder[CONTRACT_GROUP]}`
          },
          UpdateExpression: `set ${NAME} = :n, ${FIRST_NAME} = :fn, ${REGIONAL_GROUP} = :rg ,${USERNAME} = :un`,
          ExpressionAttributeValues: {
            ':n': formData.user[NAME],
            ':fn': formData.user[FIRST_NAME],
            ':rg': formData.regionalGroupHolder[REGIONAL_GROUP],
            ':un': formData.user[USER_ID]
          }
        }
        if (formData.stockSite[PK]) {
          contractUpdate.UpdateExpression += `, ${DEFAULT_STOCK_SITE} = :ss`
          contractUpdate.ExpressionAttributeValues[':ss'] = formData.stockSite[SITE_ID]
        }
  
        params.push({
          Update: contractUpdate
        })
      }
      

      params.push({ //remove the regional group from the previous regional manager
        Update: {
          TableName: TABLE_NAME,
          Key: {
            [PK]: formData[PK],
            [SK]: `${PREFIX_U}${formData.regionalGroupHolder[USERNAME]}`
          },
          UpdateExpression: `remove ${REGIONAL_GROUP}`
        }
      })
      break;
    case CONTRACT_MANAGER:
      const contractUpdate = {
        TableName: TABLE_NAME,
        Key: {
          [PK]: formData[PK],
          [SK]: `${PREFIX_CG}${formData.contractGroupHolder[CONTRACT_GROUP]}`
        },
        UpdateExpression: `set ${NAME} = :n, ${FIRST_NAME} = :fn, ${REGIONAL_GROUP} = :rg ,${USERNAME} = :un`,
        ExpressionAttributeValues: {
          ':n': formData.user[NAME],
          ':fn': formData.user[FIRST_NAME],
          ':rg': formData.regionalGroupHolder[REGIONAL_GROUP],
          ':un': formData.user[USER_ID]
        }
      }
      if (formData.stockSite[PK]) {
        contractUpdate.UpdateExpression += `, ${DEFAULT_STOCK_SITE} = :ss`
        contractUpdate.ExpressionAttributeValues[':ss'] = formData.stockSite[SITE_ID]
      }

      params.push({
        Update: contractUpdate
      })

      params.push({
        Update: { //remove the contract group from the current holder
          TableName: TABLE_NAME,
          Key: {
            [PK]: formData[PK],
            [SK]: `${PREFIX_U}${formData.contractGroupHolder[USERNAME]}`
          },
          UpdateExpression: `remove ${CONTRACT_GROUP}`
        }
      })
      break;
    default:
  }
  return params
}

export const createUpdateAppCGParams = (app) => {
  const update = {
    TableName: TABLE_NAME,
    Key: {
      [PK]: app[PK],
      [SK]: app[SK]
    },
    UpdateExpression: `set ${APP_SITE_ID} = :asid, ${SITE_NAME} = :sn`,
    ExpressionAttributeValues: {
      ':asid': app[APP_SITE_ID],
      ':sn': app[SITE_NAME]
    }
  }

  if (app[CONTRACT_GROUP]) {
    update.UpdateExpression += `, ${CONTRACT_GROUP} = :cg`
    update.ExpressionAttributeValues[':cg'] = app[CONTRACT_GROUP]
  }

  return update
}

/*
* AuditData {
  appId: Appliance id,
  category: Visual Audit, Damage etc.
  user: user's name,
  note: any notes added to log
}
*/

export const createUpdateAppDamagedParams = (id, auditData) => {
  const date = new Date().getTime()
  const uuid = uuidv4().toString()
  var params = []
  if (auditData.movedSite[SK] !== -111 && auditData.movedSite[SITE_ID] !== auditData.app[APP_SITE_ID]) {
    if (auditData.movedSite[IS_STOCK_SITE]) {
      params.push({
        Update: {
          TableName: TABLE_NAME,
          Key: {
            [PK]: id,
            [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
          },
          UpdateExpression:
            `set ${AUDIT_DATE} = :ad, ${APP_NOTE} = :an, ${PAT_DATE} = :d, ${COMPANY_NAME} = :cn, ${REGIONAL_GROUP} = :rg, ${APP_SITE_ID} = :apsid, ${SITE_NAME} = :sn ${IS_STOCK_SITE} = :ss remove ${CONTRACT_GROUP}`,
          ExpressionAttributeValues: {
            ':ad': date,
            ':an': auditData.note,
            ':d': 0,
            ':cn': auditData.movedSite[COMPANY_NAME],
            ":rg": auditData.movedSite[REGIONAL_GROUP],
            ":apsid": auditData.movedSite[SITE_ID],
            ":sn": auditData.movedSite[NAME],
            ":ss": true
          }
        }
      })
    } else {
      params.push({
        Update: {
          TableName: TABLE_NAME,
          Key: {
            [PK]: id,
            [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
          },
          UpdateExpression:
            `set ${AUDIT_DATE} = :ad, ${APP_NOTE} = :an, ${PAT_DATE} = :d, ${CONTRACT_GROUP} = :cg, ${COMPANY_NAME} = :cn, ${REGIONAL_GROUP} = :rg, ${APP_SITE_ID} = :apsid, ${SITE_NAME} = :sn remove ${IS_STOCK_SITE}`,
          ExpressionAttributeValues: {
            ':ad': date,
            ':an': auditData.note,
            ':d': 0,
            ':cg': auditData.movedSite[CONTRACT_GROUP],
            ':cn': auditData.movedSite[COMPANY_NAME],
            ":rg": auditData.movedSite[REGIONAL_GROUP],
            ":apsid": auditData.movedSite[SITE_ID],
            ":sn": auditData.movedSite[NAME]
          }
        }
      })
    }

  } else {
    params.push({
      Update: {
        TableName: TABLE_NAME,
        Key: {
          [PK]: id,
          [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
        },
        UpdateExpression: `set ${AUDIT_DATE} = :ad, ${APP_NOTE} = :an, ${PAT_DATE} = :d`,
        ExpressionAttributeValues: {
          ':ad': date,
          ':an': auditData?.note,
          ':d': 0
        }
      }
    })
  }

  var movedSiteName = ''

  if (auditData.movedSite[COMPANY_NAME] && auditData.movedSite[COMPANY_NAME] === REPAIR_ON_SITE) {
    movedSiteName = `${auditData.app[COMPANY_NAME]}, ${auditData.app[SITE_NAME]}`
    auditData.note = auditData.note ? `Repair on site: ${auditData.note}` : 'Repair on site'
  } else if (auditData.movedSite[COMPANY_NAME]) {
    movedSiteName = `${auditData.movedSite[COMPANY_NAME]}, ${auditData.movedSite[NAME]}`
  } else {
    movedSiteName = auditData.movedSite[NAME]
  }

  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: {
        [PK]: `${PK_PREFIX_FOR_APPLIANCE_HISTORY}${auditData.app[APP_ID]}`,
        [SK]: `${PREFIX_APP_HISTORY}${uuid}`,
        [PAT_DATE]: date,
        [PAT_CATEGORY]: auditData.category,
        [NAME]: auditData.user,
        [APP_DESCR]: auditData.note,
        [MOVED_SITE]: movedSiteName
      }
    }
  })
  return params
}

export const createAddApplianceParams = (formData, site) => {
  const date = new Date().getTime()
  const uuidLog = uuidv4().toString()
  var params = []
  if (site[IS_STOCK_SITE]) {
    params.push({
      Update: {
        TableName: TABLE_NAME,
        Key: {
          [PK]: formData[PK],
          [SK]: `${PREFIX_APP}${formData.appId}`
        },
        UpdateExpression:
          `set ${COMPANY_NAME} = :cn, ${REGIONAL_GROUP} = :rg, ${APP_SITE_ID} = :apsid, ${SITE_NAME} = :sn ${IS_STOCK_SITE} = :ss remove ${CONTRACT_GROUP}`,
        ExpressionAttributeValues: {
          ':cn': site[COMPANY_NAME],
          ":rg": site[REGIONAL_GROUP],
          ":apsid": site[SITE_ID],
          ":sn": site[NAME],
          ':ss': true
        }
      }
    })
  } else {
    params.push({
      Update: {
        TableName: TABLE_NAME,
        Key: {
          [PK]: formData[PK],
          [SK]: `${PREFIX_APP}${formData.appId}`
        },
        UpdateExpression:
          `set ${CONTRACT_GROUP} = :cg, ${COMPANY_NAME} = :cn, ${REGIONAL_GROUP} = :rg, ${APP_SITE_ID} = :apsid, ${SITE_NAME} = :sn remove ${IS_STOCK_SITE}`,
        ExpressionAttributeValues: {
          ':cg': site[CONTRACT_GROUP],
          ':cn': site[COMPANY_NAME],
          ":rg": site[REGIONAL_GROUP],
          ":apsid": site[SITE_ID],
          ":sn": site[NAME]
        }
      }
    })
  }

  var movedSiteName = ''
  if (site[COMPANY_NAME]) { movedSiteName = `${site[COMPANY_NAME]}, ${site[NAME]}` } else { movedSiteName = site[NAME] }

  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: {
        [PK]: `${PK_PREFIX_FOR_APPLIANCE_HISTORY}${formData.appId}`,
        [SK]: `${PREFIX_APP_HISTORY}${uuidLog}`,
        [PAT_DATE]: date,
        [PAT_CATEGORY]: "Add Appliance",
        [NAME]: formData.user,
        [MOVED_SITE]: movedSiteName
      }
    }
  })

  return params
}

export const createAddNewApplianceParams = (formData, site) => {
  const date = new Date().getTime()
  const uuidLog = uuidv4().toString()
  var params = []

  const appItem = {
    [PK]: formData[PK],
    [SK]: `${PREFIX_APP}${formData.appId}`,
    [APP_ID]: formData.appId,
    [APP_SITE_ID]: site[SITE_ID],
    [REGIONAL_GROUP]: site[REGIONAL_GROUP],
    [SITE_NAME]: site[NAME],
    [CUSTOMER_NAME]: site[COMPANY_NAME],
    [PAT_DATE]: formData.noTestDate ? 0 : formData.testDate,
    [AUDIT_DATE]: 0,
    [NAME]: formData.make,
    [APP_DESCR]: formData.description,
    [APP_SERIAL]: formData.serialNo
  }

  if (!site[IS_STOCK_SITE]) {
    appItem[CONTRACT_GROUP] = site[CONTRACT_GROUP]
  } else {
    appItem[IS_STOCK_SITE] = true
  }

  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: appItem
    }
  })

  var movedSiteName = ''
  if (site[COMPANY_NAME]) { movedSiteName = `${site[COMPANY_NAME]}, ${site[NAME]}` } else { movedSiteName = site[NAME] }

  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: {
        [PK]: `${PK_PREFIX_FOR_APPLIANCE_HISTORY}${formData.appId}`,
        [SK]: `${PREFIX_APP_HISTORY}${uuidLog}`,
        [PAT_DATE]: date,
        [PAT_CATEGORY]: "Add Appliance",
        [NAME]: formData.user,
        [MOVED_SITE]: movedSiteName
      }
    }
  })

  return params
}

export const createUpdateAppMoveParams = (id, auditData) => {
  const date = new Date().getTime()
  const uuid = uuidv4().toString()
  var params = []

  if (auditData.movedSite[IS_STOCK_SITE]) {
    params.push({
      Update: {
        TableName: TABLE_NAME,
        Key: {
          [PK]: id,
          [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
        },
        UpdateExpression:
          `set ${AUDIT_DATE} = :ad, ${APP_NOTE} = :an, ${CUSTOMER_NAME} = :cn, ${REGIONAL_GROUP} = :rg, ${APP_SITE_ID} = :apsid, ${SITE_NAME} = :sn, ${IS_STOCK_SITE} = :ss remove ${CONTRACT_GROUP}`,
        ExpressionAttributeValues: {
          ':ad': date,
          ':an': auditData.note,
          ':cn': auditData.movedSite[COMPANY_NAME],
          ":rg": auditData.movedSite[REGIONAL_GROUP],
          ":apsid": auditData.movedSite[SITE_ID],
          ":sn": auditData.movedSite[NAME],
          ":ss": true
        }
      }
    })
  } else {
    params.push({
      Update: {
        TableName: TABLE_NAME,
        Key: {
          [PK]: id,
          [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
        },
        UpdateExpression:
          `set ${AUDIT_DATE} = :ad, ${APP_NOTE} = :an, ${CONTRACT_GROUP} = :cg, ${CUSTOMER_NAME} = :cn, ${REGIONAL_GROUP} = :rg, ${APP_SITE_ID} = :apsid, ${SITE_NAME} = :sn remove ${IS_STOCK_SITE}`,
        ExpressionAttributeValues: {
          ':ad': date,
          ':an': auditData.note,
          ':cg': auditData.movedSite[CONTRACT_GROUP],
          ':cn': auditData.movedSite[COMPANY_NAME],
          ":rg": auditData.movedSite[REGIONAL_GROUP],
          ":apsid": auditData.movedSite[SITE_ID],
          ":sn": auditData.movedSite[NAME]
        }
      }
    })
  }

  var movedSiteName = ''
  if (auditData.movedSite[COMPANY_NAME]) { movedSiteName = `${auditData.movedSite[COMPANY_NAME]}, ${auditData.movedSite[NAME]}` } else { movedSiteName = auditData.movedSite[NAME] }

  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: {
        [PK]: `${PK_PREFIX_FOR_APPLIANCE_HISTORY}${auditData.app[APP_ID]}`,
        [SK]: `${PREFIX_APP_HISTORY}${uuid}`,
        [PAT_DATE]: date,
        [PAT_CATEGORY]: auditData.category,
        [NAME]: auditData.user,
        [APP_DESCR]: auditData.note,
        [MOVED_SITE]: movedSiteName
      }
    }
  })

  return params
}

export const createUpdateAppMissingParams = (id, auditData) => {
  const date = new Date().getTime()
  const uuid = uuidv4().toString()
  var params = []

  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: id,
        [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
      },
      UpdateExpression: `set ${MISSING_DATE} = :md`,
      ExpressionAttributeValues: {
        ':md': date
      }
    }
  })

  var movedSiteName = ''
  if (auditData.app[COMPANY_NAME]) { movedSiteName = `${auditData.app[COMPANY_NAME]}, ${auditData.app[SITE_NAME]}` } else { movedSiteName = auditData.app[SITE_NAME] }

  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: {
        [PK]: `${PK_PREFIX_FOR_APPLIANCE_HISTORY}${auditData.app[APP_ID]}`,
        [SK]: `${PREFIX_APP_HISTORY}${uuid}`,
        [PAT_DATE]: date,
        [PAT_CATEGORY]: auditData.category,
        [NAME]: auditData.user,
        [MOVED_SITE]: movedSiteName
      }
    }
  })

  return params
}

export const createUpdateAppScrappedParams = (id, auditData) => {
  const date = new Date().getTime()
  const uuid = uuidv4().toString()
  var params = []

  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: id,
        [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
      },
      UpdateExpression: `set ${SCRAP_DATE} = :sd remove MD`,
      ExpressionAttributeValues: {
        ':sd': date
      }
    }
  })

  var movedSiteName = ''
  if (auditData.app[COMPANY_NAME]) { movedSiteName = `${auditData.app[COMPANY_NAME]}, ${auditData.app[SITE_NAME]}` } else { movedSiteName = auditData.app[SITE_NAME] }

  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: {
        [PK]: `${PK_PREFIX_FOR_APPLIANCE_HISTORY}${auditData.app[APP_ID]}`,
        [SK]: `${PREFIX_APP_HISTORY}${uuid}`,
        [PAT_DATE]: date,
        [PAT_CATEGORY]: auditData.category,
        [NAME]: auditData.user,
        [MOVED_SITE]: movedSiteName
      }
    }
  })

  return params
}

export const createUpdateAppFoundParams = (id, auditData) => {
  const date = new Date().getTime()
  const uuid = uuidv4().toString()
  var params = []

  if (auditData.app[APP_SITE_ID] !== auditData.movedSite[SITE_ID]) {
    if (auditData.movedSite[IS_STOCK_SITE]) {
      params.push({
        Update: {
          TableName: TABLE_NAME,
          Key: {
            [PK]: id,
            [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
          },
          UpdateExpression:
            `set ${AUDIT_DATE} = :ad, ${CUSTOMER_NAME} = :cn, ${REGIONAL_GROUP} = :rg, ${APP_SITE_ID} = :apsid, ${SITE_NAME} = :sn, ${IS_STOCK_SITE} = :ss remove ${MISSING_DATE}, ${CONTRACT_GROUP}`,
          ExpressionAttributeValues: {
            ':ad': date,
            ':cn': auditData.movedSite[COMPANY_NAME],
            ":rg": auditData.movedSite[REGIONAL_GROUP],
            ":apsid": auditData.movedSite[SITE_ID],
            ":sn": auditData.movedSite[NAME],
            ":ss": true
          }
        }
      })
    } else {
      params.push({
        Update: {
          TableName: TABLE_NAME,
          Key: {
            [PK]: id,
            [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
          },
          UpdateExpression:
            `set ${AUDIT_DATE} = :ad, ${CONTRACT_GROUP} = :cg, ${CUSTOMER_NAME} = :cn, ${REGIONAL_GROUP} = :rg, ${APP_SITE_ID} = :apsid, ${SITE_NAME} = :sn remove ${MISSING_DATE}, ${IS_STOCK_SITE}`,
          ExpressionAttributeValues: {
            ':ad': date,
            ':cg': auditData.movedSite[CONTRACT_GROUP],
            ':cn': auditData.movedSite[COMPANY_NAME],
            ":rg": auditData.movedSite[REGIONAL_GROUP],
            ":apsid": auditData.movedSite[SITE_ID],
            ":sn": auditData.movedSite[NAME]
          }
        }
      })
    }

  } else {
    params.push({
      Update: {
        TableName: TABLE_NAME,
        Key: {
          [PK]: id,
          [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
        },
        UpdateExpression: `remove ${MISSING_DATE}`
      }
    })
  }

  var movedSiteName = ''
  if (auditData.movedSite[COMPANY_NAME]) { movedSiteName = `${auditData.movedSite[COMPANY_NAME]}, ${auditData.movedSite[NAME]}` } else { movedSiteName = auditData.movedSite[NAME] }

  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: {
        [PK]: `${PK_PREFIX_FOR_APPLIANCE_HISTORY}${auditData.app[APP_ID]}`,
        [SK]: `${PREFIX_APP_HISTORY}${uuid}`,
        [PAT_DATE]: date,
        [PAT_CATEGORY]: auditData.category,
        [NAME]: auditData.user,
        [MOVED_SITE]: movedSiteName
      }
    }
  })

  return params
}

export const createUpdateAppAuditParams = (id, auditData) => {
  const date = new Date().getTime()
  const uuid = uuidv4().toString()
  var params = []
  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: id,
        [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
      },
      UpdateExpression: `set ${AUDIT_DATE} = :ad`,
      ExpressionAttributeValues: {
        ':ad': date
      }
    }
  })

  const siteName = auditData.app[COMPANY_NAME] ? `${auditData.app[COMPANY_NAME]}, ${auditData.app[SITE_NAME]}` : auditData.app[SITE_NAME]

  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: {
        [PK]: `${PK_PREFIX_FOR_APPLIANCE_HISTORY}${auditData.app[APP_ID]}`,
        [SK]: `${PREFIX_APP_HISTORY}${uuid}`,
        [PAT_DATE]: date,
        [PAT_CATEGORY]: auditData.category,
        [NAME]: auditData.user,
        [APP_DESCR]: auditData.note,
        [MOVED_SITE]: siteName
      }
    }
  })
  return params
}

export const createUpdateAppPassParams = (id, auditData) => {
  const date = new Date().getTime()
  const uuid = uuidv4().toString()
  var params = []
  params.push({
    Update: {
      TableName: TABLE_NAME,
      Key: {
        [PK]: id,
        [SK]: `${PREFIX_APP}${auditData.app[APP_ID]}`
      },
      UpdateExpression: `set ${PAT_DATE} = :d`,
      ExpressionAttributeValues: {
        ':d': date
      }
    }
  })

  const siteName = auditData.app[COMPANY_NAME] ? `${auditData.app[COMPANY_NAME]}, ${auditData.app[SITE_NAME]}` : auditData.app[SITE_NAME]

  params.push({
    Put: {
      TableName: TABLE_NAME,
      Item: {
        [PK]: `${PK_PREFIX_FOR_APPLIANCE_HISTORY}${auditData.app[APP_ID]}`,
        [SK]: `${PREFIX_APP_HISTORY}${uuid}`,
        [PAT_DATE]: date,
        [PAT_CATEGORY]: auditData.category,
        [NAME]: auditData.user,
        [APP_DESCR]: auditData.note,
        [MOVED_SITE]: siteName
      }
    }
  })
  return params
}
