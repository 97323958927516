import { Box, Button, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { NAME, PK, SK } from "../../DynamoDbConstants";
import { fetchAllCompanies, fetchAllSites, fetchUsers } from "../../services/dynamoDBservice";
import { compareName } from "../../services/sortService";
import PropTypes from 'prop-types'
import CompanyButton from "../buttons/CompanyButton";
import { FORM_EDIT_COMPANY } from "../../FormConstants";

// list for displaying all companies to admin user
export default function AdminCompanyList (props) {
  const navigate = useNavigate()
  const [companies, setCompanies] = useState([])

  const testUser = useOutletContext()
  
  // if companies haven't been fetched, fetches them from dyanmo
  useEffect(() => {
    if (companies.length === 0) {
      fetchAllCompanies(testUser[PK])
      .then(res => {
        const sorted = res.Items.sort(compareName)
        setCompanies(sorted)
      })
      .catch(err => {
        console.log(err)
      })
    }
    
  }, [])

  // navigates to the edit company page
  const navigateToCompanyPage = (event, item) => {
    // sets the formData company item equal to the clicked on company
    props.formData.company = item
    props.formData.type = FORM_EDIT_COMPANY
    console.log('navigating', event.target.value.toLowerCase())
    const elements = event.target.value.toLowerCase().split(' ')
    if (elements.length > 1) {
      navigate(`/appliance-home/admin/edit-company/${elements.join('-')}`, {state: props.formData})
      return
    }
    navigate(`/appliance-home/admin/edit-company/${event.target.value.toLowerCase()}`, {state: props.formData})
  }

  return (
    <Box>
      <Stack spacing={1}>
        <Typography variant="h5" align="center">
          All Companies
        </Typography>
        {companies.map((item) => 
          <CompanyButton 
            key={item[SK]}
            company={item}
            onClick={navigateToCompanyPage}
          />
        )}
      </Stack>
      
    </Box>
  )
}

AdminCompanyList.propTypes ={
  formData: PropTypes.object
}