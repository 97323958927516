import { ADMINSTRATOR, APP_DESCR, APP_ID, APP_SITE_ID, COMPANY_ID, COMPANY_NAME, CONTRACT_GROUP, CONTRACT_MANAGER, COVER, DEVELOPER, FIRST_NAME, MISSING_DATE, NAME, PAT_DATE, PAT_TESTER, REGIONAL_MANAGER, SCRAP_DATE, SITE_ID, SITE_NAME, SK, UPLOAD_DATE } from "../DynamoDbConstants"
import { checkApplianceExpired, checkApplianceWarning, checkApplianceWarningOrExpired } from "./dateServices"

const compareApps = (a, b) => {
  if (a[SCRAP_DATE] && !b[SCRAP_DATE]) {
    return 1
  }
  if (!a[SCRAP_DATE] && b[SCRAP_DATE]) {
    return -1
  }

  if (a[MISSING_DATE] && !b[MISSING_DATE]) {
    return 1
  }
  if (!a[MISSING_DATE] && b[MISSING_DATE]) {
    return -1
  }
  
  if (a[PAT_DATE] === 0 && b[PAT_DATE] !== 0) {
    return 1
  }
  if (a[PAT_DATE] !== 0 && b[PAT_DATE] === 0) {
    return -1
  }

  if (a[PAT_DATE] > b[PAT_DATE]) return 1
  if (a[PAT_DATE] < b[PAT_DATE]) return -1
  return 0
}

export const compareUploadDateReversed = (a, b) => {
  if (a[UPLOAD_DATE] < b[UPLOAD_DATE]) {
    return 1
  } else if (a[UPLOAD_DATE] > b[UPLOAD_DATE]) {
    return -1
  }
  return 0
}

export const compareUploadDate = (a, b) => {
  if (a[UPLOAD_DATE] < b[UPLOAD_DATE]) {
    return -1
  } else if (a[UPLOAD_DATE] > b[UPLOAD_DATE]) {
    return 1
  }
  return 0
}

export const compareKeys = (a, b) => {
  if (a.key < b.key) {
    return -1
  } else if (a.key > b.key) {
    return 1
  }
  return 0
}

export const compareFirstNames = (a, b) => {
  if (a[FIRST_NAME] < b[FIRST_NAME]) {
    return -1
  } else if (a[FIRST_NAME] > b[FIRST_NAME]) {
    return 1
  }
  return 0
}

export const compareCompanyNames = (a, b) => {
  if (a[COMPANY_NAME] < b[COMPANY_NAME]) {
    return -1
  } else if (a[COMPANY_NAME] > b[COMPANY_NAME]) {
    return 1
  }
  return 0
}

export const compareAppId = (a, b) => {
  if (a[APP_ID] < b[APP_ID]) {
    return -1
  } else if (a[APP_ID] > b[APP_ID]) {
    return 1
  }
  return 0
}

export const compareCGgrouped = (a, b) => {
  // if (a.isRG) {
  //   return -1
  // }

  //console.log(`${a.CGN}, ${b.CGN}`, a < b)
  
  if (a.CGN < b.CGN) {
    return -1
  } else if (a.CGN > b.CGN) {
    return 1
  }
  return 0
}


export function getFullAppName (app) {
  var name = ""
  var ellipses = ''
  if (app[NAME]) {
    name = app[NAME]
  } else if (app[APP_DESCR]) {
    const desEle = app[APP_DESCR]
    var second = desEle.slice(0, 9)
    if (desEle.length > 9) {
      ellipses = '...'
    }
    return second + ellipses
  }
  
  if (app[APP_DESCR]) {
    const nEle = app[NAME]
    const desEle = app[APP_DESCR]

    

    var first = nEle.slice(0, 6)
    second = desEle.slice(0, 9)

    if (desEle.length > 9) {
      ellipses = '...'
    }

    name = `${first} ${second}${ellipses}`
  }
  return name
}

export function groupRGDataByCompany (data) {
  //console.log(data)
  const sorted = []
  // receives data split into Contract Groups, which in turn has data grouped by SK
  // i.e. Sites are all under 'S', Apps under 'AP' (not split up by Company/site yet)
  data.forEach(item => {
    //console.log(item)
    // finds all sites for a Contract group
    const sites = item.data.find(i => i.key === 'S')
    // finds the contract group item
    const contractGroup = item.data.find(i => i.key === 'gCG')
    if (contractGroup) {
      item.contractGroup = contractGroup.data[0]
    }
    // if (item.isRG) {
    //   const regionalGroup = item.data.find(i => i.key === 'gRG')
    //   if (regionalGroup) {
    //     item.regionalGroup = regionalGroup.data[0]
    //   }
    // }
    const userItem = item.data.find(i => i.key === 'U')
    if (userItem) {
      item.user = userItem.data[0]
      // if (item.contractGroup) {
      //   item.contractGroup[COVER] = item.user[COVER]
      // }
    }
    // if the CG has sites
    if (sites) {
      const companies = []
      // loops through each site
      sites.data.forEach(site => {
        // if the Company hasn't been added to the companies array
        if (!companies.find(i => i[COMPANY_ID] === site[COMPANY_ID])) {
          // add the Company (just name)
          companies.push({[COMPANY_NAME]: site[COMPANY_NAME], [COMPANY_ID]: site[COMPANY_ID]})
        }
        // finds the sites associated company in companies array
        const c = companies.find(i => i[COMPANY_ID] === site[COMPANY_ID])
        // if the company has no sites, add empty array
        if (!c.sites) {
          c.sites = []
        }

        // starts off assuming a company has no sites with appliances
        // c.hasApps = false

        // finds all appliances for Contract group
        const apps = item.data.find(i => i.key === 'AP')
        //console.log(item)
        if (apps) {
          //console.log(apps)
          // adds all matching appliances to the site item
          const siteApps = apps.data.filter(a => a[APP_SITE_ID] === site[SITE_ID])
          // checks site apps for any that are expired
          const expiredApp = siteApps.find(app => checkApplianceWarningOrExpired(app))
          // if found, then the site has expired apps, and will be shown to CM's
          if (expiredApp) {
            site.hasExpired = true
            c.hasExpired = true
          }
          // console.log(siteApps)
          // console.log(c)
          // if any site for a company has appliances, set hasApps true
          if (siteApps.length > 0) {
            c.hasApps = true
          }
          site.apps = siteApps
        }
        //console.log(site)
        // adds the site to the company item
        //console.log(c)
        c.sites.push({[SITE_NAME]: site[NAME], [SITE_ID]: site[SITE_ID], site: site})
      })
      // assigns the companies array to the Contract Group
      item.companies = companies.sort(compareCompanyNames)
      
      //console.log(companies)
    }
    sorted.push(item)
    //console.log(item)
    
  })
  console.log(sorted)
  return sorted
}

export function groupRGData (data) {

  const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {})
  const arr = []
  let grouped = groupBy(data, i => i[CONTRACT_GROUP])

  Object.keys(grouped).forEach(key => {
    var item = { key: key, data: groupBySK(grouped[key]) }
    var cg = grouped[key].find(i => i[SK].includes('gCG'))
    if (cg) {
      item.CGN = `${cg[FIRST_NAME]} ${cg[NAME]}`
    }
    // var rg = grouped[key].find(i => i[SK].includes('gRG'))
    // if (rg) {
    //   item.isRG = true
    //   item.CGN = `${rg[FIRST_NAME]} ${rg[NAME]}`
    // }
    // if (grouped[key][0][SK] && grouped[key][0][SK].includes('gCG')) {
    //   item.CGN = `${grouped[key][0][FIRST_NAME]} ${grouped[key][0][NAME]}`
    // }
    if (item.key !== 'undefined') {
      arr.push(item)
    }
    
  })
  return arr.sort(compareCGgrouped)
}

export function groupCGData (data) {
  const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {})
  const arr = []
  let grouped = groupBy(data, i => i[CONTRACT_GROUP])

  Object.keys(grouped).forEach(key => {
    var item = { key: key, data: groupBySK(grouped[key]) }
    arr.push(item)
  })
  return arr.sort(compareCGgrouped)
}

function groupBySK (data) {
  const splitSK = (sk) => {
    const elements = sk.split('#')
    return elements[0]
  }

  const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {})
  const arr = []
  let grouped = groupBy(data, i => splitSK(i[SK]))

  Object.keys(grouped).forEach(key => {
    var item = { key: key, data: grouped[key] }
    // if (grouped[key][0][SK] && grouped[key][0][SK].includes('gCG')) {
    //   item.CGN = `${grouped[key][0][FIRST_NAME]} ${grouped[key][0][NAME]}`
    // }
    arr.push(item)
  })
  return arr
}

export function groupCompanies (companies) {
  const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {})
  const arr = []
  let grouped = groupBy(companies, i => i[COMPANY_NAME].trim())

  Object.keys(grouped).forEach(key => {
    arr.push({ key: key, CID: getCompanyId(grouped[key][0][APP_SITE_ID].trim()), data: groupSites(grouped[key]) })
  })
  return arr
}

function groupSites (sites) {
  const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {})
  const arr = []
  let grouped = groupBy(sites, i => i[SITE_NAME].trim())

  Object.keys(grouped).forEach(key => {
    arr.push({ key: key, SID: grouped[key][0][APP_SITE_ID].trim(), data: grouped[key].sort(compareApps) })
  })
  return arr
}

const getCompanyId = (SID) => {
  const elements = SID.split('#')
  return elements[0]
}

export const filterOutScrappedApps = (apps) => {
  return apps.filter(app => !app[SCRAP_DATE])
}

export const checkSiteContainsExpiredApps = (site) => {
  return site.apps.filter(app => (!app[SCRAP_DATE] && !app[MISSING_DATE]) && (checkApplianceWarning(app) || checkApplianceExpired(app))).length > 0
}

export const getRoleText = (role) => {
  if (role === CONTRACT_MANAGER) {
    return '(CM)'
  }
  if (role === REGIONAL_MANAGER) {
    return '(RM)'
  }
  if (role === PAT_TESTER) {
    return '(PAT)'
  }
  if (role === ADMINSTRATOR) {
    return '(ADMIN)'
  }
  if (role === DEVELOPER) {
    return '(DEV)'
  }
}

export const splitPk = (pk) => {
  const elements = pk.split('#')
  return elements[elements.length-1]
}