import { Box, Container, Stack } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import AddSiteButtonGroup from "../../components/buttonGroups/AddSiteButtonGroup";
import { StandardButton } from "../../components/buttons/customButtons";
import AddSiteDialogGroup from "../../components/dialogGroups/AddSiteDialogGroup";
import AddEditSiteForm from "../../components/forms/AddEditSiteForm";
import AddStockSiteForm from "../../components/forms/AddStockSiteForm";
import { NAME, PK, SITE_ID } from "../../DynamoDbConstants";
import { addNewCompanyAndSite, addNewSite, deleteSite, fetchAppsForSite } from "../../services/dynamoDBservice";

// Add stock site page
export default function AddStockSite () {
  // React Router hook to navigate between pages
  const navigate = useNavigate()
  // React Router hook which gets the passed in props from parent page (navigated from)
  const location = useLocation()
  // React Router hook to get whatever was passed in at the parent component (App.js)
  const testUser = useOutletContext()

  const [formData, setFormData] = useState(location.state)
  const [checkForm, setCheckForm] = useState(false)

  // called when form submit is clicked
  const onSubmitClicked = (e) => {
    console.log(formData)
    // sets the checkForm boolean to true, which triggers a form check in child form component
    setCheckForm(true)
  }

  // called from child form component if form check was successful
  const submitForm = () => {
    setCheckForm(false)
    // otherwise just add the new site
    console.log('adding site')
    addNewSite(formData)
      .then(res => {
        console.log(res)
        navigate(-1)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <Container style={{ paddingTop: '20px' }} maxWidth="md">
    {/* show the view/delete buttons only if editing a site */}
    <Stack spacing={1}>
      <AddStockSiteForm 
        formData={formData}
        setFormData={setFormData}
        checkForm={checkForm}
        setCheckForm={setCheckForm}
        submitForm={submitForm}
      />
      <StandardButton 
        fullWidth={true}
        label='Submit'
        onClick={onSubmitClicked}
      />
    </Stack>
    
    </Container>
  )
}