import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import { DeleteObjectCommand, GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { BUCKET, COGNITO_ID, IDENTITY_POOL_ID, REGION } from '../../AWSConstants'
import { IMAGE_ID, MESSAGE_ID } from '../../DynamoDbConstants'

let s3Client = null

const refreshS3 = (token) => {
  const loginData = {
    [COGNITO_ID]: token
  }

  s3Client = new S3Client({
    region: REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: {
        region: REGION
      },
      identityPoolId: IDENTITY_POOL_ID,
      logins: loginData
    })
  })
}

export const uploadImage = (token, image, messageId) => {
  if (!messageId) {
    return Promise.reject(new Error('message id cannot be null'))
  }
  refreshS3(token)

  const params = {
    Bucket: BUCKET,
    Key: `public/stream/images/${messageId}/${image.name}`,
    Body: image
  }

  return s3Client.send(new PutObjectCommand(params))
}

export const getImage = (token, message) => {
  refreshS3(token)

  const params = {
    Bucket: BUCKET,
    Key: `public/stream/images/${message[MESSAGE_ID]}/${message[IMAGE_ID]}`,
  }

  return s3Client.send(new GetObjectCommand(params))
}

export const deleteImage = (token, message) => {
  refreshS3(token)

  const params = {
    Bucket: BUCKET,
    Key: `public/stream/images/${message[MESSAGE_ID]}/${message[IMAGE_ID]}`,
  }

  return s3Client.send(new DeleteObjectCommand(params))
}