import { Box, List, ListItem, Typography } from "@mui/material";
import { COMPANY_NAME, CONTRACT_MANAGER, NAME, SITE_ID, SITE_NAME } from "../../DynamoDbConstants";
import AppChipGroup from "../chips/AppChipGroup";
import PropTypes from 'prop-types'
import { useNavigate } from "react-router-dom";
import { checkApplianceWarningOrExpired } from "../../services/dateServices";

export default function CompanySitesList(props) {
  const navigate = useNavigate()

  // function for navigating to the clicked on site
  const onSiteClicked = (site) => {
    // creates a full site name out of company name and optional site name
    var siteName = site[NAME] ? `${site[COMPANY_NAME]} ${site[NAME]}` : site[COMPANY_NAME]

    console.log('navigating', siteName.toLowerCase())
    // splits the full site name into individual words, ready for displaying in the domain name
    siteName = siteName.replaceAll('/', ' ')
    const elements = siteName.toLowerCase().split(' ').filter(element => element)
    console.log(elements)
    if (elements.length > 1) {
      // navigates to the site view, displaying the full site name joined by dashes
      navigate(`site/${elements.join('-')}`, { state: site })
      return
    }
    // if the full site name was one word, no need to join it and can just navigate
    navigate(`site/${siteName.toLowerCase().trim()}`, { state: site })
  }

  return (
    <List dense>
      {props.company.sites.map(site =>
        (props.role !== CONTRACT_MANAGER || (site.site.hasExpired || props.viewAll)) &&
        <Box key={site[SITE_ID]}>
          <ListItem button onClick={(e) => onSiteClicked(site.site)} divider sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: 16 }}>
              {site[SITE_NAME]}
            </Typography>
            <AppChipGroup 
            apps={props.role === CONTRACT_MANAGER && !props.viewAll ? site.site.apps.filter(app => checkApplianceWarningOrExpired(app)): site.site.apps} 
            size='small' 
            scrapAndMissing={props.scrapAndMissing} />
          </ListItem>
        </Box>

      )}
    </List>
  )
}

CompanySitesList.propTypes = {
  company: PropTypes.object,
  role: PropTypes.string,
  viewAll: PropTypes.bool,
  scrapAndMissing: PropTypes.bool
}