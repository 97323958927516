import { COGNITO_ID, IDENTITY_POOL_ID, REGION } from "../../AWSConstants"
import { InvokeCommand, LambdaClient } from '@aws-sdk/client-lambda'
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"

let lambdaClient = null

const refreshClient = (token) => {
  const loginData = {
    [COGNITO_ID]: token
  }

  lambdaClient = new LambdaClient({
    region: REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: {
        region: REGION
      },
      identityPoolId: IDENTITY_POOL_ID,
      logins: loginData
    })
  })
}

export const getLinkPreview = (token, linkString) => {
  refreshClient(token)
  const payload = {
    link: linkString
  }
  const params = {
    FunctionName: 'zergles-link-preview-function',
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify(payload)
  }

  return lambdaClient.send(new InvokeCommand(params))
}